import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "../sidebar/Sidebar";
import { Switch, Route } from "react-router-dom";
import Dashboard from "../../pages/Dashboard";
import EditBusiness from "../business/EditBusiness";
import UserJobs from "../../pages/UserJobs";
import BusinessLeads from "../../pages/BusinessLeads";
import ChangePassword from "../../pages/ChangePassword";
import AboutBusiness from "../business/AboutBusiness";
import AddBusiness from "../business/AddBusiness";
import Subscriptions from "../subscriptions/subscriptions";
import IndividualJobs from "../individual-jobs/IndividualJobs";
import QuoteJob from "../quote-job/QuoteJob";
import Profile from "../../pages/Profile";
import Reviews from "../../pages/Reviews";
import { useSelector, useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";
import { useToasts } from "react-toast-notifications";
import JobType from "../business/JobType";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { getUserData, logOut } from "../../redux/actions/userAction";
import UpdateJobStatus from "../update-job-status/UpdateJobStatus";
import BusinessSettings from "../BusinessSettings/BusinessSettings";

const WithSidebar = () => {
  // const { path } = useRouteMatch();
  // console.log("path", path);
  // console.log("url", url);
  // const location = useLocation();
  // const path = location.pathname;
  const history = useHistory();
  const quotejobStatus = useSelector((state) => state.job.quoteJob.status);
  const jobToUpdateStatus = useSelector((state) => state.job.jobStatusUpdate);
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  // const userEmail = useSelector(state => state.user.profile.email);

  useEffect(() => {
    const token = localStorage.FBIdToken;
    if (token) {
      console.log("token", token);
      const decodedToken = jwtDecode(token);
      console.log("decodedToken", new Date(decodedToken.exp * 1000));
      if (decodedToken.exp * 1000 < Date.now()) {
        localStorage.removeItem("FBIdToken");
        addToast("Session timed out. Please login Again", {
          appearance: "error",
        });
        dispatch(logOut(history));
      }
    }
  });

  return (
    <Container fluid>
      <Row
        style={{
          minHeight: "90vh",
        }}
      >
        {quotejobStatus && <QuoteJob />}

        <Sidebar />
        <Col
          sm={12}
          md={9}
          style={{
            // paddingBottom: "1.5rem",
            // alignItems: "stretch",
            // height: "100%",
            // position: "relative",
            // overflowY: "auto",
            // position: "fixed",
            // position: "fixed",
            padding: "20px 0 30px 0",
            // paddingBottom: "30px",
            // paddingLeft: "2rem",
            width: "100%",
            // margin: "0 auto",
          }}
        >
          {jobToUpdateStatus && <UpdateJobStatus />}
          <Switch>
            <Route exact path="/dashboard/business">
              <Dashboard />
            </Route>
            <Route exact path="/dashboard/business/edit">
              <EditBusiness />
            </Route>
            <Route exact path="/dashboard/business/buy-membership">
              <Subscriptions />
            </Route>
            <Route exact path="/dashboard/business/leads">
              <BusinessLeads />
            </Route>

            <Route exact path="/dashboard/business/register-business">
              <AboutBusiness />
            </Route>

            <Route exact path="/dashboard/business/jobtype">
              <JobType />
            </Route>
            <Route exact path="/dashboard/business/settings">
              <BusinessSettings />
            </Route>
            <Route exact path="/dashboard/jobs">
              <UserJobs />
            </Route>
            <Route exact path="/dashboard/reviews">
              <Reviews />
            </Route>
            <Route exact path="/dashboard/change-password">
              <ChangePassword />
            </Route>
            <Route exact path="/dashboard/add-business">
              <AddBusiness />
            </Route>
            <Route exact path="/dashboard/job/:id">
              <IndividualJobs />
            </Route>
            {/* <Route exact path="/dashboard/register-business">
              <AboutBusiness email={userEmail}/>
            </Route> */}
            <Route exact path="/dashboard/business/profile">
              <Profile />
            </Route>
            <Route exact path="/dashboard/business/profile/:id">
              <Profile />
            </Route>
          </Switch>
        </Col>
      </Row>
    </Container>
  );
};

export default WithSidebar;
