import React from "react";
import { Form } from "react-bootstrap";
import { useField } from "formik";

const SelectInput = props => {
  const [field, meta] = useField(props);
  const { options, name } = props;
  return (
    <div>
      <Form.Control
        as="select"
        custom
        name={name}
        value={field.value}
        // isValid={meta.touched && !meta.error}
        isInvalid={meta.touched && meta.error}
        // onChange={handleChange}
        {...field}
      >
        <option value="">Select one</option>
        {options.map(option => (
          <option value={option.value}>{option.label}</option>
        ))}

        {/* <option value="top tradie">Get quotes from top tradie</option> */}
      </Form.Control>
    </div>
  );
};

export default SelectInput;
