import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div>
      <h2 style={{ textAlign: "center", padding: "5rem 0 0 0" }}>404</h2>
      <p style={{ textAlign: "center", padding: "5rem 0 0 0" }}>
        This page isn't available. Sorry about that. Just use the link below to
        go back to the front page.
      </p>
      <h3 style={{ textAlign: "center", padding: "5rem" }}>
        <Link to="/">Go to Home</Link>
      </h3>
    </div>
  );
};

export default NotFoundPage;
