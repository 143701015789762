import React from "react";
import { Form } from "react-bootstrap";
import { useField } from "formik";

const RadioInput = props => {
  // const { data, name, ...rest } = props;
  const { setValue, name, value, label, id } = props;
  const [field, meta] = useField(props);
  // console.log("radio input field", field);
  // console.log("radio value", value);
  // console.log("setValue", setValue);
  return (
    <>
      <Form.Check
        custom
        type="radio"
        name={name}
        value={value}
        label={label}
        onChange={e => {
          // console.log("radio onchange", e.target.value);
          setValue(name, e.target.value);
          // console.log("radio onchange");
        }}
        checked={field.value === value}
        // onClick={e => {
        //   // console.log("radio onchange", e.target.value);
        //   // setValue(name, e.target.value);
        //   // console.log("radio onchange");
        //   console.log("clicked", value);
        // }}
        // onChange={setValue}
        // {...field}
        id={id}
      />
    </>
  );
};

export default RadioInput;
