import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_APIKEY,
  authDomain: process.env.REACT_APP_FB_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECTID,
  storageBucket: process.env.REACT_APP_FB_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FB_APPID,
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({ timestampsInSnapshot: true });
export const auth = firebase.auth();
export const firestore = firebase.firestore();

// Initialize Google Authentication Provider. Will provide us with the way to authenticate the user to Google API.
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

// Initialize Facebook Authentication Provider. Will provide us with the way to authenticate the user with Facebook API.
export const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();

export default firebase;
