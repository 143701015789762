import React from "react";
import { Form } from "react-bootstrap";
import { useField } from "formik";

const InputFields = props => {
  const { name, type, ...rest } = props;
  const [field, meta] = useField(props);
  console.log("fields", field);
  console.log("meta", meta);
  return (
    <Form.Group>
      <Form.Control
        type={type}
        name={name}
        {...rest}
        {...field}
        isInvalid={meta.touched && meta.error}
      />
      <Form.Control.Feedback type="invalid">
        {meta.error && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default InputFields;
