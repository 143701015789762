import React from "react";
import { Form } from "react-bootstrap";
import { useField } from "formik";

const FileInput = props => {
  const { name, setValue, values } = props;
  const [field, meta] = useField(props);
  console.log("file input field", field);
  console.log("file input meta", meta);
  return (
    <div>
      <Form.Group>
        <Form.Label>Attachments</Form.Label>
        <Form.File custom name={name} multiple {...field} />
      </Form.Group>
    </div>
  );
};

export default FileInput;
