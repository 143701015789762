import React, { useState, useEffect } from "react";
import "./list-business.css";
import { useHistory } from "react-router-dom";
// import Button from "../Button";
import { Container, Button, Col } from "react-bootstrap";
import { FacebookProvider, Page } from "react-facebook";
import listBusiness from "../../assets/img/listBusiness.jpeg";
import TradieBannerImage from "../../assets/img/TradieBannerImage.png";
// import Announcement from "../Announcement";

const ListBusiness = () => {
  // const [offsetY, setOffsetY] = useState(0);
  // // const parallaxContainer = useRef();
  // const handleScroll = () => {
  //   // console.log(window.pageYOffset);
  //   setOffsetY(window.pageYOffset);
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);

  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);
  // console.log(offsetY);
  const history = useHistory();
  const goToRegisterBusiness = () => history.push("/register-business");
  return (
    // <Container fluid>
    <div
      className="list-business"
      // style={{ transform: `translateY(${offsetY * 0.5}px)` }}
    >
      <div className="parallax_background"></div>
      <div
        className="list-business__content"
        // style={{ transform: `translateY(${offsetY * 0.8}px)` }}
      >
        <Container>
          <h2>
            If you run a successfull business. List your business and grow your
            business
          </h2>
          <Button
            onClick={goToRegisterBusiness}
            size="lg"
            style={{ padding: "1rem 1.5rem" }}
          >
            {/* <Link to="/register-business"> */}
            <h3 style={{ margin: "0" }}>Start 12 months Free Trial</h3>
            {/* </Link> */}
          </Button>
        </Container>
      </div>
    </div>
    // </Container>
  );
};

export default ListBusiness;
