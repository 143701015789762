import React, { useState } from "react";
// import logo from '../../assets/img/logo-small.png';
import logoBig from "../../assets/img/logo-big.webp";
import "./header.css";
import { withRouter, NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
// import { user } from "../../redux/actions/userAction";
// import firebase from "../../firebase/firebase-config";
import { useSelector, useDispatch } from "react-redux";
import { logOut } from "../../redux/actions/userAction";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Image, Dropdown, Badge } from "react-bootstrap";
// import {FaBars} from 'react-icons/fa'
import { FiMenu } from "react-icons/fi";
// import MobileNav from "../mobile-nav/MobileNav";

const Header = ({ openMenu }) => {
  const dispatch = useDispatch();

  // const handleLogOut = () => {
  //   firebase
  //     .auth()
  //     .signOut()
  //     .then(() => {
  //       setUser(null);
  //       history.push("/");
  //     })
  //     .catch(err => {
  //       console.log(err.message);
  //     });
  // };

  const auth = useSelector((state) => state.user.profile);
  const history = useHistory();
  const location = useLocation();

  // console.log("location in header js", location);

  return (
    <nav
      // className={location.pathname === "/" ? "nav-custom home" : "nav-custom"}
      // style={{ height: "10vh" }}
      className="nav-custom"
    >
      {/* <Container fluid> */}
      {/* <Row style={{ alignItems: "center" }}> */}
      {/* <Col xs={3}> */}
      <div className="logo">
        <Link to="/">
          <img
            src={logoBig}
            alt="logo"
            className="logo"
            width="260px"
            height="60px"
          />
        </Link>
      </div>
      {/* </Col> */}
      {/* <Col xs={7}> */}
      <div className="nav-menu">
        <ul
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 0,
            height: "100%",
          }}
        >
          {!auth && (
            <li>
              <NavLink
                to="/"
                exact
                activeStyle={{
                  borderBottom: "2px solid #0FB2E8",
                  backgroundCcolor: "whitesmoke",
                }}
              >
                Home
              </NavLink>
            </li>
          )}

          <li>
            <NavLink
              to="/about-us"
              activeStyle={{ borderBottom: "2px solid #0FB2E8" }}
            >
              About Us
            </NavLink>
          </li>
          {!auth ? (
            <li>
              <NavLink
                to="/register-business"
                activeStyle={{ borderBottom: "2px solid #0FB2E8" }}
              >
                Register as Business
              </NavLink>
            </li>
          ) : auth && !auth.business ? (
            <li>
              <NavLink
                to="/add-business"
                activeStyle={{ borderBottom: "2px solid #0FB2E8" }}
              >
                Register Business
              </NavLink>
            </li>
          ) : null}
          <li>
            <NavLink
              to="/contact-us"
              activeStyle={{ borderBottom: "2px solid #0FB2E8" }}
            >
              Contact Us
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              to="/job/bDNrnZioulfzHQkIfvVx"
              activeStyle={{ borderTop: "2px solid red" }}
            >
              Job
            </NavLink>
          </li> */}
        </ul>
      </div>
      {/* </Col> */}
      {/* <Col xs={2}> */}
      <div className="loginSignup">
        {!auth ? (
          <>
            <NavLink
              to="/user/signin"
              style={{ padding: "0", display: "block" }}
            >
              <Button variant="primary" style={{ width: "100%" }}>
                Login
              </Button>
            </NavLink>
            <NavLink
              to="/user/signup"
              style={{ padding: "0", display: "block" }}
            >
              <Button variant="primary" style={{ width: "100%" }}>
                Sign Up
              </Button>
            </NavLink>
          </>
        ) : (
          <>
            <Dropdown
              classname="nav-dropdown"
              style={{
                border: "2px solid white",
                padding: "5px 0",
                cursor: "pointer",
                display: "grid",
                gridColumnStart: "1",
                gridColumnEnd: "3",
              }}
              menuAlign="left"
            >
              <Dropdown.Toggle as={Badge} style={{ color: "white" }}>
                <Image
                  src={auth.photoUrl}
                  roundedCircle
                  style={{
                    width: "35px",
                    height: "35px",
                    objectFit: "cover",
                    marginRight: ".5rem",
                  }}
                />{" "}
                <span className="capitalize">{auth.name}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="2">
                  <NavLink
                    to={
                      auth.business
                        ? "/dashboard/business/"
                        : "/dashboard/jobs/"
                    }
                    style={{ color: "#000" }}
                  >
                    Dashboard
                  </NavLink>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Badge
                  pill
                  variant="secondary"
                  onClick={() => dispatch(logOut(history))}
                  style={{
                    display: "block",
                    width: "80%",
                    margin: "0 auto",
                    padding: "10px",
                  }}
                >
                  Log Out
                </Badge>
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}
      </div>
      <FiMenu className="hamburger-menu" onClick={openMenu} />
      {/* </Col> */}
      {/* </Row> */}
      {/* </Container> */}
      <Link
        to="/register-business"
        className="cta-link"
        // style={{
        //   backgroundColor: "#367373",
        //   color: "#fff",
        //   padding: "10px",
        //   borderRadius: "5px",
        // }}
      >
        Start 12 months Free Trial
      </Link>
    </nav>
  );
};

// const mapStateToProps = state => ({
//   // user: state.user,
// });

// export default connect(mapStateToProps)(withRouter(Header));
export default Header;
