import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import HomeHero from "../components/home-hero/HomeHero";
import FullWidthForm from "../components/fullWidth-form/FullWidthForm";
import HowWeDo from "../components/how-we-do/HowWeDo";
import RecentActivity from "../components/recent-activity/RecentActivity";
import ListBusiness from "../components/list_business/ListBusiness";
import Categories from "../components/categories/Categories";
import Announcement from "../components/Announcement";
import Testimonial from "../components/testimonials/Testimonial";

const Home = () => {
  const { ref, inView } = useInView({ threshold: 0 });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HomeHero ref={ref} />
      {!inView && <FullWidthForm />}
      <HowWeDo />
      <RecentActivity />
      {/* <Testimonial /> */}
      <Categories />
      <ListBusiness />
    </>
  );
};

export default Home;
