import React, { useState, useEffect } from "react";
import { enGB } from "date-fns/locale";
import { DatePicker } from "react-nice-dates";
import "react-nice-dates/build/style.css";
// Redux Stuff
import { connect } from "react-redux";
import {
  setActiveStep,
  setWhen,
  setPriority,
  submitJob,
} from "../../../redux/actions/jobAction";
import RadioInput from "../../forms/FormFields/RadioInput";
import SelectInput from "../../forms/FormFields/SelectInput";
import { Form, Button } from "react-bootstrap";
import { useField } from "formik";
// import { set } from "date-fns";
import { Formik } from "formik";
import * as yup from "yup";
import { useHistory } from "react-router-dom";

const JobWhen = props => {
  const {
    setValue,
    values,
    errors,
    when,
    priority,
    activeStep,
    setActiveStep,
    user,
    setWhen,
    setPriority,
  } = props;
  // const [field, meta] = useField(props)
  // const [fieldWhen, metaWhen] = useField({ name: "when" });
  // console.log(fieldWhen.value);
  // const [fieldDate, metaDate] = useField({ name: "date" });
  // console.log("field date", fieldDate);
  const currentDate = new Date();
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState();
  const options = [
    { label: "Get as many as quotes as possible", value: "every business" },
    { label: "Get quotes from top tradie", value: "top tradie" },
  ];
  const history = useHistory();

  useEffect(() => {
    if (when.whenValue === "date") {
      when.startDate
        ? setStartDate(when.startDate)
        : setStartDate(new Date(when.startDate));
      when.endDate && setEndDate(new Date(when.endDate));
    }
    // setWhenLocal({ value: when.value });
  }, []);

  const formikInitialValues = {
    when,
    priority,
  };

  const validationSchema = yup.object().shape({
    when: yup.object().shape({
      whenValue: yup
        .string()
        .required("Please select when do you need the job done"),
    }),
    priority: yup.string().required("Select one of the options"),
  });

  const radioStyle = {
    borderBottom: "1px solid gray",
    padding: "10px",
    marginBottom: "0",
  };

  const handleSubmit = values => {
    console.log("jobwhen values", values);
    if (values.when.whenValue !== "date") {
      setWhen(values.when);
    } else {
      if (endDate) {
        setWhen({
          whenValue: values.when.whenValue,
          startDate,
          endDate,
        });
      } else {
        setWhen({
          whenValue: values.when.whenValue,
          startDate,
        });
      }
    }
    setPriority(values.priority);
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  // console.log("formik values", values);

  return (
    <Formik
      initialValues={formikInitialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({
        handleSubmit,
        isSubmitting,
        setFieldError,
        setFieldValue,
        values,
        errors,
        resetForm,
        touched,
      }) => (
        <Form onSubmit={handleSubmit}>
          <>
            {/* {console.log("jobWhen values", values)}
            {console.log("errors", errors)} */}
            <Form.Label>When do you need the job done</Form.Label>
            <div
              style={{
                border: "1px solid gray",
                borderRadius: "10px",
              }}
            >
              {/* <Form.Control as="radio" name="value"> */}
              <Form.Group style={radioStyle}>
                <RadioInput
                  name="when.whenValue"
                  value="date"
                  label="Particular Date"
                  setValue={setFieldValue}
                  id="date"
                />
                {values.when.whenValue === "date" && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <DatePicker
                      date={startDate}
                      // onDateChange={setStartDate}
                      onDateChange={value => {
                        setStartDate(value);
                        // console.log("date target value", value);
                        // setFieldValue("when.startDate", value);
                      }}
                      locale={enGB}
                      minimumDate={currentDate}
                      // onChange={e => setValue("date.startDate", e.target.value)}
                      // name="jobWhen.startDate"
                      value={startDate}
                    >
                      {({ inputProps, focused }) => (
                        <input
                          className={"input" + (focused ? " -focused" : "")}
                          {...inputProps}
                          placeholder="Start Date"
                          required
                        />
                      )}
                    </DatePicker>
                    <DatePicker
                      date={endDate}
                      // onDateChange={setEndDate}
                      onDateChange={date => {
                        setEndDate(date);
                        // setFieldValue("date.endDate", date);
                      }}
                      locale={enGB}
                      minimumDate={startDate}
                      onChange={
                        e => {
                          setEndDate(e.target.value);
                        }
                        // setFieldValue("date.endDate", e.target.value)
                      }
                      value={endDate}
                      // name="jobWhen.endDate"
                    >
                      {({ inputProps, focused }) => (
                        <input
                          className={"input" + (focused ? " -focused" : "")}
                          {...inputProps}
                          placeholder="End Date"
                        />
                      )}
                    </DatePicker>
                  </div>
                )}
              </Form.Group>
              <Form.Group style={radioStyle}>
                <RadioInput
                  name="when.whenValue"
                  value="Next Couple of Weeks"
                  label="Next Couple of Weeks"
                  setValue={setFieldValue}
                  id="next-couple-of-weeks"
                />
              </Form.Group>
              <Form.Group style={radioStyle}>
                <RadioInput
                  name="when.whenValue"
                  value="This week"
                  label="This Week"
                  setValue={setFieldValue}
                  id="this-week"
                />
              </Form.Group>
              <Form.Group style={{ ...radioStyle, border: 0 }}>
                <RadioInput
                  name="when.whenValue"
                  value="As soon as possible"
                  label="As soon as possible"
                  setValue={setFieldValue}
                  id="asap"
                />
              </Form.Group>
              {/* </Form.Control> */}
            </div>

            <Form.Control.Feedback
              type="invalid"
              style={{
                display: `${touched.when && errors.when ? "block" : "none"}`,
              }}
            >
              {errors.when}
            </Form.Control.Feedback>

            <Form.Group controlId="exampleForm.SelectCustom" className="mt-3">
              <Form.Label>
                Recieve quotes from top tradie or every business.
              </Form.Label>

              <SelectInput
                options={options}
                name="priority"
                setValue={setFieldValue}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{
                  display: `${
                    touched.priority && errors.priority ? "block" : "none"
                  }`,
                }}
              >
                {errors.priority}
              </Form.Control.Feedback>
            </Form.Group>
          </>
          <Form.Row
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button type="button" onClick={handleBack}>
              Back
            </Button>
            <Button type="submit">Continue</Button>
          </Form.Row>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = state => ({
  activeStep: state.job.activeStep,
  when: state.job.when,
  priority: state.job.priority,
  user: state.user.profile,
});

const mapDispatchToProps = { setWhen, setActiveStep, setPriority };

export default connect(mapStateToProps, mapDispatchToProps)(JobWhen);
