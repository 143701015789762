import React from "react";
import styled from "styled-components";

const Button = ({ children, handleClick }) => {
  const style = {
    padding: "13px 25px",
    // backgroundColor: 'rgb(17, 71, 112)',
    // backgroundColor: "#0169D9",
    background: "transparent",
    border: "1px solid #0169D9",
    color: "#0169D9",
    borderRadius: "6px",
    outline: "none",
  };

  const Button = styled.button`
    padding: 13px 25px;
    background: transparent;
    border: 1px solid #0169d9;
    color: #fff;
    border-radius: 6px;
    outline: none;

    &:hover {
      background: #fff;
      border: none;
      color: #0169d9;
    }
  `;

  return <Button onClick={handleClick}>{children}</Button>;
};

export default Button;
