import React, { useEffect, useState } from "react";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Badge, Row, Col, Button, Spinner, Alert } from "react-bootstrap";
import { useLoadScript } from "@react-google-maps/api";
import MapComponent from "../google-map/MapComponent";
// import Slider, { SliderTooltip } from "rc-slider";
import "rc-slider/assets/index.css";
// import { changeRadius } from "../../redux/actions/businessActions";
import { useHistory } from "react-router-dom";
// import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { SET_AUTHENTICATED } from "../../redux/types";
import SubscriptionItems from "./SubscriptionItems";
import { FiCheckSquare, FiChevronLeft } from "react-icons/fi";
import { createFirebaseConnect } from "react-redux-firebase";

// export const stripePromise = loadStripe(
//   "pk_test_51IpkqAJf3ioUV8IXN0UiDJY9viZCZ8TjAUIcryuF4WopUWPtLX1JjS3QyyMUC6yut4RhqoGZ6rPpnSXGcsKcghvT00TutiHoVu"
// );

export const stripePromise = loadStripe(
  "pk_live_51IpkqAJf3ioUV8IXu6IzU0DlKJHaRFvQ7oGXCLwbuUcPNfgphOZTjxIjyq12buwgEHrTFWXNAHjCHrZ5ReKjZRbO00ucchd0i0"
);

const libraries = ["places"];

function SubscribeToPlan(props) {
  const stripe = useStripe();
  const elements = useElements();
  // const user = useUser();
  const user = useSelector((state) => state.user.profile);
  const businessDetails = user.business;
  const radius = user.business.radius;
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDPoIPwIh0EFiEE5ys3mN9UFRnB2n5dJ3o",
    libraries,
  });
  const { addToast } = useToasts();

  // const [price, setPrice] = useState();
  const [prices, setPrices] = useState();
  // const [quantity, setQuantity] = useState();
  const [error, setError] = useState();
  // const [subscription, setSubscription] = useState();
  const [chosenSubscription, setChosenSubscription] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const [step, setStep] = useState(1);

  const stripeOptions = {
    style: {
      base: {
        color: "#424770",
        letterSpacing: "0.025em",
        fontFamily: "Source Code Pro, monospace",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };

  // console.log("reactfire user", user);

  // Get current subscriptions on mount
  useEffect(() => {
    // getSubscriptions();
    getPrices();
    // checkSubscriptionPricesAndPlan(radius);
  }, []);

  useEffect(() => {
    checkSubscriptionPricesAndPlan(radius);
    // return () => checkSubscriptionPricesAndPlan();
  }, [radius, prices]);

  // Fetch all subscription plan from our server via stripe
  const getPrices = async () => {
    try {
      const pricespromise = await axios.get("subscription_prices");
      // console.log("prices raw", pricespromise);
      // console.log("all subscriptions", pricespromise.data);
      setPrices(pricespromise.data);
    } catch (e) {
      console.log("subscription prices error", e);
    }
  };

  // Handle the submission of card details
  const handleSubmit = async (event) => {
    setLoading(true);
    setError();
    event.preventDefault();

    try {
      // const cardElement = elements.getElement(CardElement);
      const cardElement = elements.getElement(CardNumberElement);

      // Create Payment Method
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      // console.log("payment method", paymentMethod);

      if (error) {
        // alert(error.message);
        setError("Unable to pay with this card. Please try again");
        setLoading(false);
        return;
      }

      // Create Subscription on the Server
      // const subscription = await fetchFromAPI("subscriptions", {
      //   body: {
      //     price,
      //     payment_method: paymentMethod.id,
      //   },
      // });

      // Create Subscription on the Server
      const subscriptiononServer = await axios.post("/subscriptions", {
        radius: radius,
        price: chosenSubscription.id,
        payment_method: paymentMethod.id,
      });

      // The subscription contains an invoice
      // If the invoice's payment succeeded then you're good,
      // otherwise, the payment intent must be confirmed

      console.log("create subscription response", subscriptiononServer);

      const { latest_invoice, plan } = subscriptiononServer.data;

      if (latest_invoice.payment_intent) {
        const { client_secret, status } = latest_invoice.payment_intent;

        if (status === "requires_action") {
          const { error: confirmationError } = await stripe.confirmCardPayment(
            client_secret
          );
          if (confirmationError) {
            console.error(confirmationError);
            // alert("unable to confirm card");
            setError("Unable to confirm card. Please try again");
            setLoading(false);
            return;
          }
        }

        // success
        // alert("You are subscribed!");

        // getSubscriptions();
      }

      // const res = await axios.get("/user-details");
      dispatch({
        type: SET_AUTHENTICATED,
        user: {
          ...user,
          membership: "pro",
          status: "active",
          activePlan: plan.id,
        },
      });
      addToast("Get ready to quote on the jobs!!!", {
        appearance: "success",
      });
      // axios
      //   .get("/user-details")
      //   .then(res => {

      //
      //
      //   })
      //   .catch(e => {
      //     setError(

      //     );
      //     console.log(e);
      //   });

      setLoading(false);
      history.push("/dashboard/business");
    } catch (error) {
      console.log("subscription error", error);
      setError(error.message);
      setLoading(false);
    }

    // setPrice(null);
  };

  /**  Subscribe to plan with defaul payment method (without credit card details)
    const subscribeToPlan = async () => {
      setLoading(true);
      const subscription = await axios.post("/subscriptions", {
        price,
      });

      const { latest_invoice } = subscription.data;

      if (latest_invoice.payment_intent) {
        const { client_secret, status } = latest_invoice.payment_intent;

        if (status === "requires_action") {
          const { error: confirmationError } = await stripe.confirmCardPayment(
            client_secret
          );
          if (confirmationError) {
            console.error(confirmationError);
            alert("unable to confirm card");
            return;
          }
        }

        // success
        alert("You are subscribed!");
        // getSubscriptions();
      }

      setLoading(false);
      setPrice(null);
    };
  *******/

  const checkSubscriptionPricesAndPlan = (radius) => {
    // const radius = businessDetails.radius;
    const chosenSubscription =
      prices &&
      prices.find((price) => radius === parseInt(price.metadata.max_radius));

    // console.log("chosenSubscription", chosenSubscription);
    // return chosenSubscription;
    // setSubscriptions(chosenSubscription);
    setChosenSubscription(chosenSubscription);
  };

  const next = () => {
    setError();
    setStep((step) => step + 1);
  };

  const back = () => {
    setError();
    setStep((step) => step - 1);
  };

  return (
    <div className="container h-100">
      {error && <Alert variant="danger">{error}</Alert>}
      {console.log("prices", prices)}
      {step === 1 && (
        <>
          <p style={{ marginBottom: ".5rem" }}>
            Confirm what leads you want to recieve around{" "}
            <span style={{ fontWeight: "bold" }}>
              {businessDetails.location.name}, {businessDetails.location.state}{" "}
              {businessDetails.location.postcode}
            </span>
          </p>
          <div
            style={{
              padding: "10px",
              borderRadius: "5px",
              marginBottom: "1rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: "white",
            }}
            className="bg-secondary bg-gradient"
          >
            <div>
              {businessDetails.industry.map((jobtype) => {
                return (
                  <span>
                    <span style={{ fontWeight: "bold" }}>{jobtype.name} </span>
                    {jobtype.categories &&
                      `(${jobtype.categories.length} subcategories)`}
                  </span>
                );
              })}
            </div>
            {/* <Link to="/dashboard/business/jobtype">
          <Button variant="outline-light">Edit</Button>
        </Link> */}
          </div>
          <p className="mb-0">Select the subscription plan</p>

          {/* <div>
        <Slider
          min={6}
          max={12}
          // defaultValue={businessDetails.radius}
          step={2}
          value={businessDetails.radius}
          handle={handle}
          marks={{
            6: "6 km",
            8: "8 km",
            10: "10 km",
            12: "12",
          }}
          onChange={value => dispatch(changeRadius(value))}
        />
      </div> */}

          <div className="mt-2">
            {prices ? (
              <SubscriptionItems
                prices={prices}
                activeSubscription={chosenSubscription}
              />
            ) : (
              <div style={{ display: "grid", placeItems: "center" }}>
                <Spinner animation="border" size="sm" />
              </div>
            )}
            {/* <SubscriptionItems /> */}
          </div>

          <div
            style={{
              height: "450px",
              marginTop: "1rem",
            }}
            // ref={mapContainer}
          >
            <MapComponent
              center={{
                lat: businessDetails.location.lat,
                lng: businessDetails.location.lng,
              }}
              radius={businessDetails.radius * 1000}
              zoom={
                businessDetails.location.lat !== -25.274399 &&
                businessDetails.location.lng !== 133.775131
                  ? 11
                  : 4
              }
              isLoaded={isLoaded}
              loadError={loadError}
              // value={values}
            />
          </div>
          {chosenSubscription && (
            <div className="premium_privileges mt-4 shadow">
              <div className="premium_heading">
                <p className="m-0">
                  ${chosenSubscription.unit_amount / 100} monthly
                </p>

                <h5 className="mb-0">
                  <Badge pill variant="primary" style={{ marginTop: ".3rem" }}>
                    Upto {chosenSubscription.metadata.max_radius}km
                  </Badge>{" "}
                </h5>
              </div>
              <div className="px-3">
                <p>
                  <FiCheckSquare />
                  <span>Unlimited quoting on leads</span>
                </p>
                <p>
                  <FiCheckSquare />
                  Email and Messages notifications
                </p>
                <p>
                  <FiCheckSquare />
                  Recieve leads with mobile number
                </p>
              </div>
              <hr />

              <Button
                className="btn w-50 ml-3 mb-3"
                variant="outline-success"
                onClick={next}
              >
                Next
              </Button>
            </div>
          )}
        </>
      )}

      {step === 2 && (
        <div className="card_container">
          <div className="card_form shadow">
            <h6
              className="mb-4 text-muted"
              onClick={back}
              style={{ cursor: "pointer" }}
            >
              <FiChevronLeft /> Back
            </h6>
            <hr />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "60% 40%",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <div className="mb-2 ">
                  {businessDetails.industry.map((item) => (
                    <Badge variant="info">{item.name}</Badge>
                  ))}
                </div>
                <p className="mb-1">
                  Upto {chosenSubscription.metadata.max_radius} km radius around{" "}
                  {businessDetails.location.name},{" "}
                  {businessDetails.location.state}
                </p>
              </div>
              {/* <div className="bg-secondary"> */}
              <h4 style={{ textAlign: "right" }}>
                ${chosenSubscription.unit_amount / 100}
              </h4>
              {/* </div> */}
            </div>
            <hr />
            <form
              onSubmit={handleSubmit}
              className="well"
              // hidden={!price}
              //  hidden={!subscription || subscription !== chosenSubscription.id}
              // style={{ maxWidth: "400px" }}
            >
              <label className="stripe">
                Card number
                <CardNumberElement
                  options={stripeOptions}
                  // onReady={() => {
                  //   console.log("CardNumberElement [ready]");
                  // }}
                  // onChange={event => {
                  //   console.log("CardNumberElement [change]", event);
                  // }}
                  // onBlur={() => {
                  //   console.log("CardNumberElement [blur]");
                  // }}
                  // onFocus={() => {
                  //   console.log("CardNumberElement [focus]");
                  // }}
                />
              </label>
              <label className="stripe">
                Expiration date
                <CardExpiryElement
                  options={stripeOptions}
                  // onReady={() => {
                  //   console.log("CardNumberElement [ready]");
                  // }}
                  // onChange={event => {
                  //   console.log("CardNumberElement [change]", event);
                  // }}
                  // onBlur={() => {
                  //   console.log("CardNumberElement [blur]");
                  // }}
                  // onFocus={() => {
                  //   console.log("CardNumberElement [focus]");
                  // }}
                />
              </label>
              <label className="stripe">
                CVC
                <CardCvcElement
                  options={stripeOptions}
                  // onReady={() => {
                  //   console.log("CardNumberElement [ready]");
                  // }}
                  // onChange={event => {
                  //   console.log("CardNumberElement [change]", event);
                  // }}
                  // onBlur={() => {
                  //   console.log("CardNumberElement [blur]");
                  // }}
                  // onFocus={() => {
                  //   console.log("CardNumberElement [focus]");
                  // }}
                />
              </label>
              <Button
                className="mt-3 w-50"
                variant="primary"
                type="submit"
                disabled={loading}
              >
                {loading ? <Spinner animation="border" size="sm" /> : "Pay"}
              </Button>
            </form>
          </div>
        </div>
        //  </Col>
      )}

      {/* </Col> */}
    </div>
  );
}

// export default SubscribeToPlan;

function Subscriptions() {
  return (
    // <Suspense fallback={"loading user"}>
    <Elements stripe={stripePromise}>
      <SubscribeToPlan />
    </Elements>
    // </Suspense>
  );
}

export default Subscriptions;
