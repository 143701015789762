import {
  JOB,
  LOCATION,
  DETAILS,
  WHEN,
  PRIORITY,
  STEP,
  SHOW_POSTJOB,
  DESCRIPTION,
  ATTACHMENT,
  JOB_SUBMIT_SUCCESS,
  JOB_SUBMIT_ERROR,
  RESET_JOB,
  SET_USER,
  CLEAR_USER,
  QUOTE_JOB,
  JOB_STATUS_UPDATE,
  REMOVE_ATTACHMENT,
} from "../types";

/* eslint-disable import/no-anonymous-default-export */
const initialState = {
  job: {
    id: "",
    name: "",
  },
  location: { name: "", lat: "", lng: "", postcode: "" },
  description: "",
  attachments: [],
  when: { whenValue: "" },
  priority: "",
  activeStep: 0,
  // steps: ["About Job", "Details", "When", "Priority"],
  showPostJob: false,
  user: {
    exists: null,
    email: "",
  },
  quoteJob: {
    status: false,
    job: null,
  },
  jobStatusUpdate: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case JOB:
      return {
        ...state,
        job: action.payload,
      };
    case LOCATION:
      return {
        ...state,
        location: action.payload,
      };

    case DESCRIPTION:
      return {
        ...state,
        description: action.description,
      };

    case ATTACHMENT:
      return {
        ...state,
        attachments: [...state.attachments, action.attachment],
      };

    case WHEN:
      return {
        ...state,
        when: action.payload,
      };
    case PRIORITY:
      return {
        ...state,
        priority: action.payload,
      };
    case STEP:
      return {
        ...state,
        activeStep: action.payload,
      };
    case SHOW_POSTJOB:
      return {
        ...state,
        showPostJob: action.payload,
      };
    case SET_USER:
      return {
        ...state,
        user: action.user,
      };
    case CLEAR_USER: {
      return {
        ...state,
        user: {
          exists: null,
          email: "",
        },
      };
    }
    case JOB_SUBMIT_SUCCESS:
      // return {
      //   job: "",
      //   location: { name: "", lat: "", lng: "" },
      //   description: "",
      //   attachments: [],
      //   when: { whenValue: "" },
      //   priority: "",
      //   activeStep: 0,
      //   // steps: ["About Job", "Details", "When", "Priority"],
      //   showPostJob: false,
      // };
      return initialState;

    case RESET_JOB:
      return initialState;

    case JOB_SUBMIT_ERROR:
      return state;

    case QUOTE_JOB:
      return {
        ...state,
        quoteJob: action.quoteJob,
      };

    case JOB_STATUS_UPDATE:
      return {
        ...state,
        jobStatusUpdate: action.job,
      };

    case REMOVE_ATTACHMENT:
      return {
        ...state,
        attachments: state.attachments.filter(
          attachment => attachment.name !== action.attachment_name
        ),
      };
    default:
      return state;
  }
}
