import React, { useState, useEffect } from "react";
import "./recent-activity.css";
import { Container, Spinner } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
// import { formatDistanceToNow } from "date-fns";
import { useSelector } from "react-redux";

const RecentActivity = () => {
  const [activities, setActivities] = useState();
  const auth = useSelector((state) => state.user.profile);

  useEffect(() => {
    axios
      .get("latest-jobs")
      .then((res) => {
        setActivities(res.data);
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="fluid">
      <Container fluid>
        <section className="recent-activity">
          {/* <div style={{ width: "100%" }}> */}
          <div className="activity-container">
            <h2 className="section-header">Recent Activity</h2>
            <div
              style={{
                maxHeight: "460px",
                overflowY: "auto",
                cursor: "pointer",
                width: "100%",
              }}
            >
              {activities ? (
                activities.map((activity) =>
                  auth && auth.business ? (
                    <Link
                      to={`/job/${activity.id}`}
                      key={activity.id}
                      style={{ color: "#000" }}
                    >
                      <div className="activity__item">
                        <p style={{ margin: 0, textAlign: "center" }}>
                          <span style={{ fontWeight: "bold" }}>
                            {activity.name}
                          </span>{" "}
                          is looking for{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {activity.jobType.name}
                          </span>{" "}
                          in{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {activity.location.name}, {activity.location.state}
                          </span>
                        </p>
                        {/* <p style={{ margin: 0 }}>
              {formatDistanceToNow(new Date(activity.createdAt), {
                addSuffix: true,
              })}
            </p> */}
                      </div>
                    </Link>
                  ) : (
                    <Link
                      to="/register-business"
                      style={{ color: "#000" }}
                      key={activity.id}
                    >
                      <div className="activity__item">
                        <p style={{ margin: 0, textAlign: "center" }}>
                          <span style={{ fontWeight: "bold" }}>
                            {activity.name}
                          </span>{" "}
                          is looking for{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {activity.jobType.name}
                          </span>{" "}
                          in{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {activity.location.name}, {activity.location.state}
                          </span>
                        </p>
                        {/* <p style={{ margin: 0 }}>
                {formatDistanceToNow(new Date(activity.createdAt), {
                  addSuffix: true,
                })}
              </p> */}
                      </div>
                    </Link>
                  )
                )
              ) : (
                <Spinner animation="border" size="sm" />
              )}
            </div>
          </div>
          {/* </div> */}
        </section>
      </Container>
    </div>
  );
};

export default RecentActivity;
