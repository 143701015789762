import React from "react";
// import './mobile-nav.css'
import {
  MobileNavContainer,
  CloseIcon,
  NavMenu,
  Icon,
  NavItems,
  BtnWrap,
  NavButton,
  NavWrapper,
  Logo,
} from "./MobileNavElements";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import logoBig from "../../assets/img/logo-big.webp";
import { logOut } from "../../redux/actions/userAction";

const MobileNav = ({ Open, close }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.user.profile);

  const closeButton = (to) => {
    close();
    history.push(to);
  };

  const logOutBtn = () => {
    dispatch(logOut(history));
    close();
  };

  return (
    <MobileNavContainer isOpen={Open}>
      <Icon>
        <CloseIcon onClick={close} />
      </Icon>
      <NavWrapper>
        <Logo src={logoBig} alt="logo" width="200px" height="45px" />

        <NavMenu>
          <NavItems
            to={
              auth
                ? auth.business
                  ? "/dashboard/business"
                  : "/dashboard/customer"
                : "/"
            }
            onClick={close}
          >
            Home
          </NavItems>
          {auth && (
            <>
              <NavItems to="/dashboard/business" onClick={close}>
                Business Account
              </NavItems>
              <NavItems to="/dashboard/customer" onClick={close}>
                Customer Account
              </NavItems>
            </>
          )}
          <NavItems to="/about-us" onClick={close}>
            About Us
          </NavItems>
          <NavItems to="/contact-us" onClick={close}>
            Contact Us
          </NavItems>
          {(!auth || !auth.business) && (
            <NavItems to="/register-business" onClick={close}>
              Register Business
            </NavItems>
          )}
        </NavMenu>
        <BtnWrap>
          {!auth ? (
            <>
              <NavButton onClick={() => closeButton("/user/signin")}>
                Log In
              </NavButton>
              <NavButton onClick={() => closeButton("/user/signup")}>
                Sign Up
              </NavButton>
            </>
          ) : (
            <NavButton onClick={logOutBtn}>Log Out</NavButton>
          )}
        </BtnWrap>
      </NavWrapper>
    </MobileNavContainer>
  );
};

export default MobileNav;
