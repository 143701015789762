import React from "react";
import {
  Card,
  Row,
  Col,
  OverlayTrigger,
  Button,
  Tooltip,
  Popover,
  Badge,
} from "react-bootstrap";
import { formatDistanceToNow, format } from "date-fns";
import InfiniteCarousel from "react-leaf-carousel";
// import { BiLabel } from "react-icons/bi";
import { FiFlag, FiAtSign } from "react-icons/fi";
import { AiOutlineMobile } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { QUOTE_JOB } from "../../redux/types";

const RelatedJobs = ({ jobs }) => {
  const jobLocation = location => {
    const locationArray = location.split(" ");
    if (locationArray.length > 2) {
      return `${locationArray[0]} ${locationArray[1]}, ${locationArray[2]}`;
    } else {
      return `${locationArray[0]} ${locationArray[1]}`;
    }
  };

  const dispatch = useDispatch();
  const auth = useSelector(state => state.user.profile);

  const quoteJob = job => {
    dispatch({ type: QUOTE_JOB, quoteJob: { status: true, job } });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          color: "white",
          background: "#124870",
          padding: "1rem",
          marginBottom: "1rem",
        }}
      >
        <h6
          style={{
            // textAlign: "center",
            margin: "0",
          }}
        >
          <FiFlag /> {auth.leads.length} Leads Available
        </h6>
        <h6
          style={{
            // textAlign: "center",
            margin: "0",
          }}
        >
          <Link to="/dashboard/business/leads">View All</Link>
        </h6>
      </div>
      {jobs.length > 0 ? (
        <InfiniteCarousel
          breakpoints={[
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ]}
          dots={true}
          showSides={true}
          sidesOpacity={0.5}
          sideSize={0.1}
          slidesToScroll={1}
          slidesToShow={1}
          scrollOnDevice={true}
          // animationDuration={100}
        >
          {/* <Accordion defaultActiveKey="0"> */}
          {jobs.map((job, i) => {
            let jobIndustry = (
              <span style={{ textTransform: "capitalize", fontWeight: "bold" }}>
                {job.jobType.name}
              </span>
            );
            // console.log("jobIndustry", jobIndustry);
            return (
              <Card
                key={job.id}
                style={{
                  // marginBottom: "10px",
                  // width: "300px",
                  // background: "#ccc",
                  width: "100%",
                  minHeight: "320px",
                  boxShadow: "0 2px 10px rgb(0 0 0 / 20%)",
                  // border: "none",
                }}
              >
                <Card.Header
                  style={{
                    background: "transparent",
                    display: "flex",
                    justifyContent: "space-between",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h6 style={{ margin: 0 }}>
                    {job.name}'s{" "}
                    <span className="capitalize">{jobIndustry}</span> job at{" "}
                    {jobLocation(job.location.name)}
                  </h6>
                  {auth.membership && auth.membership !== "pro" ? (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`${i}-topplacement`}>
                          Buy Membership
                        </Tooltip>
                      }
                    >
                      <Link
                        to="/dashboard/business/buy-membership"
                        style={{ color: "#6c757d" }}
                      >
                        Quote
                      </Link>
                    </OverlayTrigger>
                  ) : (
                    <Button
                      variant="outline-primary"
                      // disabled={
                      //   (auth.membership && auth.membership !== "pro") ||
                      //   (auth.status && auth.status !== "active")
                      // }
                      style={{ padding: "5px 10px", cursor: "pointer" }}
                      onClick={() => quoteJob(job)}
                      size="sm"
                    >
                      <h6 style={{ margin: 0 }}>Quote</h6>
                    </Button>
                  )}
                </Card.Header>

                <Card.Body>
                  <Row>
                    <Col xs={12} md={8}>
                      <div>
                        <p>{job.description}</p>
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <ul>
                        <li>
                          Posted{" "}
                          {formatDistanceToNow(new Date(job.createdAt), {
                            addSuffix: true,
                          })}
                          {/* {format(new Date(job.createdAt), "do MMM, yy")} */}
                        </li>
                        <li>
                          {job.when.whenValue !== "date"
                            ? job.when.whenValue
                            : job.when.endDate
                            ? `${format(
                                new Date(job.when.startDate),
                                "do MMM, yy"
                              )} - ${format(
                                new Date(job.when.endDate),
                                "do MMM, yy"
                              )}`
                            : format(
                                new Date(job.when.startDate),
                                "do MMM, yy"
                              )}
                        </li>
                      </ul>
                    </Col>
                    {job.attachmentsUrl
                      ? job.attachmentsUrl.length > 0 && (
                          <Col xs={12}>
                            <p style={{ marginBottom: ".5rem" }}>Attachments</p>
                            <hr />
                            {job.attachmentsUrl.map(attachment => (
                              <img
                                src={attachment.url}
                                alt={`attachment ${attachment.name}`}
                                style={{
                                  width: "80px",
                                  height: "80px",

                                  borderRadius: "5px",
                                  objectFit: "contain",
                                }}
                              />
                            ))}
                          </Col>
                        )
                      : null}

                    <Col xs={12} style={{ marginTop: "10px" }}>
                      {auth.membership && auth.membership !== "pro" ? (
                        <OverlayTrigger
                          placement="top"
                          rootClose={true}
                          overlay={
                            <Tooltip id={`${i}-topplacement`}>
                              Buy Membership
                            </Tooltip>
                          }
                        >
                          <Link
                            to="/dashboard/business/buy-membership"
                            style={{ color: "#6c757d" }}
                          >
                            CONTACT CUSTOMER
                          </Link>
                        </OverlayTrigger>
                      ) : (
                        <OverlayTrigger
                          trigger="click"
                          key={i}
                          placement="right"
                          overlay={
                            <Popover id={`popover-positioned-${i}`}>
                              <Popover.Content>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <h6 style={{ margin: 0, padding: "5px" }}>
                                    <FiAtSign />
                                  </h6>
                                  <h6 style={{ margin: 0, padding: "5px" }}>
                                    {job.email}
                                  </h6>
                                </div>
                                {job.mobile && (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <h6 style={{ margin: 0, padding: "5px" }}>
                                      <AiOutlineMobile />
                                    </h6>

                                    <h6 style={{ margin: 0, padding: "5px" }}>
                                      +61 {job.mobile}
                                    </h6>
                                  </div>
                                )}
                              </Popover.Content>
                            </Popover>
                          }
                        >
                          <Badge
                            pill
                            variant="secondary"
                            style={{ cursor: "pointer" }}
                          >
                            <h6 style={{ margin: 0 }}>
                              CUSTOMER CONTACT DETAILS
                            </h6>
                          </Badge>
                        </OverlayTrigger>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            );
          })}
        </InfiniteCarousel>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            minHeight: "300px",
            border: "2px dashed #ccc",
            padding: "1.5rem",
            borderRadius: "10px",
          }}
        >
          <p
            style={{
              fontSize: "1.5rem",
              fontWeight: "light",
              textAlign: "center",
              color: "#9e9696",
            }}
          >
            There are currently no active leads available in your surrounding
            area. Will notify you as soon as job is posted related to the
            services you provide.
          </p>
        </div>
      )}
    </>
  );
};

export default RelatedJobs;
