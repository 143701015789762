import React, { useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Form,
  Button,
  Container,
  Alert,
  Spinner,
  InputGroup,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { setAuthorizationHeader } from "../redux/actions/userAction";
import { SET_AUTHENTICATED } from "../redux/types";
import axios from "axios";
import { FiEyeOff, FiEye } from "react-icons/fi";

const LoginWithPassword = ({ email }) => {
  const loginSchema = yup.object().shape({
    userpassword: yup
      .string()
      .required("Required")
      .min(6, "Minimum of 6 characters"),
  });

  const [error, setError] = useState();
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const userExistsSubmit = (values, actions) => {
    // console.log(actions);
    // e.stopPropagation();
    setError();
    actions.setSubmitting(true);
    console.log("user exists submit", { email, password: values.userpassword });
    axios
      .post("/login", { email, password: values.userpassword })
      .then(res => {
        setAuthorizationHeader(res.data.token);
        return axios.get("/user-details");
      })
      .then(userDetails => {
        dispatch({ type: SET_AUTHENTICATED, user: userDetails.data });
        actions.setSubmitting(false);
        userDetails.data.business
          ? history.push("/dashboard/business")
          : history.push("/add-business");
      })
      .catch(err => {
        console.log("sign in error", err.toJSON());
        actions.setSubmitting(false);
        setError("Wrong Credentials, Please try again");
      });
    // actions.setSubmitting(false);
  };

  return (
    <Container style={{ minHeight: "80vh" }}>
      <Alert variant="success">
        <p style={{ marginBottom: ".5rem" }}>
          We've found your existing account!
        </p>
        <p className="m-0">Please enter your password to login and proceed.</p>
      </Alert>
      {error && <Alert variant="danger">{error}</Alert>}
      <Formik
        initialValues={{
          userpassword: "",
        }}
        onSubmit={userExistsSubmit}
        validationSchema={loginSchema}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          touched,
          errors,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label htmlFor="userpassword">Password *</Form.Label>
              <InputGroup>
                <Form.Control
                  type={show ? "text" : "password"}
                  name="userpassword"
                  id="userpassword"
                  placeholder="Password"
                  onChange={handleChange}
                  isInvalid={touched.userpassword && errors.userpassword}
                />
                <InputGroup.Append onClick={() => setShow(!show)}>
                  <InputGroup.Text style={{ cursor: "pointer" }}>
                    {show ? <FiEyeOff /> : <FiEye />}
                  </InputGroup.Text>
                </InputGroup.Append>
                <Form.Control.Feedback type="invalid">
                  {errors.userpassword}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group className="d-flex flex-row justify-content-between align-items-end">
              <Button
                type="submit"
                className="w-50"
                disabled={isSubmitting || errors.password}
              >
                {isSubmitting ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Login"
                )}
              </Button>
              <Link to="/forgot-password">Forgot Password</Link>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default LoginWithPassword;
