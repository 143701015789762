import React from "react";
import "./how-we-do.css";
// import JobSvg from "../job-svg";
// import QuotesSvg from "../quotes-svg";
// import HireSvg from "../hire-svg";
// import Card from "../card/Card";
import { Container } from "react-bootstrap";
import Button from "../Button";
import lapo from "../../assets/img/lapo.webp";
import lapo1 from "../../assets/img/lapo2.webp";
import lapo2 from "../../assets/img/lapo3.webp";

// a2a1a1
// rgb(177 177 177)
const HowWeDo = () => {
  return (
    <Container fluid style={{ background: "#ffffff" }}>
      <div className="how-we-do container">
        <div className="howWeDo-wrapper">
          <div className="we-do-card">
            <div className="card-img">
              {/* <JobSvg /> */}
              <img
                src={lapo}
                alt="what need to be done"
                width="180px"
                height="145px"
              />
            </div>
            <div className="we-do-description">
              <h2>What need to be done</h2>
              <p>Select business, location and post job</p>
            </div>
          </div>
          <div className="we-do-card">
            <div className="card-img">
              {/* <QuotesSvg /> */}
              <img
                src={lapo1}
                alt="compare quotes"
                width="185px"
                height="145px"
              />
            </div>
            <div className="we-do-description ">
              <h2>Compare your quotes</h2>
              <p>You'll recieve quotes from local business.</p>
            </div>
          </div>
          <div className="we-do-card">
            <div className="card-img">
              {/* <HireSvg /> */}
              <img
                src={lapo2}
                alt="chose business"
                width="180px"
                height="145px"
              />
            </div>
            <div className="we-do-description">
              <h2>Hire the right business</h2>
              <p>Choose quotes that are most suitable to you</p>
            </div>
          </div>
          {/* <div className="btn">
            <Button type="button">Get started now</Button>
          </div> */}

          {/* <Card>
            <h1>01</h1>
            <h3>Tell us what need to be done</h3>
            <p>Select business, location and post job.</p>
          </Card>

          <Card>
            <h1>02</h1>
            <h3>Comapre your quotes</h3>
            <p>You'll recieve quotes from local business.</p>
          </Card>

          <Card>
            <h1>03</h1>
            <h3>Hire the right business</h3>
            <p>Choose quotes that are most suitable to you.</p>
          </Card> */}
        </div>
      </div>
    </Container>
  );
};

export default HowWeDo;
