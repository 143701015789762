import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import ab1 from "../assets/img/ab1.jpeg";
import ab2 from "../assets/img/ab2.jpeg";

import classes from "./About.module.css";

const About = () => {
  return (
    <>
      <div id="about-feature" className="feature">
        <Helmet>
          <title>GetFreeQuote - About us</title>
              <meta name="description"
                    content="Getfreequote is a leading company in Australia that provides an active marketplace for people looking for jobs. For more info, explore now!"
              />
        </Helmet>
        <div className="overlay grid-center" style={{ padding: "6rem 0 0 0" }}>
          <h1>ABOUT US</h1>
          <h6
            className={classes.mt}
            style={{ fontSize: "1.3rem", fontWeight: "400" }}
          >
            Getfreequote provides an active marketplace for people <br />
            looking for jobs as well as people needing jobs done.
          </h6>
        </div>
      </div>
      <Container className={classes.row1} fluid>
        <div className={classes.aboutSection1} style={{ display: "flex" }}>
          <div className={classes.abImg}>
            <img className={classes.img1} src={ab2} alt="background" />
          </div>
          <div className={classes.description1}>
            <div>
              <h4>BACKGROUND</h4>
              <p>
                The gap was recognised in the marketplace for a cost effective
                service that not only helped people shop around for the best
                quote on the work they needed, but also gave job-seekers the
                opportunity to quote on a wide variety of work suited to their
                skills. getfreequote has now filled that need and offers
                everyone a smarter, cheaper and better way to get the right
                quote for the job.
              </p>
            </div>
            <div>
              <h5>WHAT WE DO</h5>
              <p>
                We are a connection – matching tradesmen, professionals and all
                types of people to all types of jobs. If you need it done you’ll
                get it done at the right price on getfreequote
              </p>
            </div>
            <div>
              <h5>OUR SYSTEM</h5>
              <p>
                We have created an easy to use system that is simple for both
                job-seekers and job posters.
              </p>
            </div>
          </div>
        </div>
      </Container>
      <Container className={classes.row2} fluid>
        <div className={classes.aboutSection2} style={{ display: "flex" }}>
          <div className={classes.description2}>
            <div>
              <h5>OUR MEMBERS</h5>
              <p>
                Our quick to complete template allows members to register their
                details and create a profile without any fuss whatsoever.
              </p>
            </div>
            <div>
              <h5>EMPOWERMENT</h5>
              <p>
                By making jobs and the people to do those jobs accessible to
                everyone, we have empowered individuals to shop around for the
                best job at the best price, no longer do they have to rely on
                overpriced suppliers or service people. Also, getfreequote makes
                unemployment a thing of the past, with plenty of jobs advertised
                for all types of skills. Excellent Service: We strive for
                excellence in everything we do. Our service and support ensures
                your success when using getfreequote.
              </p>
            </div>
            <div>
              <h5>LEARN MORE</h5>
              <p>
                Become a Member. Get access to all the jobs you need to boost
                your income. Start your profile now.
              </p>
            </div>
          </div>
          <div className={classes.abImg}>
            <img className={classes.img2} src={ab1} alt="about" />
          </div>
        </div>
      </Container>
    </>
  );
};

export default About;
