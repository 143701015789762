import React, { useState } from "react";
import {
  Button,
  Form,
  InputGroup,
  Row,
  Col,
  Spinner,
  Collapse,
  Alert,
} from "react-bootstrap";
import { useSelector, useDispatch, connect } from "react-redux";
import * as yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import {
  setActiveStep,
  setShowPostJob,
  resetJob,
} from "../../../redux/actions/jobAction";
import { logOut } from "../../../redux/actions/userAction";
import { SET_JOBS } from "../../../redux/types";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";

const SubmitJob = ({
  jobUser,
  activeStep,
  setActiveStep,
  job,
  setShowPostJob,
  logOut,
}) => {
  const user = useSelector((state) => state.user.profile);
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [jobSubmit, setJobSubmit] = useState(false);
  const formikInitialValues = {
    notificaton: "",
    phone: "",
  };
  const validationSchema = yup.object().shape({
    notificaton: yup.string().required("Required"),
    phone: yup.string().when("notificaton", {
      is: (val) => val === "sms" || val === "both",
      then: yup
        .string()
        .min(9, "Invalid")
        .max(10, "Invalid")
        .matches(
          /^((\\+[0-9]{1,3}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          "Invalid"
        ),
    }),
  });

  const handleSubmit = async (values) => {
    console.log(values);
    setLoading(true);
    try {
      await axios.post("update-phone", {
        phone: values.phone,
      });
    } catch (e) {
      if (e.code === "auth/id-token-expired") {
        setJobSubmit("Session timed out. Please login again");
      } else {
        setJobSubmit(
          "There is problem in updating mobile no. Please try again."
        );
      }
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const submitJob = async () => {
    setLoading(true);
    setJobSubmit(false);
    let dataToSave = {
      job: { id: job.job.id, name: job.job.name },
      location: job.location,
      description: job.description,
      when: job.when,
      priority: job.priority,
    };

    const formData = new FormData();
    formData.append("jobDetails", JSON.stringify(dataToSave));

    if (job.attachments) {
      for (let attachment of job.attachments) {
        console.log("attachment", attachment);
        console.log("type of attachment", typeof attachment);
        formData.append(
          `job_attachment_${attachment.name}`,
          attachment,
          attachment.name
        );
      }
    }

    axios({
      method: "post",
      url: "/post-job",
      data: formData,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "content-type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    })
      .then((res) => {
        return axios.get("user-jobs");
      })
      .then((res) => {
        setLoading(false);
        // console.log("loggedin user posted job response", res);
        dispatch(resetJob());
        dispatch({ type: SET_JOBS, jobs: res.data });
        history.push("/dashboard/jobs/");
        dispatch(setShowPostJob(false));
      })
      .catch((err) => {
        setLoading(false);
        setJobSubmit(err.message);
        if (err.code === "auth/id-token-expired") {
          dispatch(logOut());
        }
        console.log("loggedin user posted job error", JSON.stringify(err));
      });
  };

  const getWhen = () => {
    if (job.when.whenValue !== "date") {
      console.log("job when", job.when.whenValue);
      return `${job.when.whenValue}`;
    } else if (job.when.endDate) {
      return `${format(new Date(job.when.startDate), "do, MMM")} - ${format(
        new Date(job.when.endDate),
        "do, MMM"
      )}`;
    } else {
      return `${format(new Date(job.when.startDate), "do, MMM")}`;
    }
  };

  return (
    <>
      {jobSubmit && (
        <Collapse in={jobSubmit}>
          <Alert variant="danger">{jobSubmit}</Alert>
        </Collapse>
      )}
      {!user.phone && (
        <>
          {/* <p className="text-muted">If you would like </p> */}
          <Formik
            initialValues={formikInitialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ handleSubmit, handleChange, errors, touched, values }) => (
              <Form onSubmit={handleSubmit}>
                {console.log("values", values)}

                <Form.Group>
                  <Form.Label>
                    Please provide us your phone no so that you could recieve
                    quotes sent by businesses via SMS
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>+61</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="text"
                      name="phone"
                      placeholder="Phone No"
                      onChange={handleChange}
                      isValid={touched.phone && !errors.phone}
                      isInvalid={touched.phone && errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  </InputGroup>
                  {!errors.phone && (
                    <Form.Text className="text-muted">
                      We'll never share your phone no with anyone else.
                    </Form.Text>
                  )}
                </Form.Group>
                {/* )} */}
                <Button type="submit">Update</Button>
              </Form>
            )}
          </Formik>
          <hr />
        </>
      )}
      <Row>
        <Col sm="12">
          <h5 style={{ marginBottom: "1rem" }}>
            {job.job.name} job in {job.location.name}
          </h5>
          <p>
            <span className="text-muted">💁 Description: </span>{" "}
            {job.description}
          </p>
          <p>
            <span className="text-muted">📆 Job to be done by </span>
            {getWhen()}
          </p>
          <p className="text-muted">
            <span>👉 </span>Get ready to recieve quotes from business via email
            as well as text messages.
          </p>
        </Col>
        <Col sm={12} md={2}>
          <Button type="button" onClick={handleBack}>
            Back
          </Button>
        </Col>
        <Col sm={12} md={{ span: 3, offset: 7 }}>
          <Button
            onClick={submitJob}
            type="button"
            className="w-100"
            disable={loading}
          >
            {" "}
            {loading ? <Spinner animation="border" size="sm" /> : "Submit Job"}
          </Button>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.profile,
  jobUser: state.job.user,
  activeStep: state.job.activeStep,
  job: state.job,
});

export default connect(mapStateToProps, {
  setActiveStep,
  setShowPostJob,
  resetJob,
  logOut,
})(SubmitJob);
