import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Alert,
  Form,
  Spinner,
  Container,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { FiCheck } from "react-icons/fi";
import { JOB_STATUS_UPDATE } from "../redux/types";

const Reviews = () => {
  const dispatch = useDispatch();
  const jobs = useSelector(state => state.user.profile.jobs);

  const jobStatusUpdate = useSelector(state => state.user.jobStatusUpdate);

  return (
    <Container>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      > */}
      <Row>
        {/* <div style={{ width: "50%" }}> */}
        <Col sm={12}>
          <div className="mt-4">
            {jobs ? (
              <>
                <p>For my job</p>
                <div className="d-flex flex-wrap">
                  {jobs.map(job => (
                    <div style={{ margin: "0 1rem" }}>
                      <Alert
                        key={job.id}
                        variant={
                          jobStatusUpdate && job.id === jobStatusUpdate.id
                            ? "success"
                            : "dark"
                        }
                        // style={{
                        //   padding: "5px 10px",
                        //   border: "1px solid #ccc",
                        //   margin: "10px 0",
                        //   borderRadius: "10px",
                        // }}
                        style={{
                          cursor: "pointer",
                          position: "relative",
                        }}
                        onClick={() => {
                          dispatch({ type: JOB_STATUS_UPDATE, job });
                        }}
                      >
                        <p className="m-0">
                          {job.jobType.name} | {job.location.name}
                        </p>
                      </Alert>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              "No job posted"
            )}
          </div>
          {/* </div> */}
        </Col>
      </Row>
    </Container>
  );
};

export default Reviews;
