import React, { useState } from "react";
// import {Form} from 'react-bootstrap'
import JobTypeAutomcomplete from "../Jobs/JobType/JobTypeAutocomplete";
import JobLocation from "../Jobs/JobLocation/JobLocation";
import { Formik, useField } from "formik";
import { Container, Form, Button } from "react-bootstrap";
import * as yup from "yup";
import JobValidationSchema from "../forms/FormModel/JobValidationSchema";
import InputFields from "../forms/FormFields/InputFields";
import FileInput from "../forms/FormFields/FileInput";
import RadioInput from "../forms/FormFields/RadioInput";
import PlaceAutocomplete from "../google-map/PlaceAutocomplete";
import FileUpload from "../Jobs/job-description/FileUpload";

const GetQuote = () => {
  const [activeStep, setActiveStep] = useState(0);
  const steps = ["About Job", "Job Details", "When"];
  const isLastStep = activeStep === steps.length - 1;
  const formikInitialValues = {
    job: "",
    location: "",
    description: "",
    attachment: [],
    when: {
      whenValue: "",
    },
    priority: "",
  };

  const _renderStepContent = (step, setFieldValue, setFieldError, errors) => {
    switch (step) {
      case 0:
        return <AboutJob setValue={setFieldValue} />;
      case 1:
        return <DetailsJob setValue={setFieldValue} />;
      case 2:
        return <WhenJob />;
      default:
        return <div>Not Found</div>;
    }
  };

  const currentValidationSchema = JobValidationSchema[activeStep];
  // console.log("currentValidationSchema", currentValidationSchema);

  const submitForm = (values, actions) => {
    console.log(values);
    console.log(actions);
  };

  const handleSubmit = (values, actions) => {
    console.log("handleSubmit");
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
    console.log(values);
  };

  const handleBack = () => {
    setActiveStep((activeStep) => activeStep - 1);
  };

  console.log("activeStep", activeStep);
  console.log("isLastStep", isLastStep);
  return (
    <Container className="mt-2">
      {/* <JobTypeAutomcomplete />
      <JobLocation /> */}
      <h4>{steps[activeStep]}</h4>
      <Formik
        initialValues={formikInitialValues}
        validationSchema={currentValidationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleSubmit,
          isSubmitting,
          errors,
          setFieldValue,
          values,
          setFieldError,
          touched,
        }) => (
          <Form onSubmit={handleSubmit}>
            {console.log("getquote errors", errors)}
            {console.log("issubmitting", isSubmitting)}
            {console.log("getquote values", values)}
            {_renderStepContent(
              activeStep,
              setFieldValue,
              setFieldError,
              errors,
              touched
            )}
            {!isLastStep && <Button onClick={handleBack}>Back</Button>}
            <Button
              // disabled={isSubmitting}
              type="submit"
              // variant="contained"
              className="btn btn-primary w-30"
            >
              {isLastStep ? "Submit" : "Next"}
            </Button>
            {isSubmitting && "Loading"}
          </Form>
        )}
      </Formik>
    </Container>
  );
};

const AboutJob = (props) => {
  const { setValue } = props;
  return (
    <>
      <JobTypeAutomcomplete
        type="text"
        name="job"
        placeholder="eg. Cleaner, Plumber, Electrician etc in lowercase"
        setValue={setValue}
        label="What service would you like"
      />
      <PlaceAutocomplete
        name="location"
        label="Suburb or Postcode"
        setValue={setValue}
      />
    </>
  );
};

const DetailsJob = () => {
  return (
    <>
      <InputFields
        type="textarea"
        name="description"
        placeholder="Short Description of your job"
        row="4"
        label="Briefly describe your job"
      />

      {/* <FileUpload
        setFieldError={setFieldError}
        setValue={setValue}
        errorMessage={errors.attachment}
        name="attachment"
        touched={touched}
      /> */}
      <FileInput name="attachement" />
    </>
  );
};

const WhenJob = () => {
  const data = [
    "Particular Date",
    "This Week",
    "Next Week",
    "As soon as possible",
  ];
  return (
    <>
      <RadioInput data={data} name="when" />

      {/* <Form.Check
        type="checkbox"
        name="when"
        id="when"
        label="Particular Date"
        value="Particular Date"
      >
        Particular Date
      </Form.Check>
      <Form.Check
        type="checkbox"
        name="when"
        id="when"
        label="This Week"
        value="This Week"
      >
        This Week
      </Form.Check>
      <Form.Check
        type="checkbox"
        name="when"
        id="when"
        label="Next Week"
        value="Next Week"
      >
        Next Week
      </Form.Check>
      <Form.Check
        type="checkbox"
        name="when"
        id="when"
        label="As soon as possible"
        value="As soon as possible"
      >
        As soon as possible
      </Form.Check> */}
    </>
  );
};

export default GetQuote;
