import React from "react";

const AutocompleteItem = ({
  name,
  onSelectItem,
  isHighlighted,
  suggestion,
}) => {
  return (
    <li
      className={`list-group-item ${isHighlighted && "active highlighted"}`}
      onClick={() => onSelectItem(suggestion)}
    >
      <div className="col text-left">
        <p className="mb-0 font-weight-bold line-height-1 text-capitalize">
          {name}
        </p>
      </div>
    </li>
  );
};

export default AutocompleteItem;
