import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
// import { getFirebase, firebaseReducer } from "react-redux-firebase";
// import { reduxFirestore, firestoreReducer } from "redux-firestore";
import jobReducer from "./reducers/jobReducer";
import userReducer from "./reducers/userReducer";
// import firebase, { firebaseConfig } from "../firebase/firebase-config";

const initialState = {};

// const middleware = [thunk];

const reducers = combineReducers({
  job: jobReducer,
  user: userReducer,
  // firebase: firebaseReducer,
  // firestore: firestoreReducer,
});

const store = createStore(
  reducers,
  initialState,
  compose(
    // applyMiddleware(thunk.withExtraArgument({ getFirebase })),
    applyMiddleware(thunk),
    // reduxFirestore(firebase, firebaseConfig),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : f => f
  )
);

export default store;
