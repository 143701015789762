import React from "react";
import { Container, Card, Accordion } from "react-bootstrap";
const TermsAndConditions = () => {
  return (
    <>
      <div id="privacy-feature" className="feature">
        <div className="overlay grid-center">
          <h1>Terms & Conditions</h1>
        </div>
      </div>

      <Container className="py-3">
        <div style={{ maxWidth: "900px", margin: "70px auto" }}>
          <Accordion defaultActiveKey="0">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                <h5>Terms & Conditions</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    The following outlines the Terms and Conditions under which
                    this website can be used. Please read them in full and
                    understand them before you begin to use the services of
                    getfreequote.com.au
                  </p>

                  <p>
                    The Terms and Conditions on this website as well as any
                    additional terms, conditions, notices and disclaimers along
                    with any documents available for download regulate your use
                    of this website and the services of getfreequote.com.au.
                  </p>

                  <p>
                    By accessing or using the Site and the services of
                    getfreequote.com.au you accept the Terms of Use. If you do
                    not agree to the Terms of Use, you must not access or use
                    the Site.
                  </p>

                  <p>
                    We may change our Terms of Use at any time by publishing
                    those changes on the Site. If you use or continue to use the
                    website and/or services provided by getfreequote.com.au you
                    acknowledge and agree that you accept to abide by and be
                    bound by the Terms and Conditions and any changes made by
                    getfreequote.com.au or any authorised officer, agent,
                    employee or contractor of getfreequote.com.au to the Terms
                    and Conditions from time to time.
                  </p>

                  <p>
                    If you object to any of the Terms and Conditions or any
                    changes to them, or become dissatisfied with your use of the
                    getfreequote.com.au in any way, your only option is to
                    immediately stop using the services of getfreequote.com.au.
                  </p>

                  <p>
                    A link to this Terms and Conditions Agreement is published
                    on every page of the website.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>

          <Accordion className="mt-2">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="1">
                <h5>Local Business Information</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p>
                    We publish data and information about Local Businesses on
                    the getfreequote.com.au Web Site. We make no representations
                    or warranties about the truth, accuracy, completeness,
                    security or currency of the content or information contained
                    on our Site (Our Content), including the competence,
                    character or identity of any Local Businesses listed on the
                    Site.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>

          <Accordion className="mt-2">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="2">
                <h5>Acess to our website</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p>
                    You are allowed to view, use, copy, and distribute the
                    Materials found on getfreequote.com.au for internal,
                    non-commercial and informational purposes only. However, you
                    are prohibited from data mining, scraping, crawling, or
                    using any process or processes that send automated queries
                    to getfreequote.com.au. You may not use getfreequote.com.au
                    or any of our related websites to compile a collection of
                    listings, including a competing listing product or service.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>

          <Accordion className="mt-2">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="3">
                <h5>Disputes</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p>
                    You acknowledge that the Site is a venue only and we are not
                    a party to, and do not participate in any transaction
                    between you and any Local Business or other User. If a
                    dispute arises in relation to a transaction, please seek
                    professional advice and do not contact getfreequote.com.au.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>

          <Accordion className="mt-2">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="4">
                <h5>Miscellaneos</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  <p>
                    Advertisers and sponsors of getfreequote.com.au have no
                    influence on content or presentation.
                  </p>

                  <p>
                    Our relationship with you under these Terms and Conditions
                    is one of independent contractors and not of partnership,
                    joint venture or principal and agent, however you may not
                    assign or transfer any of your rights or obligations under
                    these Terms and Conditions without our prior written
                    consent.
                  </p>

                  <p>
                    We will send any legal notices or communications regarding
                    your service account to your nominated email address. Please
                    ensure that a valid email address is recorded at all times.
                    Your primary point of contact with us should be via the
                    “Contact Us” facility on the getfreequote.com.au Web Site. A
                    notice sent to you by email will be deemed to have been
                    received by you 24 hours after it was sent, regardless of
                    whether or not you have actually read the notice.
                  </p>

                  <p>
                    If any clause or provision of these Terms and Conditions is
                    determined to be illegal, invalid or unenforceable or
                    capable of termination by a party in any jurisdiction in
                    which these Terms and Conditions are to operate, then such
                    clause or provision will be construed, to the extent
                    feasible, to render the clause or provision enforceable. If
                    no feasible interpretation would save such clause or
                    provision, it will be severed from the remainder of these
                    Terms and Conditions without affecting the enforceability of
                    all remaining clauses and provisions.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>

          <Accordion className="mt-2">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="5">
                <h5>Your Conduct</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="5">
                <Card.Body>
                  <p>
                    As a condition of your use of getfreequote.com.au, you will
                    not use the website for any purpose that is prohibited by
                    the Terms and Conditions or that is illegal or unlawful. You
                    agree to abide by all applicable laws and regulations.
                  </p>

                  <p>
                    Your Content may be seen and used by other Users and Local
                    Businesses however we are not responsible for misuse or
                    misappropriation of any of Your Content.
                  </p>

                  <p>
                    You must not submit content or use the Site in any manner or
                    for any purpose which is illegal or prohibited by any laws
                    that apply to you or violate our rights in any way under our
                    Terms of Use.
                  </p>

                  <p>
                    We do not endorse, support, sanction, encourage, verify or
                    agree with Your Content including without limitation the
                    comments, opinions, or statements posted or otherwise
                    contained on the Site.
                  </p>

                  <p>
                    We reserve the right, but have no obligation to, amend,
                    supplement, delete or update Our Content and Your Content,
                    without notice to you and at our sole discretion. However,
                    if we become aware of any defamatory, misleading, false or
                    offensive material in Your Content, we will remove such
                    material.
                  </p>

                  <p>
                    getfreequote.com.au has no obligation to monitor your or
                    anyone else’s use of the website. However,
                    getfreequote.com.au reserves the right at all times to
                    monitor, retain and disclose any information as necessary to
                    satisfy any applicable law, regulation, legal process or
                    governmental request.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>

          <Accordion className="mt-2">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="6">
                <h5>User Identity</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="6">
                <Card.Body>
                  <p>
                    As a user of the website, you agree to indemnify, defend and
                    hold harmless us and our officers, directors, employees,
                    agents, information providers, partners, advertisers,
                    licensors and suppliers from and against all losses,
                    expenses, damages and costs, including reasonable
                    solicitor's fees, resulting from any violation of these
                    Terms and Conditions or any activity related to your service
                    account (including infringement of third parties'
                    intellectual property rights anywhere in the world or
                    negligent or wrongful conduct) by you or any other person
                    accessing the getfreequote.com.au Web Site using your
                    service account.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>

          <Accordion className="mt-2">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="7">
                <h5>Limitation of Liability</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="7">
                <Card.Body>
                  <p>
                    getfreequote.com.au excludes all conditions and warranties
                    that may be implied by law. To the extent permitted by law,
                    the total liability of us, our officers, directors,
                    employees, agents, information providers, partners,
                    advertisers, licensors and suppliers, if any, for any loss
                    or damage arising in connection with the getfreequote.com.au
                    Web Site shall not exceed the greater of the amount of fees
                    paid by you for the particular information or service
                    provided.
                  </p>

                  <p>
                    As a user of the website, you expressly agree and
                    acknowledge that under no circumstances will
                    getfreequote.com.au be liable to you for any indirect,
                    incidental, special and/or consequential losses or damages
                    or loss of profits of any nature arising (including but not
                    limited to any act or omission by getfreequote.com.au) which
                    result from the use or access to or any inability to use or
                    access the website or any material on the website;
                    unauthorised access to your user account, or internet
                    transmissions or data; or statements or conduct of any third
                    party on the website.
                  </p>

                  <p>
                    Excluding liability for negligence the maximum liability of
                    getfreequote.com.au is equivalent to the total of any
                    amounts you have paid to getfreequote.com.au in respect of
                    goods or services supplied to you by getfreequote.com.au or
                    AUD$200, whichever is less.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>

          <Accordion className="mt-2">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="8">
                <h5>Disclaimer</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="8">
                <Card.Body>
                  <p>
                    getfreequote.com.au endeavours to provide valuable and
                    accurate information and believes our sources to be reliable
                    and the information to be correct at the time of posting.
                    However, in view of the possibility of any error by the
                    authors, editors, or publishers neither we nor any other
                    party involved in the preparation of material contained in
                    the getfreequote.com.au represents or warrants that the
                    information is in every respect accurate or complete, and
                    they assume no responsibility for any errors or omissions or
                    for the results obtained from the use of such material. You
                    are encouraged to confirm the information contained therein
                    with other sources.
                  </p>

                  <p>
                    We do not examine, determine or warrant the certification
                    and/or licensing, competence, solvency or information of any
                    professional or facility listed in our directories. We rely
                    on the professionals or the facilities listed in our
                    directories to provide accurate information and assume no
                    responsibility for verifying the information provided. Use
                    of our directories to locate a professional or facility is
                    wholly voluntary and, to the extent permitted by law, in no
                    event will we or any of our agents be liable for damages to
                    any user of our directories for the selection of a
                    professional or facility or for the services provided by any
                    professional or facility listed therein, or for any other
                    loss or damage which may occur as a result thereof. We
                    recommend that you check the certification and/or licensing
                    of any professional or other service provider with the
                    applicable licensing Board or authority.
                  </p>

                  <p>
                    You should satisfy yourself as the competence, solvency,
                    insurances and licensing of anybody you decide to retain as
                    a result of a connection made through our services. Under no
                    circumstances shall we be liable for any damages whatsoever
                    as a result of losses caused to you by a person or entity
                    that you retain as a consequence of using our directory or
                    services.
                  </p>

                  <p>
                    The getfreequote.com.au Web Site may contain links to web
                    sites operated by third parties. The linked sites are not
                    under our control and we are not responsible for the
                    contents of any linked site. The appearance of any product,
                    service or web site link on the getfreequote.com.au does not
                    imply endorsement, approval or warranty by us and we
                    disclaim all liability with regard to any such products,
                    services or web site links.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>

          <Accordion className="mt-2">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="9">
                <h5>Warranty</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="9">
                <Card.Body>
                  <p>
                    You agree to accept the sole responsibility for the legality
                    of your actions using the laws which apply to you. You agree
                    that getfreequote.com.au has no responsibility for the
                    legality of the actions of users of the website or
                    getfreequote.com.au services.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>

          <Accordion className="mt-2">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="10">
                <h5>Indemnity</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="10">
                <Card.Body>
                  <p>
                    You indemnify and release getfreequote.com.au and its
                    directors, employees, agents, contractors, affiliates from
                    any claims, demands, proceedings, losses and damages of
                    every kind and nature including reasonable solicitor's fees,
                    made by you or any third party due to or arising (whether
                    directly or indirectly) out of your breach of these Terms
                    and Conditions or your violation of and law or the rights of
                    a third party.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>

          <Accordion className="mt-2">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="11">
                <h5>No Agency</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="11">
                <Card.Body>
                  <p>
                    You and getfreequote.com.au are independent contractors and
                    no agency, partnership, joint venture, employee-employer or
                    franchisee-franchisor relationship is created or intended by
                    this Terms and Conditions agreement or your use of the
                    website or getfreequote.com.au services.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>

          <Accordion className="mt-2">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="12">
                <h5>Submission of Content/User Information</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="12">
                <Card.Body>
                  <p>
                    You acknowledge that all content (including text, images and
                    any other data) submitted to or through us will not be
                    considered confidential or proprietary and we are under no
                    obligation to keep such information confidential.
                  </p>

                  <p>You must not submit information that: </p>

                  <p>
                    a. you do not have the right to disclose or make available
                    under any law or under contractual or fiduciary
                    relationships (such as insider information, or proprietary
                    and confidential information learned or disclosed as part of
                    employment relationships or under non-disclosure
                    agreements); or would infringe the intellectual property
                    rights of third parties.
                  </p>

                  <p>
                    a: By submitting Your Content through the Site, you grant us
                    a royalty-free, perpetual, irrevocable, non-exclusive,
                    unrestricted, worldwide licence to use, reproduce, modify,
                    adapt, translate, enhance, transmit, distribute, publicly
                    perform, display, or sublicense any such communication in
                    any medium and for any purpose, including commercial
                    purposes, and to authorise others to do so. As a user of the
                    website you must ensure that the information you provide is
                    complete, up to date and accurate and it is your
                    responsibility to inform getfreequote.com.au of any changes
                    to that information. All personal information you provide to
                    getfreequote.com.au as a user will be treated in accordance
                    with getfreequote.com.au Privacy Policy. getfreequote.com.au
                    reserves the right to modify or delete any general
                    information you submit to the website in order to enhance
                    the services we provide to you and other users.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>

          <Accordion className="mt-2">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="13">
                <h5>Username / Password</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="13">
                <Card.Body>
                  <p>
                    To become a member of getfreequote.com.au you will nominate
                    a username and password. You are entirely responsible for
                    all activities that occur under your username and password.
                    You must ensure these remain confidential at all times. You
                    must notify getfreequote.com.au immediately by email if you
                    become aware of any unauthorised use of your username and
                    password. Each username and password must be used by a
                    single individual and is not transferrable.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>

          <Accordion className="mt-2">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="14">
                <h5>Termination</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="14">
                <Card.Body>
                  <p>
                    We may terminate your access to this Site at any time
                    without reason and without notice. The Terms of Use survive
                    any such termination.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </Container>
    </>
  );
};

export default TermsAndConditions;
