import React, { useEffect, useState } from "react";
// Redux Suffi
// import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import WithSidebar from "../components/layout/WithSidebar";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  Alert,
  Button,
  Spinner,
  Row,
  Col,
  Modal,
  Form,
  FormCheck,
} from "react-bootstrap";
import { PieChart } from "react-minimal-pie-chart";
import RelatedJobs from "../components/related-jobs/RelatedJobs";
import { FiUserCheck, FiCheckCircle } from "react-icons/fi";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import {
  UPDATE_BUSINESS_DESCRIPTION,
  UPDATE_BUSINESS_LOGO,
  UPDATE_BUSINESS_GALLERY,
} from "../redux/types";
import Autosuggest from "react-autosuggest";
// import Profile from "./Profile";
// const FILE_SIZE = 400 * 400;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const imageUpload = (e, setValue, setError, name, fileSize) => {
  e.preventDefault();
  let filesToUpload = [];
  let files = e.target.files;
  // console.log("image upload event", e);
  console.log("files to upload", files);

  if (files) {
    Array.from(files).forEach((file) => {
      console.log("file", file);
      console.log("type of file", typeof file);
      if (SUPPORTED_FORMATS.includes(file.type) && file.size <= fileSize) {
        filesToUpload.push(file);
        // setValue(name, [...values[name], file]);
        // dispatch(setAttachment(file));
        // setFieldError(name, null);
      } else {
        if (!SUPPORTED_FORMATS.includes(file.type)) {
          setError(name, "Unsupported Format");
        }
        if (file.size > fileSize) {
          setError(name, "File too large");
        }
      }
    });
    setValue(name, filesToUpload);
  } else {
    setError(name, "File is required");
  }
};

const BusinessDescription = ({ setIsOpen }) => {
  const dispatch = useDispatch();
  const handleSubmit = async (values, actions) => {
    console.log("description values", values);
    try {
      const updatedDescription = await axios.post(
        "/update-description",
        values
      );
      console.log("update description response", updatedDescription);
      dispatch({
        type: UPDATE_BUSINESS_DESCRIPTION,
        description: updatedDescription.data.description,
      });
      setIsOpen();
    } catch (e) {
      console.error("description update error", e);
    }
  };
  const description = useSelector(
    (state) => state.user.profile.business.description
  );

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ description }}
      validationSchema={yup.object().shape({
        description: yup.string().required("Required"),
      })}
    >
      {({
        handleSubmit,
        handleChange,
        touched,
        errors,
        isSubmitting,
        values,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Control
              as="textarea"
              required
              rows={5}
              placeholder="A description for your business"
              name="description"
              isInvalid={touched.description && errors.description}
              onChange={handleChange}
              value={values.description}
            />
            <Form.Control.Feedback type="invalid">
              {errors.description}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Button type="submit">
              {isSubmitting ? <Spinner animation="border" size="sm" /> : "Save"}
            </Button>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
};

const UploadLogo = ({ setIsOpen }) => {
  const dispatch = useDispatch();
  // const validationSchema = yup.object().shape({
  //   logo: yup
  //     .array()
  //     .min(1, "A file is required")
  //     .required("A file is required"),
  // });

  const handleSubmit = async (values, actions) => {
    console.log("logo submit values", values);
    const formData = new FormData();
    // formData.append("logo", values.logo[0], values.logo[0].name);
    for (let attachment of values.logo) {
      console.log("attachment", attachment);
      console.log("type of attachment", typeof attachment);
      formData.append(`logo_${attachment.name}`, attachment, attachment.name);
    }
    // console.log(formData);
    try {
      const updatedLogo = await axios({
        method: "post",
        url: "/upload-logo",
        data: formData,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          "content-type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      });
      console.log("logo is updated", updatedLogo);
      dispatch({ type: UPDATE_BUSINESS_LOGO, logo: updatedLogo.data.logo });
      setIsOpen();
    } catch (e) {
      console.log("logo update error", e);
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ logo: [] }}
      // validationSchema={validationSchema}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        touched,
        errors,
        setFieldValue,
        values,
        setFieldError,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          {console.log("errors", errors)}
          {console.log("upload logo values", values)}
          <Form.Text className="text-muted mb-1">Max file size 1.5Mb</Form.Text>
          <Form.Group>
            <Form.Control
              type="file"
              // custom
              // onChange={handleChange}
              name="logo"
              onChange={(e) =>
                imageUpload(e, setFieldValue, setFieldError, "logo", 1500000)
              }
              isInvalid={errors.logo}
            />
            <Form.Control.Feedback type="invalid">
              {errors.logo}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Button type="submit" disabled={errors.logo || isSubmitting}>
              {isSubmitting ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Upload logo"
              )}
            </Button>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
};

const UploadGallery = ({ setIsOpen }) => {
  // const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
  const dispatch = useDispatch();
  // const validationSchema = yup.object().shape({
  //   gallery: yup
  //     .array()
  //     .min(1, "A file is required")
  //     .required("A file is required"),
  // });

  const handleSubmit = async (values, actions) => {
    console.log(values);
    const formData = new FormData();
    for (let attachment of values.gallery) {
      console.log("attachment", attachment);
      console.log("type of attachment", typeof attachment);
      formData.append(
        `gallery_${attachment.name}`,
        attachment,
        attachment.name
      );
    }

    try {
      const updatedLogo = await axios({
        method: "post",
        url: "/upload-gallery",
        data: formData,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          "content-type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      });
      console.log("gallery is updated", updatedLogo.data.gallery);
      dispatch({
        type: UPDATE_BUSINESS_GALLERY,
        gallery: updatedLogo.data.gallery,
      });
      setIsOpen();
    } catch (e) {
      console.log("gallery update error", e);
    }
  };

  // const imageUpload = e => {
  //   e.preventDefault();
  //   console.log(e.target.files);
  // };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ gallery: [] }}
      // validationSchema={validationSchema}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        touched,
        errors,
        setFieldValue,
        setFieldError,
        values,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          {console.log("errors", errors)}
          {console.log("gallery values", values)}
          <Form.Group>
            <Form.Text className="text-muted mb-1">Max File Size 3Mb</Form.Text>
            <Form.Control
              type="file"
              // custom
              onChange={(e) =>
                imageUpload(e, setFieldValue, setFieldError, "gallery", 3000000)
              }
              // onChange={handleChange}
              name="gallery"
              isInvalid={errors.gallery}
              multiple
            />
            <Form.Control.Feedback type="invalid">
              {errors.gallery}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Button type="submit" disabled={errors.gallery || isSubmitting}>
              {isSubmitting ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Upload Gallery Images"
              )}
            </Button>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
};

const BackgroundCheck = ({ setIsOpen }) => {
  const handleSubmit = async (values) => {
    const valuesToSubmit = delete values.terms;
    await axios.post("background-info", valuesToSubmit);
    setIsOpen();
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        firstName: "",
        middleName: "",
        lastName: "",
        address: "",
        terms: "",
      }}
      validationSchema={yup.object().shape({
        firstName: yup.string().required("Required"),
        middleName: yup.string(),
        lastName: yup.string().required("Required"),
        address: yup.string().required("Required"),
        terms: yup.bool().required().oneOf([true], "Terms must be accepted"),
      })}
    >
      {({
        handleSubmit,
        handleChange,
        touched,
        errors,
        isSubmitting,
        values,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          {console.log(errors)}
          <p
            className="text-alert"
            style={{ borderLeft: "3px solid red", paddingLeft: "10px" }}
          >
            We'll verify you by matching information from your either your
            driver's licence or passport. This information is kept private and
            confidential.
          </p>
          <Form.Group>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              isInvalid={touched.firstName && errors.firstName}
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">
              {errors.firstName}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Middle Name (optional)</Form.Label>
            <Form.Control
              type="text"
              name="middleName"
              isInvalid={touched.middleName && errors.middleName}
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">
              {errors.middleName}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              isInvalid={touched.lastName && errors.lastName}
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">
              {errors.lastName}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              name="address"
              isInvalid={touched.address && errors.address}
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">
              {errors.address}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Text>
              <p>
                Please provide your Australian Driver’s licence, Identity Card
                or Passport details so we can confirm them with the Australian
                Government. Your Australian Driver’s licence, Identity Card or
                Passport will be submitted to the Australian Government’s
                Document Verification Service (DVS). The DVS is a national
                online system that allows organisations to compare an
                individual’s identifying information with a government record.{" "}
                <a href="#" target="_blank">
                  More information about the DVS is available on their website
                </a>
              </p>
              <p style={{ marginBottom: "3px" }}>
                By submitting your details on this page you agree that:
              </p>
              <ul style={{ listStylePosition: "inside" }}>
                <li>
                  you are authorised to provide the Australian Driver’s licence,
                  Identity Card or Passport details; and
                </li>
                <li>
                  you understand that the Australian Driver’s licence, Identity
                  Card or Passport details will be checked against records held
                  by the Issuer or Official Record Holder
                </li>
              </ul>
            </Form.Text>
          </Form.Group>
          <Form.Group>
            {/* <Form.Control> */}
            <Form.Check>
              <FormCheck.Input
                type="checkbox"
                name="terms"
                value={values.terms}
                onChange={handleChange}
                isInvalid={touched.terms && errors.terms}
              />
              <FormCheck.Label>
                By clicking "Verify these details", I acknowledge and agree to
                the{" "}
                <a href="#" target="_blank">
                  Terms and Conditions
                </a>{" "}
                and I agree that my above information is checked with the Issuer
                or Official Record Holder
              </FormCheck.Label>
              {/* <FormCheck.Feedback type="invalid">
                {errors.terms}
              </FormCheck.Feedback> */}
            </Form.Check>
            {/* </Form.Control> */}
            {errors.terms && (
              <Form.Text style={{ color: "red" }}>{errors.terms}</Form.Text>
            )}
          </Form.Group>

          <Form.Group>
            <Button type="submit">
              {isSubmitting ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Verify these datails"
              )}
            </Button>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
};

const DashboardModal = ({ isOpen, setIsOpen, title, children }) => {
  return (
    <Modal show={isOpen} onHide={() => setIsOpen(false)}>
      <Modal.Header style={{ fontWeight: "bold" }} closeButton>
        {title}
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

function Dashboard({ user, leads }) {
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const business = user.business;
  const [profileCompletion, setProfileCompletion] = useState(0);

  useEffect(() => {
    if (business.description && profileCompletion < 25) {
      setProfileCompletion((profileCompletion) => profileCompletion + 25);
    }
    if (business.logo && profileCompletion < 50) {
      setProfileCompletion((profileCompletion) => profileCompletion + 25);
    }
    if (business.gallery && profileCompletion < 75) {
      setProfileCompletion((profileCompletion) => profileCompletion + 25);
    }
    if (business.verified) {
      setProfileCompletion((profileCompletion) => profileCompletion + 25);
    }
  }, [business]);

  const title = {
    description: "Write description for your business",
    logo: "Upload a logo to your business",
    gallery: "Upload an image the business gallery",
    background: "Background check will give your business verified badge",
  };

  return (
    <>
      {loading ? (
        <Col xs={12}>
          <Spinner animation="border" size="sm" />
        </Col>
      ) : (
        <>
          <DashboardModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title={title[modalContent]}
          >
            {modalContent === "description" && (
              <BusinessDescription setIsOpen={() => setIsOpen(false)} />
            )}
            {modalContent === "logo" && (
              <UploadLogo setIsOpen={() => setIsOpen(false)} />
            )}
            {modalContent === "gallery" && (
              <UploadGallery setIsOpen={() => setIsOpen(false)} />
            )}
            {modalContent === "background" && (
              <BackgroundCheck setIsOpen={() => setIsOpen(false)} />
            )}
          </DashboardModal>
          {leads && (
            <Col xs={12}>
              <RelatedJobs jobs={leads.slice(0, 10)} />
            </Col>
          )}
          <Col xs={12} className="mt-3">
            <div
              style={{
                marginTop: "1rem",
                boxShadow: "0 2px 10px rgb(0 0 0 / 20%)",
                // minHeight: "300px",
                // _height: "300px",
                height: "auto",
                borderRadius: "5px",
                padding: "25px",
              }}
            >
              {((user.business && user.membership !== "pro") ||
                (user.status && user.status === "pending")) && (
                <Alert
                  variant="danger"
                  className="d-flex justify-content-between align-items-center mb-4"
                >
                  {user.status && user.status === "pending"
                    ? "There is a problem with your payment."
                    : "Become a member to start quoting on leads."}
                  {!user.status && (
                    <Link to="/dashboard/business/buy-membership">
                      <Button>Start 12 months Free Trial</Button>
                    </Link>
                  )}
                  {user.status && user.status === "pending" && (
                    <Button>Resolve Payment</Button>
                  )}
                </Alert>
              )}
              {/* <Container> */}
              <Row>
                <Col sm={12} style={{ marginBottom: "1rem", height: "auto" }}>
                  <h5 style={{ textAlign: "center" }}>PROFILE COMPLETION</h5>
                  <hr />
                </Col>

                <Col
                  md={4}
                  sm={12}
                  className="profile-stat-chart"
                  style={{ height: "auto" }}
                >
                  <div
                    style={{
                      display: "grid",
                      justifyItems: "center",
                      gridTemplateColumns: "1fr 1fr",
                      alignItems: "center",
                    }}
                  >
                    <h5 style={{ fontSize: "3rem", color: "#00e29f" }}>
                      {profileCompletion}%
                    </h5>
                    <div
                      style={{
                        position: "relative",
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      <PieChart
                        className="pie-chart"
                        data={[
                          {
                            title: "done",
                            value: parseInt(profileCompletion),
                            color: "#00e29f",
                          },
                          {
                            title: "pending",
                            value: parseInt(100 - profileCompletion),
                            color: "#009569",
                          },
                        ]}
                        lineWidth={15}
                        startAngle={270}
                      />
                      <FiUserCheck className="profile-user-avatar" />
                    </div>
                  </div>
                </Col>
                <Col md={8} sm={12} className="profile-stat">
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gridTemplateRows: "1fr 1fr",
                      gridGap: "1.5rem",
                    }}
                  >
                    <div
                      style={
                        business.description
                          ? {
                              border: "2px solid #00e29f",
                              borderRadius: "10px",
                              padding: "10px",
                              position: "relative",
                            }
                          : {
                              border: "2px solid #ccc",
                              postion: "relative",
                              padding: "10px",
                              borderRadius: "10px",
                            }
                      }
                    >
                      <p style={{ marginBottom: ".5rem" }}>About Us</p>
                      <div
                        onClick={() => {
                          setIsOpen(true);
                          setModalContent("description");
                        }}
                        className="text-primary d-inline-block"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        Write about us
                      </div>
                      {/* <Link to="/dashboard/business/profile">
                        Write About Us
                      </Link> */}
                      {business.description ? (
                        <FiCheckCircle
                          style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            color: "#00e29f",
                          }}
                        />
                      ) : (
                        <FiCheckCircle
                          style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            color: "#ccc",
                          }}
                        />
                      )}
                    </div>
                    <div
                      style={
                        business.logo
                          ? {
                              border: "2px solid #00e29f",
                              borderRadius: "10px",
                              padding: "10px",
                              position: "relative",
                            }
                          : {
                              border: "2px solid #ccc",
                              postion: "relative",
                              borderRadius: "10px",
                              padding: "10px",
                              position: "relative",
                            }
                      }
                    >
                      <p style={{ marginBottom: ".5rem" }}>Business Logo</p>
                      <div
                        className="text-primary d-inline-block"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setIsOpen(true);
                          setModalContent("logo");
                        }}
                      >
                        Upload your business logo
                      </div>
                      {/* <Link to="/dashboard/business/profile/logo">
                        Upload your business logo
                      </Link> */}
                      {business.logo ? (
                        <FiCheckCircle
                          style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            color: "#00e29f",
                          }}
                        />
                      ) : (
                        <FiCheckCircle
                          style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            color: "#ccc",
                          }}
                        />
                      )}
                    </div>
                    <div
                      style={
                        business.gallery
                          ? {
                              border: "2px solid #00e29f",
                              borderRadius: "10px",
                              padding: "10px",
                              position: "relative",
                            }
                          : {
                              border: "2px solid #ccc",
                              postion: "relative",
                              borderRadius: "10px",
                              padding: "10px",
                              position: "relative",
                            }
                      }
                    >
                      <p style={{ marginBottom: ".5rem" }}>Gallery</p>
                      <div
                        className="text-primary d-inline-block"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setIsOpen(true);
                          setModalContent("gallery");
                        }}
                      >
                        Upload images to your gallery
                      </div>
                      {/* <Link to="/dashboard/business/profile/gallery">
                        Upload images to your gallery{" "}
                      </Link> */}
                      {business.gallery ? (
                        <FiCheckCircle
                          style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            color: "#00e29f",
                          }}
                        />
                      ) : (
                        <FiCheckCircle
                          style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            color: "#ccc",
                          }}
                        />
                      )}
                    </div>
                    <div
                      style={
                        business.background_checked
                          ? {
                              border: "2px solid #00e29f",
                              borderRadius: "10px",
                              padding: "10px",
                              position: "relative",
                            }
                          : {
                              border: "2px solid #ccc",
                              postion: "relative",
                              borderRadius: "10px",
                              padding: "10px",
                              position: "relative",
                            }
                      }
                    >
                      <p style={{ marginBottom: ".5rem" }}>Background Check</p>
                      <div
                        className="text-primary d-inline-block"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setIsOpen(true);
                          setModalContent("background");
                        }}
                      >
                        Complete your background check
                      </div>
                      {/* <Link to="/dashboard/business/profile/background">
                        Background Check
                      </Link> */}
                      {business.gallery ? (
                        <FiCheckCircle
                          style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            color: "#00e29f",
                          }}
                        />
                      ) : (
                        <FiCheckCircle
                          style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            color: "#ccc",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              {/* </Container> */}
            </div>
          </Col>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.profile,
  leads: state.user.profile && state.user.profile.leads,
});

export default connect(mapStateToProps, null)(Dashboard);
// export default Dashboard;
