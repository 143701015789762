import React, { useState, useEffect } from "react";
import "./App.css";
// Router
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import axios from "axios";
import jwtDecode from "jwt-decode";
// Redux
// import { connect } from "react-redux";
import { useSelector } from "react-redux";
import store from "./redux/store";
import { ToastProvider } from "react-toast-notifications";

// import { ReactReduxFirebaseProvider } from "react-redux-firebase";
// import { createFirestoreInstance } from "redux-firestore";
// import firebase from "./firebase/firebase-config";

// import { setUser } from './redux/actions/userAction';
// import { auth } from './firebase/firebase-config';
// import { SET_AUTHENTICATED } from "./redux/types";
import { logOut, getUserData } from "./redux/actions/userAction";
// Components
import Home from "./pages/Home";
import Header from "./components/header/Header";
import About from "./pages/About";
// import Dashboard from "./pages/Dashboard";
import BusinessRegistration from "./pages/BusinessRegistration";
// import EditBusiness from "./components/business/EditBusiness";
import ContactUs from "./pages/ContactUs";
import PostJob from "./components/post-job/PostJob";
// import Modal from "./components/modal/Modal";
import SignIn from "./components/sign-in/SignIn";
// import SignUpPage from "./pages/SignUpPage";
import SignUp from "./components/signup/SignUp";
import Footer from "./components/footer/Footer";
// import LoginWithPassword from "./components/LoginWithPassword";
import PrivateRoute from "./components/PrivateRoute";
import Membership from "./pages/Membership";
import LoggedInHome from "./pages/LoggedInHome";
import GetQuote from "./components/getquoteform/GetQuote";
import Subscriptions from "./components/subscriptions/subscriptions";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import MobileNav from "./components/mobile-nav/MobileNav";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/Terms&Conditions";
import ForgotPassword from "./components/forgot-password/ForgotPassword";
import AddBusiness from "./components/business/AddBusiness";
import OtherIndividualJobs from "./components/individual-jobs/OtherIndividualJobs";
import Announcement from "./components/Announcement";
import JobTypeCategories from "./pages/JobTypeCategories";
import ScrollToTop from "./components/ScrollToTop";
import { Helmet } from "react-helmet";
import NotFoundPage from "./pages/NotFoundPage";

axios.defaults.baseURL =
  "https://australia-southeast1-getfreequote-264c7.cloudfunctions.net/api";

// axios.defaults.baseURL = "https://getfreequote.com.au/api";

const token = localStorage.FBIdToken;
if (token) {
  console.log("token", token);
  const decodedToken = jwtDecode(token);
  console.log("decodedToken", new Date(decodedToken.exp * 1000));
  if (decodedToken.exp * 1000 < Date.now()) {
    window.localStorage.removeItem("FBIdToken");
    store.dispatch(logOut);
    window.location.href = "/user/signin";
    // history.push("/");
  } else {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    store.dispatch(getUserData());
    // store.dispatch({ type: SET_AUTHENTICATED });
    // window.location.href = "/dashboard/business";
    // history.push("/dashboard/business");
  }
}

function App() {
  const [Open, setOpen] = useState(false);
  // const history = useHistory();
  Open ? disableBodyScroll(document) : enableBodyScroll(document);
  const user = useSelector((state) => state.user.profile);
  // const dispatch = useDispatch();
  // useEffect(() => {

  // }, []);

  return (
    <>
      {/* // <Provider store={store}> */}
      {/* <ReactReduxFirebaseProvider {...rrfProps}> */}
      <ToastProvider transitionDuration={1200} autoDismiss={true}>
        <Router>
          <div className="App">
            <ScrollToTop />
            {!user}
            <Header openMenu={() => setOpen(true)} />
            <MobileNav Open={Open} close={() => setOpen(false)} />
            <Helmet>
              <title>
                GetFreeQuote - We can help you achieve all of these and more!
              </title>
              <meta
                name="description"
                content="Getfreequote is a leading company in Australia that provides an active marketplace for people looking for jobs. For more info, explore now!"
              />
              <meta name="keywords" content="Quote, trades, jobs, business" />
            </Helmet>
            <PostJob />
            <Switch>
              <Route path="/" exact>
                <Home />
              </Route>
              <Route path="/about-us" exact>
                <About />
              </Route>
              <Route path="/contact-us" exact>
                <ContactUs />
              </Route>
              <Route path="/post-jobs" exact>
                <PostJob />
              </Route>
              <Route path="/user/signin" exact>
                <SignIn />
              </Route>
              <Route path="/user/signup" exact>
                <SignUp />
              </Route>
              <Route exact path="/post-jobs">
                <PostJob />
              </Route>
              <Route exact path="/register-business">
                <BusinessRegistration />
              </Route>
              <Route exact path="/add-business">
                <AddBusiness />
              </Route>
              {/* <Route exact path="/register-business">
              <EditBusiness />
            </Route> */}
              <Route exact path="/buy-member">
                <Membership />
              </Route>
              <PrivateRoute path="/dashboard" component={LoggedInHome} />
              <PrivateRoute path="/subscriptions" component={Subscriptions} />
              <Route path="/get-quote" exact>
                <GetQuote />
              </Route>
              <Route path="/privacy-policy" exact>
                <PrivacyPolicy />
              </Route>
              <Route path="/terms-conditions" exact>
                <TermsAndConditions />
              </Route>
              <Route path="/forgot-password" exact>
                <ForgotPassword />
              </Route>
              <Route path="/job/:id" exact>
                {/* <IndividualJobs /> */}
                <OtherIndividualJobs />
              </Route>
              <Route path="/jobType/:name">
                <JobTypeCategories />
              </Route>
              <Route component={NotFoundPage} />
              {/* <Route path="/test">
              <LoginWithPassword email="ldodl@kldo.com" />
            </Route>
            <PrivateRoute path="/edit-business" component={EditBusiness} /> */}
            </Switch>
            <Footer />
          </div>
        </Router>
        {/* </ReactReduxFirebaseProvider> */}
      </ToastProvider>
      {/* </Provider> */}
    </>
  );
}

// const mapStateToProps = state => ({
//   user: state.user.user,
// });

// export default connect(mapStateToProps)(App);
export default App;
