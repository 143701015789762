import React, { useState } from "react";
import { Modal, Form, Button, Spinner, Alert } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { QUOTE_JOB, ADD_TEMPLATE } from "../../redux/types";
import axios from "axios";
import { Formik } from "formik";
import * as yup from "yup";
import { useToasts } from "react-toast-notifications";

const QuoteJob = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const quoteJob = useSelector((state) => state.job.quoteJob);
  const business = useSelector((state) => state.user.profile.business);
  // const [createTemplate, setCreateTemplate] = useState(false);
  const [errors, setErrors] = useState(null);
  // const [templates, setTemplates] = useState(null);
  const [loading, setLoading] = useState(false);

  const [activeTemplate, setActiveTemplate] = useState("");

  const closeModal = () => {
    dispatch({ type: QUOTE_JOB, quoteJob: { status: false, job: null } });
  };

  const validationSchema = yup.object().shape({
    template_name: yup.string().required("Required"),
    message: yup.string().required("Required"),
  });

  const sendQuote = async (e) => {
    e.preventDefault();
    setErrors("");
    setLoading(true);
    const jobMobile = quoteJob.job.mobile && quoteJob.job.mobile;
    console.log("quote req body", {
      businessName: business.businessName,
      businessPhone: business.mobile,
      businessEmail: business.email,
      jobId: quoteJob.job.id,
      jobUserName: quoteJob.job.name,
      jobAuthor: quoteJob.job.author,
      jobLocation: quoteJob.job.location,
      jobType: quoteJob.job.jobType,
      jobEmail: quoteJob.job.email,
      message: activeTemplate[0].message,
      jobMobile,
    });
    // console.log("jobPhone", jobPhone);
    // console.log("job email", quoteJob.job.email);
    try {
      await axios.post("/send-quote", {
        businessName: business.businessName,
        businessPhone: business.mobile,
        businessEmail: business.email,
        jobId: quoteJob.job.id,
        jobUserName: quoteJob.job.name,
        jobAuthor: quoteJob.job.author,
        jobLocation: quoteJob.job.location,
        jobType: quoteJob.job.jobType,
        jobEmail: quoteJob.job.email,
        message: activeTemplate[0].message,
        jobMobile,
      });
      closeModal();
      addToast("Quote sent successfully. 👏 👏", { appearance: "info" });
    } catch (e) {
      if (e.code === "auth/id-token-expired") {
        setErrors("Session timed out. Please login again");
      } else {
        setErrors("Unable to submit quote. Please try again");
      }
      console.log("submit quote error", e);
    } finally {
      setLoading(false);
    }
  };

  const createTemplateSubmit = async (values) => {
    console.log("create template submit", values);
    try {
      const addedTemplate = await axios.post("/add-template", {
        name: values.template_name,
        message: values.message,
      });
      console.log("addedTemplate res data", addedTemplate.data);
      dispatch({
        type: ADD_TEMPLATE,
        template: addedTemplate.data,
      });
      setActiveTemplate(addedTemplate.data);
      console.log("add template response", addedTemplate);
    } catch (e) {
      setErrors("Unable to create Template. Please try again");
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    // setActiveTemplate(null);
    if (e.target.value === "") {
      setActiveTemplate("");
      return;
    }
    if (e.target.value === "create template") {
      setActiveTemplate({ name: "create template" });
      return;
    }
    let template = business.templates.filter(
      (template) => template.id === e.target.value
    );
    setActiveTemplate(template);
  };

  console.log("activetemplate", activeTemplate);
  return (
    <Modal show={quoteJob.status} onHide={closeModal}>
      <Modal.Header closeButton className="bg-primary text-white">
        Quote on {quoteJob.job.name}'s {quoteJob.job.jobType.name} job
      </Modal.Header>
      <Modal.Body>
        {errors && <Alert variant="danger">{errors}</Alert>}
        <Form id="select-template" noValidate onSubmit={sendQuote}>
          <Form.Group>
            <Form.Control
              name="name"
              as="select"
              custom
              onChange={handleChange}
              required
            >
              <option value="">Select Template</option>
              {business.templates &&
                business.templates.length > 0 &&
                business.templates.map((template) => {
                  let option;
                  if (template.name === activeTemplate.name) {
                    option = (
                      <option key={template.id} value={template.id} selected>
                        {template.name}
                      </option>
                    );
                  } else {
                    option = (
                      <option key={template.id} value={template.id}>
                        {template.name}
                      </option>
                    );
                  }
                  return option;
                })}
              <option value="create template"> + Create Template</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please choose a Template
            </Form.Control.Feedback>
          </Form.Group>
          {activeTemplate && activeTemplate.name !== "create template" && (
            <Form.Group>
              <Button type="submit" disabled={loading}>
                {loading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Submit Quote"
                )}
              </Button>
            </Form.Group>
          )}
        </Form>

        {activeTemplate.name === "create template" && (
          <Formik
            initialValues={{
              template_name: "",
              message: "",
            }}
            validationSchema={validationSchema}
            onSubmit={createTemplateSubmit}
            id="create-template"
          >
            {({
              handleSubmit,
              handleChange,
              errors,
              touched,
              values,
              isSubmitting,
            }) => (
              <Form id="create_template" noValidate onSubmit={handleSubmit}>
                <hr />
                <Form.Group>
                  <Form.Label>Template Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="template_name"
                    placeholder="Template Name"
                    value={values.template_name}
                    onChange={handleChange}
                    isInvalid={touched.template_name && errors.template_name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.template_name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    required
                    as="textarea"
                    placeholder="You can use @user_name to be replace with correct name every time you send quote"
                    name="message"
                    rows="5"
                    value={values.message}
                    onChange={handleChange}
                    isInvalid={touched.message && errors.message}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.message}
                  </Form.Control.Feedback>
                  <Form.Text id="passwordHelpBlock" muted>
                    We automatically replace @user_name with the correct name
                    every time you send a quote. You can type @user_name with
                    into any template to bring up the customer's name.
                  </Form.Text>
                </Form.Group>
                <Form.Group>
                  <Button type="submit" disable={isSubmitting || errors}>
                    {isSubmitting ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Create Template"
                    )}
                  </Button>
                </Form.Group>
              </Form>
            )}
          </Formik>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default QuoteJob;
