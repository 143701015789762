import React, { useEffect, useState, useRef } from "react";
// import { Container } from "react-bootstrap";
import { useLoadScript } from "@react-google-maps/api";
import MapComponent from "../google-map/MapComponent";
import PlacesAutocomplete from "../google-map/PlaceAutocomplete";
// import { useSelector } from "react-redux";
import { Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import JobTypeAutocomplete from "../Jobs/JobType/JobTypeAutocomplete";
import axios from "axios";
import {
  // getBusinessDetails,
  updateBusiness,
} from "../../redux/actions/businessActions";
import { getBusinessDetails } from "../../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import WithSidebar from "../layout/WithSidebar";
import { SET_BUSINESS_DETAILS } from "../../redux/types";
import Slider, { SliderTooltip } from "rc-slider";
import "rc-slider/assets/index.css";

const libraries = ["places"];
const { Handle } = Slider;

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={`${value} km`}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </SliderTooltip>
  );
};

const EditBusiness = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDPoIPwIh0EFiEE5ys3mN9UFRnB2n5dJ3o",
    libraries,
  });

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.profile);
  const history = useHistory();

  const initialCenter =
    !user.business || !user.business.location
      ? { lat: -25.274399, lng: 133.775131 }
      : {
          lat: user.business.location.lat,
          lng: user.business.location.lng,
        };
  const [center, setCenter] = useState(initialCenter);

  const formikInitialValues = !user.business
    ? {
        business_name: "",
        location: {
          name: "",
          lat: "",
          lng: "",
        },
        radius: 10,
        description: "",
        industry: "",
      }
    : {
        business_name: user.business.business_name,
        location: user.business.location
          ? {
              name: user.business.location.name,
              lat: user.business.location.lat,
              lng: user.business.location.lng,
            }
          : {
              name: "",
              lat: "",
              lng: "",
            },
        radius: user.business.radius ? user.business.radius : 10,
        description: user.business.description ? user.business.description : "",
        industry: user.business.industry ? user.business.industry : "",
      };

  const validationSchema = user.business
    ? yup.object().shape({
        location: yup
          .object()
          .required()
          .shape({
            name: yup.string().required("Required"),
            lat: yup.number().required("Required"),
            lng: yup.number().required("Required"),
          }),
        radius: yup.number().required("Required"),
        description: yup
          .string()
          .required("Required")
          .min(40, "Minimum of 40 characters"),
        industry: yup.string().required("Required"),
      })
    : yup.object().shape({
        business_name: yup.string().required("Required"),
        location: yup
          .object()
          .required()
          .shape({
            name: yup.string().required("Required"),
            lat: yup.number().required("Required"),
            lng: yup.number().required("Required"),
          }),
        radius: yup.number().required("Required"),
        description: yup
          .string()
          .required("Required")
          .min(40, "Minimum of 40 characters"),
        industry: yup.string().required("Required"),
      });

  // console.log(validationSchema);

  const handleSubmit = (values) => {
    console.log("submit values", values);
    axios
      .post("/business-details", values)
      .then((res) => {
        console.log("add business details res", res);
        // dispatch(getBusinessDetails(history));
        dispatch({ type: SET_BUSINESS_DETAILS, business: res.data });
        history.push("/dashboard/business/payments");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <h3>Business Details</h3>
      <Formik
        initialValues={formikInitialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        id="edit-business"
      >
        {({
          handleSubmit,
          handleChange,
          touched,
          errors,
          values,
          setFieldValue,
          handleBlur,
        }) => (
          <Form noValidation onSubmit={handleSubmit}>
            {/* {console.log("formik values", values)} */}
            {!user.business_details && (
              <Form.Group style={{ marginTop: "1rem" }}>
                <Form.Label htmlFor="business_name">Business Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Your business name"
                  name="business_name"
                  value={values.business_name}
                  isInvalid={touched.business_name && errors.business_name}
                  id="business_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
            )}

            <PlacesAutocomplete
              // touched={touched}
              // value={values.location.name}
              setCenter={setCenter}
              // setValue={setFieldValue}
              // onChange={handleChange}
              label="Where is your business located"
              name="location"
              setValue={setFieldValue}
              values={values}
            />
            <Form.Group style={{ marginTop: "1rem" }}>
              <Form.Label htmlFor="radius">
                How far can you provide your services (in Km)
              </Form.Label>
              <Slider
                min={6}
                max={12}
                // defaultValue={businessDetails.radius}
                step={2}
                value={values.radius}
                handle={handle}
                marks={{
                  6: "6 km",
                  8: "8 km",
                  10: "10 km",
                  12: "12",
                }}
                onChange={(value) => setFieldValue("radius", value)}
              />
              {/* <Form.Control
                type="number"
                // value={distance}
                onChange={handleChange}
                name="radius"
                value={values.radius}
                isInvalid={touched.radius && errors.radius}
                id="radius"
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid">
                {errors.radius}
              </Form.Control.Feedback> */}
            </Form.Group>
            <div
              style={{
                height: "450px",
                marginTop: "2rem",
              }}
              // ref={mapContainer}
            >
              <MapComponent
                center={center}
                radius={values.radius * 1000}
                zoom={
                  center.lat !== -25.274399 && center.lng !== 133.775131
                    ? 11
                    : 4
                }
                isLoaded={isLoaded}
                loadError={loadError}
                value={values}
              />
            </div>
            <Form.Group style={{ marginTop: "1rem" }}>
              <Form.Label htmlFor="description">Short Description</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Short description of your business"
                rows={5}
                name="description"
                value={values.description}
                isInvalid={touched.description && errors.description}
                isValid={touched.description && !errors.description}
                id="description"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid">
                {errors.description}
              </Form.Control.Feedback>
            </Form.Group>
            {/* <Form.Group>
              <JobTypeAutocomplete
                label="Which industry are your business in"
                setValue={setFieldValue}
                name="industry"
                value={values.industry}
                onChange={(name, e) => setFieldValue(name, e.target.value)}
                touched={touched.industry}
                errors={errors.industry}
              />
            </Form.Group> */}
            <Form.Group style={{ marginTop: "1.5rem" }}>
              <Button type="submit" className="w-50" onClick={handleSubmit}>
                Save Details
              </Button>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EditBusiness;
