import React, { useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Form, Button, Spinner, Container } from "react-bootstrap";
import PlaceAutocomplete from "../google-map/PlaceAutocomplete";
import JobTypeAutocomplete from "../Jobs/JobType/JobTypeAutocomplete";
import axios from "axios";
import { getNearbyPostalCode } from "../../utils";
import { useSelector, useDispatch } from "react-redux";
import { ADD_BUSINESS, SET_AUTHENTICATED } from "../../redux/types";
import { useHistory } from "react-router-dom";

const AddBusiness = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [error, setError] = useState(false);
  const formikInitialValues = {
    business_name: "",
    description: "",
    industry: "",

    location: {
      lat: "",
      lng: "",
      name: "",
      postal_code: null,
    },
    business_phone: "",
  };
  const validationSchema = yup.object().shape({
    business_name: yup.string().required("Required"),
    industry: yup.object().required("Required"),
    description: yup.string().required("Required").min(10, "Too short!"),
    location: yup
      .object()
      .required()
      .shape({
        name: yup.string().required("Required"),
        lat: yup.number().required("Required"),
        lng: yup.number().required("Required"),
        postal_code: yup.number(),
      }),
    business_phone: yup
      .string()
      .required("Required")
      .matches(
        /^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$/,
        "Invalid mobile or landline number"
      ),
  });

  const handleSubmit = async (values) => {
    console.log("about business values", values);
    const postcodes = await getNearbyPostalCode({
      lat: values.location.lat,
      lng: values.location.lng,
    });

    const businessAdded = await axios.post("add-business", {
      postcodes,
      ...values,
    });

    if (businessAdded) {
      dispatch({ type: ADD_BUSINESS, business: businessAdded.data });
      const userDetails = await axios.get("user-details");
      dispatch({ type: SET_AUTHENTICATED, user: userDetails.data });
      history.push("/dashboard/business/");
    } else {
      setError("Unable to register business. Please try again");
    }
  };

  // const user = useSelector(state => state.user.profile);

  // if (user.business) {
  //   return <Redirect to="/dashboard/business" />;
  // }

  return (
    <Container
      style={{ minHeight: "90vh", display: "grid", placeItems: "center" }}
    >
      <div style={{ margin: "1rem 0", width: "100%" }}>
        <h4 className="mb-5">Register my business</h4>
        <Formik
          initialValues={formikInitialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            touched,
            errors,
            setFieldValue,
            values,
            isSubmitting,
          }) => (
            <>
              {console.log("add business values", values)}
              {console.log("add business errors", errors)}
              <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label>
                    Business Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="business_name"
                    onChange={handleChange}
                    isInvalid={touched.business_name && errors.business_name}
                    placeholder="Business Name"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.business_name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <JobTypeAutocomplete
                    type="text"
                    name="industry"
                    placeholder="eg. Cleaner, Plumber, Electrician etc"
                    setValue={setFieldValue}
                    label="What kind of service can you provide (Required)"
                    values={values}
                  />
                </Form.Group>
                <Form.Group style={{ marginTop: "1rem" }}>
                  <Form.Label htmlFor="description">
                    Short Description ( Minimum 10 characters ){" "}
                    <span className="text-danger">*</span>
                    {errors.description ? (
                      <div style={{ color: "#C70039" }}>
                        {errors.description}
                      </div>
                    ) : null}
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Short description of your business"
                    rows={5}
                    name="description"
                    value={values.description}
                    isInvalid={touched.description && errors.description}
                    id="description"
                    onChange={handleChange}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>

                <PlaceAutocomplete
                  name="location"
                  label="Where are your business located (Required)"
                  setValue={setFieldValue}
                  // value={values.location}
                  values={values}
                />

                <Form.Group>
                  <Form.Label>
                    Business Phone <span className="text-danger">*</span>
                    {errors.business_phone ? (
                      <div style={{ color: "#C70039" }}>
                        {errors.business_phone}
                      </div>
                    ) : null}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="business_phone"
                    onChange={handleChange}
                    isInvalid={touched.business_phone && errors.business_phone}
                    placeholder="Mobile or Landline"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.business_phone}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  <Button
                    type="submit"
                    className="w-50 mt-3"
                    disabled={isSubmitting || Object.keys(errors).length > 0}
                  >
                    {isSubmitting ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Register"
                    )}
                  </Button>
                </Form.Group>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </Container>
  );
};

export default AddBusiness;
