import React, { useEffect } from "react";
import "./about-job.css";
// Redux Stuff
import { connect } from "react-redux";
import {
  setActiveStep,
  setJob,
  setLocation,
  setShowPostJob,
} from "../../../redux/actions/jobAction";
// import JobLocation from "../JobLocation/JobLocation";
import JobTypeAutocomplete from "../JobType/JobTypeAutocomplete";
import PlaceAutocomplete from "../../google-map/PlaceAutocomplete";
import { Formik } from "formik";
import * as yup from "yup";
import { Form, Button } from "react-bootstrap";

const AboutJob = ({
  job,
  location,
  activeStep,
  setActiveStep,
  setJob,
  setLocation,
  setShowPostJob,
  showPostJob,
}) => {
  // const { setValue } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formikInitialValues = {
    job,
    location,
  };

  const validationSchema = yup.object().shape({
    job: yup.object().required("Required"),
    location: yup.object().shape({
      name: yup.string().required("Required"),
      lat: yup.number().required("Required"),
      lng: yup.number().required("Required"),
    }),
  });

  const handleSubmit = (values, actions) => {
    console.log("about job values", values);
    setJob(values.job);
    setLocation(values.location);
    if (!showPostJob) {
      setShowPostJob(true);
    }
    setActiveStep(activeStep + 1);
  };

  return (
    <Formik
      initialValues={formikInitialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({
        handleSubmit,
        isSubmitting,
        setFieldError,
        setFieldValue,
        values,
        errors,
        resetForm,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div style={{ position: "relative" }}>
            <div style={{ margin: "15px 0" }}>
              <JobTypeAutocomplete
                type="text"
                name="job"
                placeholder="eg. Cleaner, Plumber, Electrician etc in lowercase"
                setValue={setFieldValue}
                label="What service would you like"
                values={values}
              />
            </div>
            <div style={{ margin: "15px 0" }}>
              <PlaceAutocomplete
                name="location"
                label="Where are you located"
                setValue={setFieldValue}
                // value={values.location}
                values={values}
              />
            </div>
          </div>
          <Button type="submit">Get Started</Button>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => ({
  activeStep: state.job.activeStep,
  job: state.job.job,
  location: state.job.location,
  showPostJob: state.job.showPostJob,
});

const mapDispatchToProps = {
  setActiveStep,
  setJob,
  setLocation,
  setShowPostJob,
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutJob);
