import React, { useState, useRef, useEffect, useMemo } from "react";
import AutocompleteItem from "./AutocompleteItem";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
// import { setJob } from "../../../redux/actions/jobAction";
import { useField } from "formik";

const Autocomplete = (props) => {
  // console.log("data", data);
  const [field, meta] = useField(props);
  const { data, label, setValue, name, values } = props;
  const [isVisible, setVisibility] = useState(false);
  // const [search, setSearch] = useState("");
  const [cursor, setCursor] = useState(-1);
  const searchContainer = useRef(null);
  const searchResultRef = useRef(null);
  const jobInput = useRef(null);

  // const [isValid, setIsValid] = useState(false);
  const [isInValid, setIsInValid] = useState(false);

  const jobType = useSelector((state) => state.job.job);

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    // setSearch(jobType);
    // jobType && setIsValid(true);
    jobType && setIsInValid(false);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const scrollIntoView = (position) => {
    isVisible &&
      searchResultRef &&
      searchResultRef.current.parentNode.scrollTo({
        top: position,
        behaviour: "smooth",
      });
  };

  const suggestions = useMemo(() => {
    let lowerString = "";
    if (!field.value) return "";
    setCursor(-1);
    scrollIntoView(0);

    //this should be fixed
    if (typeof field.value == "string") {
      lowerString = field.value.toLowerCase();
    }
    console.log("-----field value-----");
    console.log(typeof field.value);
    console.log(lowerString);
    console.log("-----field value-----");
    return data
      ? data.filter((item) => item.name.toLowerCase().includes(lowerString))
      : "";
  }, [data, field.value]);

  useEffect(() => {
    if (cursor < 0 || cursor > suggestions.length || !searchResultRef) {
      return () => {};
    }
    let listItems = Array.from(searchResultRef.current.children);
    listItems[cursor] && scrollIntoView(listItems[cursor].offsetTop);
  }, [cursor]);

  const handleClickOutside = (event) => {
    if (
      searchContainer.current &&
      !searchContainer.current.contains(event.target)
    ) {
      hideSuggestion();
    }
  };

  const keyboardNavigation = (e) => {
    // console.log(e.key, cursor);
    // console.log("e target keyboardNavigation", e.target);
    // if (!searchContainer.current.contains(e.target)) {
    //   hideSuggestion();
    // } else {
    //   showSuggestion();
    // }
    // if (!jobInput.current.focus()) {
    //   hideSuggestion();
    // } else {
    //   showSuggestion();
    // }
    if (e.key === "Tab" && validateJob(e.target.value)) {
      hideSuggestion();
    } else {
      showSuggestion();
    }
    !e.target.value && hideSuggestion();
    if (e.key === "ArrowDown") {
      // e.preventDefault();
      isVisible
        ? setCursor((c) => (c < suggestions.length - 1 ? c + 1 : c))
        : showSuggestion();

      //   return false;
    }
    if (e.key === "ArrowUp") {
      // e.stopPropagation();
      setCursor((c) => (c > 0 ? c - 1 : 0));
      // return false;
    }
    if (e.key === "Escape") {
      // e.stopPropagation();
      hideSuggestion();
      // handleSubmit();
      return;
    }
    if (e.key === "Enter" && cursor >= 0) {
      e.preventDefault();
      console.log("on Enter", suggestions[cursor]);
      // let value = suggestions[cursor];
      // console.log("value", value);
      // setJobType(suggestions[cursor].name);
      onSelect(suggestions[cursor]);
      hideSuggestion();
      // onSelect(suggestions[cursor]);
      return;
    }
  };

  const showSuggestion = () => setVisibility(true);
  const hideSuggestion = () => setVisibility(false);

  const validateJob = (suggestion) => {
    console.log("validate suggestion", suggestion);
    const job = data.filter((job) => job.name === suggestion.name);
    console.log("job", job);
    if (job.length <= 0) {
      // setIsInValid(true);
      // props.setFieldError("job", "Please select from the list");
      return false;
    } else {
      return true;
    }
  };

  const onSelect = (suggestion) => {
    hideSuggestion();
    // setJobType(suggestion);
    setValue(name, suggestion);
    // if (suggestion.categories) {
    //   setValue("name", {
    //     id: suggestion.id,
    //     name: suggestion.name,
    //     categories: suggestion.categories,
    //   });
    // } else {
    //   setValue(name, {
    //     id: suggestion.id,
    //     name: suggestion.name,
    //   });
    // }
    console.log("suggestion jobs", suggestion);
    // setJobType(suggestion);
    // console.log("field value", field.value);
    setIsInValid(false);
    // setIsValid(true);
  };

  // const handleBlur = e => {
  //   if (validateJob(e.target.value)) {
  //     setIsInValid(false);
  //   } else {
  //     setIsInValid(true);
  //   }
  // };

  // console.log("isInValid", isInValid);
  // console.log("meta error", meta.error);

  // console.log("job values in autocomplete", values);

  return (
    <div style={{ position: "relative" }} ref={searchContainer}>
      <Form.Group>
        {label && <Form.Label>{label}</Form.Label>}
        {/* <InputGroup> */}
        <Form.Control
          // required
          type="text"
          name={name}
          className="search-bar"
          autoComplete="off"
          onKeyDown={keyboardNavigation}
          ref={jobInput}
          placeholder="eg. Cleaner, Plumber etc in lowercase"
          onChange={field.onChange}
          value={values[name].name}
          isInvalid={isInValid || (meta.touched && meta.error)}
        />
        <Form.Control.Feedback type="invalid">
          {!field.value
            ? meta.error && meta.error.name
            : "Please select from the list"}
        </Form.Control.Feedback>
        {/* </InputGroup> */}
      </Form.Group>
      {/* </Form> */}
      <div
        className={`search-result ${isVisible ? "visible" : "invisible"}`}
        style={{
          position: "absolute",
          top: `${label ? "72px" : "40px"}`,
          width: "100%",
          maxHeight: "290px",
          overflowY: "auto",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.28)",
          zIndex: "500",
          overflow: "hidden",
        }}
      >
        <ul className="list-group" ref={searchResultRef}>
          {suggestions &&
            suggestions.map((suggestion, index) => (
              <AutocompleteItem
                key={suggestion.id}
                onSelectItem={onSelect}
                isHighlighted={cursor === index}
                suggestion={suggestion}
                {...suggestion}
              />
            ))}
        </ul>
      </div>
    </div>
  );
};

export default Autocomplete;
