import React, { useState } from "react";
import FacebookLogin from "../FacebookLogin";
import GoogleLogin from "../GoogleLogin";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import SignUpForm from "./SignUpForm";
import { Container, Col, Row, Alert } from "react-bootstrap";
import axios from "axios";
import { setAuthorizationHeader } from "../../redux/actions/userAction";
import { SET_AUTHENTICATED } from "../../redux/types";

const SignUp = () => {
  const auth = useSelector(state => state.user.profile);
  const [error, setError] = useState();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);

    axios
      .post("/signup", {
        name: values.name,
        email: values.email,
        password: values.password,
        confirmPassword: values.confirmPassword,
        phone: values.phone,
      })
      .then(res => {
        setAuthorizationHeader(res.data.token);
        return axios.get("/user-details");
        // history.push("/");
      })
      .then(userDetails => {
        dispatch({ type: SET_AUTHENTICATED, user: userDetails.data });
        // setLocalActiveStep(localActiveStep + 1);
        actions.setSubmitting(false);
        userDetails.data.business
          ? history.push("/dashboard/business")
          : history.push("/dashboard/customer");
      })
      .catch(err => {
        // console.log("sign up error", err.response);
        err.response && setError(err.response.data.error);
        actions.setSubmitting(false);
        // dispatch({
        //   type: SIGNUP_USER_ERROR,
        //   error: "Wrong Credentials, Please try again",
        // });
      });
  };

  return (
    <Container
      style={{ marginTop: "2.5rem", marginBottom: "2rem", minHeight: "90vh" }}
    >
      <Row>
        <Col sm={12} md={{ span: 6, offset: 3 }}>
          {auth && <Redirect to="/" />}
          <h3 style={{ textAlign: "center" }}>
            <span className="divider">Sign Up</span>
          </h3>
          {error && <Alert variant="danger">{error}</Alert>}
          <SignUpForm handleSubmit={handleSubmit} />
        </Col>
        <Col sm={12} md={{ span: 8, offset: 2 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <span
              className="divider"
              style={{ padding: "15px 0", textAlign: "center" }}
            >
              OR
            </span>
            <div className="social-container">
              <FacebookLogin />
              <GoogleLogin />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SignUp;
