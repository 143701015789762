import {
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_ERROR,
  SIGNIN_USER_SUCCESS,
  SIGNIN_USER_ERROR,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  USER_EXISTS,
  USER_NOT_EXISTS,
  UPDATE_USER_BUSINESSDETAILS,
  SET_AUTHENTICATED,
  SET_UNAUTHENTICATED,
  SET_BUSINESS_DETAILS,
  ADD_BUSINESS,
  UPDATE_JOBS,
} from "../types";
import firebase, {
  facebookAuthProvider,
  googleAuthProvider,
} from "../../firebase/firebase-config";
import axios from "axios";

export const setAuthorizationHeader = token => {
  const BearerToken = `Bearer ${token}`;
  window.localStorage.setItem("FBIdToken", token);
  axios.defaults.headers.common["Authorization"] = BearerToken;
};

export const updateUserBusiness = businessDetails => dispatch => {
  axios
    .post("/add-business", businessDetails)
    .then(res => {
      dispatch({ type: UPDATE_USER_BUSINESSDETAILS, businessDetails });
    })
    .catch(err => {
      console.log("update business details error", err);
    });
};

export const checkUser = email => dispatch => {
  axios
    .post("check-user", email)
    .then(res => {
      dispatch({
        type: USER_EXISTS,
        message: "Looks like, you have already registered. Please Login.",
      });
    })
    .catch(err => {
      console.log("check user err", err);
      dispatch({ type: USER_NOT_EXISTS, message: "new user" });
    });
  // return (dispatch, getState, { getFirebase }) => {
  //   const firebase = getFirebase();
  //   const firestore = firebase.firestore();

  //   firestore
  //     .collection("users")
  //     .where("email", "==", email)
  //     .get()
  //     .then(user => {
  //       if (user.empty) {
  //         dispatch({
  //           type: USER_EXISTS,
  //           message: "Looks like, you have already registered. Please Login.",
  //         });
  //       } else {
  //         dispatch({ type: USER_NOT_EXISTS, message: "new user" });
  //       }
  //     })
  //     .catch(err => {
  //       console.log("checkUser error", err);
  //     });
  // };
};

export const signUpUser = (newUser, history) => dispatch => {
  axios
    .post("/signup", newUser)
    .then(res => {
      setAuthorizationHeader(res.data.token);
      dispatch(getUserData(history));
      dispatch({ type: SIGNUP_USER_ERROR, error: null });
      // history.push("/");
    })
    .catch(err => {
      console.log("sign up error", err);
      dispatch({ type: SIGNUP_USER_ERROR, error: err.response.data });
    });
  // const history = useHistory();
  // return (dispatch, getState, { getFirebase }) => {
  //   const firebase = getFirebase();
  //   // const firestore = firebase.firestore();

  //   firebase
  //     .auth()
  //     .createUserWithEmailAndPassword(newUser.email, newUser.password)
  //     // .then(userCredential => {
  //     //   return firestore.collection("users").doc(userCredential.user.uid).set({
  //     //     email: newUser.email,
  //     //   });
  //     // })
  //     .then(user => {
  //       // dispatch({ type: SIGNUP_USER_SUCCESS, user });
  //       firebase.firestore().collection("users").doc(user.uid).set({
  //         email: newUser.email,
  //         createdAt: new Data().toISOString(),
  //         name: newUser.name,
  //         phone: newUser.phone,
  //         // provider: user.pr
  //       });
  //       dispatch(setUser(user.user));
  //       history.push("/dashboard");
  //     })
  //     .catch(err => {
  //       dispatch({ type: SIGNUP_USER_ERROR, err });
  //     });
  // };
};

export const signUpNewBusiness = (newBusiness, history) => dispatch => {
  axios
    .post("/register-business", newBusiness)
    .then(res => {
      setAuthorizationHeader(res.data.token);
      dispatch({
        type: SET_AUTHENTICATED,
        user: res.data.user,
      });
      history.push("/dashboard/business-details");
      // dispatch(getUserData(history, "/dashboard/business-details"));
      // dispatch({ type: SIGNUP_BUSINESS_ERROR, error: null });
      // history.push("/dashboard");
    })
    .catch(err => {
      console.log("new business error", err);
      // dispatch({ type: SIGNUP_BUSINESS_ERROR, error: err.data });
    });
  // return (dispatch, getState, { getFirebase }) => {
  //   const firebase = getFirebase();
  //   const firestore = firebase.firestore();

  //   firebase
  //     .auth()
  //     .createUserWithEmailAndPassword(newBusiness.email, newBusiness.password)
  //     .then(userCredential => {
  //       return firestore.collection("users").doc(userCredential.user.uid).set({
  //         email: newBusiness.email,
  //         type: "business",
  //       });
  //     })
  //     .then(user => {
  //       // dispatch({ type: SIGNUP_USER_SUCCESS, user });
  //       console.log("business user", user.data());
  //       setUser(user);
  //     })
  //     .catch(err => {
  //       dispatch({ type: SIGNUP_USER_ERROR, err });
  //     });
  // };
};

export const signUpBusinessForExistingUser =
  (newBusiness, history) => dispatch => {
    axios
      .post("/add-business", newBusiness)
      .then(res => {
        dispatch(getUserData(history, "/dashboard/business-details"));
      })
      .catch(err => {
        console.log("update business details error", err);
      });
  };

// export const signInUser = (credentials, history, destination) => dispatch => {
//   axios
//     .post("/login", credentials)
//     .then(res => {
//       dispatch({ type: SIGNIN_USER_ERROR, error: null });
//       setAuthorizationHeader(res.data.token);
//       destination
//         ? dispatch(getUserData(history, destination))
//         : dispatch(getUserData(history));

//       // destination ? history.push(destination) : history.push("/dashboard");
//       // history.push("/dashboard");
//       // console.log("after history push signinuser");
//     })
//     .catch(err => {
//       // console.log("signin user error", err.response);
//       // console.log("sigin user error", err.error);
//       dispatch({ type: SIGNIN_USER_ERROR, error: err.error });
//       console.log("sign in business registration error", err);
//     });
// };

export const logOut = history => dispatch => {
  // dispatch({ type: SIGNIN_USER_ERROR, error: null });
  firebase
    .auth()
    .signOut()
    .then(() => {
      window.localStorage.removeItem("FBIdToken");
      delete axios.defaults.headers.common["Authorization"];
      dispatch({ type: SET_UNAUTHENTICATED });
      if (history) {
        history.push("/user/signin");
      }
    })
    .catch(error => {
      console.log("log out error", error);
    });

  // return (dispatch, getState, { getFirebase }) => {
  //   const firebase = getFirebase();
  //   firebase
  //     .auth()
  //     .signOut()
  //     .then(() => {
  //       dispatch({ type: LOGOUT_SUCCESS });
  //       history.push("/");
  //     })
  //     .catch(err => {
  //       dispatch({ type: LOGOUT_ERROR });
  //     });
  // };
};

// export const authWithFacebook = async dispatch => {};

// export const authWithGoogle = () => {
//   return (dispatch, getState, { getFirebase }) => {
//     const firebase = getFirebase();
//     firebase
//       .auth()
//       .signInWithPopup(googleAuthProvider)
//       .then((res, err) => {
//         if (err) {
//           console.log("Unable to sign in with Facebook");
//         } else {
//           console.log("user: ", res);
//           dispatch({ type: SIGNIN_USER_SUCCESS, res });
//         }
//       })
//       .catch(error => {
//         console.log("Unable to sign in with Facebook");
//       });
//   };
// };

// export const getUserData = (history, destination) => dispatch => {
export const getUserData = (history, destination) => dispatch => {
  axios
    .get("/user-details")
    .then(res => {
      console.log("getUserData res", res);
      // dispatch(setUser(res.data));
      dispatch({ type: SET_AUTHENTICATED, user: res.data });
      destination && history
        ? history.push(destination)
        : res.data.business
        ? history.push("/dashboard/business/")
        : history.push("/dashboard/jobs/");
    })
    .catch(err => {
      console.log("user details err", err);
    });
};

export const getBusinessDetails = history => dispatch => {
  axios
    .get("/business-details")
    .then(res => {
      dispatch({ type: SET_BUSINESS_DETAILS, business: res.data });
      history.push("/dashboard");
    })
    .catch(err => {
      console.log("get busienss details error", err);
    });
};

export const getUserJobs = history => dispatch => {
  axios
    .get("/user-jobs")
    .then(res => {
      dispatch({ type: UPDATE_JOBS, jobs: res.data });
      history.push("/dashboard/jobs");
    })
    .catch(err => {
      console.log("get user job error", err);
    });
};
