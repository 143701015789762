import React from "react";
import { Formik } from "formik";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import { checkUser } from "../../redux/actions/userAction";
import { useDispatch } from "react-redux";

const CheckUser = ({ handleSubmit, helperText }) => {
  // const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Required").email("Invalid Email"),
  });

  // const handleSubmit = async values => {
  //   setEmail(values.email);
  //   axios
  //     .post("/checkuser", values)
  //     .then(res => {
  //       console.log("check user res", res);
  //       setUserExists(true);
  //       setBusinessStep(2);
  //     })
  //     .catch(err => {
  //       console.log("check user res", err);
  //       setUserExists(false);
  //       setBusinessStep(2);
  //     });
  // };

  return (
    <Container>
      <Formik
        initialValues={{ email: "" }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleChange, handleSubmit, touched, errors, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            {console.log("isSubmitting", isSubmitting)}
            <Form.Group>
              <Form.Label htmlFor="email">Email *</Form.Label>
              <Form.Control
                type="email"
                name="email"
                id="email"
                placeholder="Enter your preferred email address"
                onChange={handleChange}
                isInvalid={touched.email && errors.email}
              />
              {!errors.email && (
                <Form.Text className="text-muted">
                  {helperText
                    ? helperText
                    : "We'll never share your email with anyone else"}
                </Form.Text>
              )}

              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Button type="submit" className="w-50" disabled={isSubmitting}>
                {isSubmitting ? (
                  <Spinner size="sm" animation="border" />
                ) : (
                  "Continue"
                )}
              </Button>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default CheckUser;
