/* eslint-disable import/no-anonymous-default-export */
import {
  SIGNUP_USER_SUCCESS,
  SIGNIN_USER_SUCCESS,
  SIGNIN_USER_ERROR,
  SIGNUP_USER_ERROR,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  USER_EXISTS,
  USER_NOT_EXISTS,
  UPDATE_USER_BUSINESSDETAILS,
  SET_AUTHENTICATED,
  SET_UNAUTHENTICATED,
  SET_BUSINESS_DETAILS,
  UPDATE_JOBS,
  CHANGE_RADIUS,
  ADD_TEMPLATE,
  UPDATE_BUSINESS_DESCRIPTION,
  UPDATE_BUSINESS_LOGO,
  UPDATE_BUSINESS_GALLERY,
  UPDATE_MEMBERSHIP,
  UPDATE_JOB,
  SET_JOBS,
  UPDATE_BUSINESS_DETAILS,
  UPDATE_CONTACT_METHOD,
  UPDATE_MEMBERSHIP_STATUS,
} from "../types";

const initialState = {
  profile: null,
  signUpError: null,
  signInError: null,
  // logOutError: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        profile: action.user,
      };
    case SET_UNAUTHENTICATED:
      return {
        ...state,
        profile: null,
      };

    case SIGNUP_USER_ERROR:
      return {
        ...state,
        signUpError: action.error,
      };
    case SIGNIN_USER_SUCCESS:
      return {
        ...state,
        profile: action.user,
      };
    case SIGNIN_USER_ERROR:
      return {
        ...state,
        signInError: action.error,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        profle: null,
      };
    case LOGOUT_ERROR:
      return {
        ...state,
        logOutError: action.err.message,
      };

    // case USER_EXISTS:
    //   return {
    //     ...state,
    //     userStatus: {
    //       status: true,
    //       message: action.message
    //     }
    //   };

    // case USER_NOT_EXISTS:
    //   return {
    //     ...state,
    //     userStatus: action.message,
    //   };

    case UPDATE_USER_BUSINESSDETAILS:
      return {
        ...state,
        business: action.business,
      };

    case SET_BUSINESS_DETAILS:
      return {
        ...state,
        profile: {
          ...state.profile,
          business: action.business,
        },
      };

    case CHANGE_RADIUS:
      return {
        ...state,
        profile: {
          ...state.profile,
          business: {
            ...state.profile.business,
            radius: action.radius,
          },
        },
      };

    case UPDATE_JOBS:
      return {
        ...state,
        profile: {
          ...state.profile,
          jobs: action.jobs,
        },
      };

    case UPDATE_JOB:
      return {
        ...state,
        profile: {
          ...state.profile,
          jobs: state.profile.jobs.map(jobitem => {
            if (jobitem.id === action.job.id) {
              return {
                ...jobitem,
                status: action.job.status,
              };
            }
            return jobitem;
          }),
        },
      };

    case ADD_TEMPLATE:
      return {
        ...state,
        profile: {
          ...state.profile,
          business: {
            ...state.profile.business,
            templates: [...state.profile.business.templates, action.template],
          },
        },
      };

    case UPDATE_BUSINESS_DESCRIPTION:
      return {
        ...state,
        profile: {
          ...state.profile,
          business: {
            ...state.profile.business,
            description: action.description,
          },
        },
      };

    case UPDATE_BUSINESS_LOGO:
      return {
        ...state,
        profile: {
          ...state.profile,
          business: {
            ...state.profile.business,
            logo: action.logo,
          },
        },
      };

    case UPDATE_BUSINESS_GALLERY:
      return {
        ...state,
        profile: {
          ...state.profile,
          business: {
            ...state.profile.business,
            gallery: action.gallery,
          },
        },
      };

    case UPDATE_MEMBERSHIP:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.memberhip,
        },
      };

    case UPDATE_JOB:
      return {
        ...state,
        profile: {
          ...state.profile,
          jobs: state.profile.jobs.map(item => {
            if (item.id === action.job.id) {
              return {
                ...item,
                status: action.job.status,
              };
            }
            return item;
          }),
        },
      };

    case SET_JOBS:
      return {
        ...state,
        profile: {
          ...state.profile,
          jobs: action.jobs,
        },
      };

    case UPDATE_BUSINESS_DETAILS:
      return {
        ...state,
        profile: {
          ...state.profile,
          business: {
            ...state.profile.business,
            businessName: action.businessDetails.businessName,
            location: action.businessDetails.location,
            description: action.businessDetails.description,
          },
        },
      };

    case UPDATE_CONTACT_METHOD:
      return {
        ...state,
        profile: {
          ...state.profile,
          business: {
            ...state.profile.business,
            contactMethod: action.contactMethod,
          },
        },
      };

    case UPDATE_MEMBERSHIP_STATUS:
      return {
        ...state,
        profile: {
          ...state.profile,
          status: action.status,
        },
      };

    default:
      return state;
  }
}
