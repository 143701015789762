import styled from "styled-components";
import { FiX } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { Button } from "react-bootstrap";

export const MobileNavContainer = styled.aside`
  position: fixed;
  z-index: 1500;
  width: 100%;
  height: 100%;
  background: #124870;
  top: 0;
  // left: 0;
  transition: 0.3s ease-in-out;
  display: grid;
  margin: auto 0;
  color: #fff;
  overflow: hidden;
  right: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
`;

export const CloseIcon = styled(FiX)`
  color: #fff;
`;
export const Icon = styled.div`
  font-size: 2rem;
  position: absolute;
  top: 1rem;
  right: 2rem;
  cursor: pointer;
`;
export const NavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 70vh;
  margin: auto;
  width: 60vw;
`;

export const Logo = styled.img`
  width: 60vw;
  object-fit: cover;
`;

export const NavMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  width: 100%;
`;

export const NavItems = styled(NavLink)`
  color: #fff;
  padding: 15px 10px;
  transition: 0.2s ease-in-out;
  display: block;
  width: 100%;

  &:hover {
    color: #000;
    background: #fff;
  }

  &:active {
    color: #000;
    background: #fff;
  }
`;
export const BtnWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1.5rem;
  padding: 10px 0;
  width: 100%;
`;
export const NavButton = styled(Button)`
  background: transparent;
  outline: none;
  border: 2px solid #fff;
  border-radius: 5px;
  // padding: 6px 25px;
  margin-bottom: 1.5rem;
  width: 100%;
`;
