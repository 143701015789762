import React, { useState } from "react";
import { Formik } from "formik";
import { Form, InputGroup, Spinner, Button, Collapse } from "react-bootstrap";
import { FiEye, FiEyeOff } from "react-icons/fi";
import * as yup from "yup";
import { Link } from "react-router-dom";

const SignInForm = ({ handleSubmit, email, disabled }) => {
  const [show, setShow] = useState(false);

  const validationSchema = yup.object().shape({
    email: yup.string().email("Invaid Email").required("Required"),
    password: yup.string().required("Required"),
  });

  const formikInitialValues = {
    email: email ? email : "",
    password: "",
  };

  return (
    <Formik
      initialValues={formikInitialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({
        handleChange,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        values,
        actions,
      }) => (
        <Form onSubmit={handleSubmit} id="signInFormik">
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="Email"
              onChange={handleChange}
              // isValid={touched.email && !errors.email}
              isInvalid={touched.email && errors.email}
              value={values.email}
              disabled={disabled}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Password</Form.Label>
            <InputGroup>
              <Form.Control
                type={show ? "text" : "password"}
                name="password"
                placeholder="Password"
                onChange={handleChange}
                // isValid={touched.password && !errors.password}
                isInvalid={touched.password && errors.password}
                value={values.password}
              />
              <InputGroup.Append onClick={() => setShow(!show)}>
                <InputGroup.Text style={{ cursor: "pointer" }}>
                  {show ? <FiEyeOff /> : <FiEye />}
                </InputGroup.Text>
              </InputGroup.Append>
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "1.5rem",
                alignItems: "center",
              }}
            >
              <Button
                type="submit"
                className="w-50"
                variant="primary"
                block
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Login"
                )}
              </Button>
              <Collapse>
                <Link to="/forgot-password">Forgot Password</Link>
              </Collapse>
              <Link to="/forgot-password">Forgot Password</Link>
            </div>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
};

export default SignInForm;
