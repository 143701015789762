import * as yup from "yup";

// const FILE_SIZE = 1600 * 1024;
// const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

const validationSchema = [
  yup.object().shape({
    job: yup.string().required("Required"),
    location: yup.object().shape({
      name: yup.string().required("Required"),
      lat: yup.number().required("Required"),
      lng: yup.number().required("Required"),
    }),
  }),
  yup.object().shape({
    description: yup
      .string()
      .required("Required")
      .min(20, "Minimum 20 Characters"),
    // attachments: yup
    //   .mixed().shape([

    //   ])
    //   .nullable()
    //   .test("fileSize", "File too large", val => {
    //     if (val) {
    //       console.log("validation schema val", val);
    //       return val.size <= FILE_SIZE;
    //     }
    //     return true;
    //   })
    //   .test("fileFormat", "Unsupported Format", val => {
    //     if (val) {
    //       return SUPPORTED_FORMATS.includes(val.type);
    //     }
    //     return true;
    //   }),
  }),
  yup.object().shape({
    when: yup.string().required("Required"),
    // date: yup.ref("when"),
    priority: yup.string().required("Required"),
  }),
  yup.object().shape({
    user: yup.object().shape({
      exist: yup.boolean().nullable(),
      email: yup.string().required("Required").email("Invalid Email"),
      password: yup.string().when("exist", {
        is: value => value === false || value === true,
        then: yup
          .string()
          .required("Required")
          .min(6, "Minimum of 6 characters"),
      }),
      name: yup.string().when("exist", {
        is: value => value === false,
        then: yup.string().required("Required"),
      }),
      phone: yup.string().when("exist", {
        is: value => value === false,
        then: yup
          .string()
          .min(9, "Invalid")
          .max(10, "Invalid")
          .matches(
            /^((\\+[0-9]{1,3}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
            "Invalid"
          )
          .required("Required"),
      }),
    }),
  }),
];

export default validationSchema;
