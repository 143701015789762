import React from "react";
import { Route, Redirect } from "react-router-dom";
// Redux
import { useSelector } from "react-redux";

const PrivateRoute = ({ component: Component, ...rest }) => {
  // console.log('redux user', user);
  const user = useSelector(state => state.user.profile);

  return (
    <Route
      {...rest}
      render={props => {
        return user ? <Component {...props} /> : <Redirect to="/" />;
      }}
    ></Route>
  );
};

export default PrivateRoute;
