import React, { useState } from "react";
import { FiFacebook } from "react-icons/fi";
// import { FiArrowLeftCircle } from 'react-icons/fi';

import firebase, { facebookAuthProvider } from "../firebase/firebase-config";
import { useDispatch } from "react-redux";
// Redux Stuff
// import { connect } from "react-redux";
import {
  getUserData,
  setAuthorizationHeader,
} from "../redux/actions/userAction";
import { SIGNIN_USER_ERROR } from "../redux/types";
import { Spinner } from "react-bootstrap";

const FacebookLogin = ({ destination, errorMessage, history }) => {
  // let history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const authwithFacebook = () => {
    // console.log("Loggin or signingup with Facebook");
    setLoading(true);
    firebase
      .auth()
      .signInWithPopup(facebookAuthProvider)
      .then(facbookAuth => {
        // console.log('facebook signin or sign up response', res)
        // const user = googleAuth.user
        console.log();
        return facbookAuth.user.getIdToken();
      })
      .then(token => {
        dispatch({ type: SIGNIN_USER_ERROR, error: null });
        setAuthorizationHeader(token);
        setLoading(false);
        destination
          ? dispatch(getUserData(history, destination))
          : dispatch(getUserData(history));
      })
      .catch(error => {
        console.log("facebook login error", error);
        setLoading(false);
        dispatch({ type: SIGNIN_USER_ERROR, error: error.message });
      });
  };

  return (
    <button className="facebook-login" onClick={authwithFacebook}>
      {!loading ? (
        <>
          <FiFacebook /> <span>Sign in with Facebook</span>
        </>
      ) : (
        <Spinner animation="border" size="sm" />
      )}
    </button>
  );
};

// const mapStateToProps = state => ({
//   user: state.user,
// });

// const mapDispatchToProps = {
//   setUser,
// };

// export default connect(null, mapDispatchToProps)(FacebookLogin);
export default FacebookLogin;
