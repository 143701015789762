import React from "react";
import FacebookLogin from "../FacebookLogin";
import GoogleLogin from "../GoogleLogin";
// import { auth } from "../../firebase/firebase-config";
// import { setUser } from '../../redux/actions/userAction';
// import { useHistory } from "react-router-dom";
import { SET_AUTHENTICATED, SIGNIN_USER_ERROR } from "../../redux/types";
import { useSelector, useDispatch } from "react-redux";
import { setAuthorizationHeader } from "../../redux/actions/userAction";
import { Redirect } from "react-router-dom";
import SignInForm from "./SignInForm";
import { Collapse, Alert } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Row, Col, Container } from "react-bootstrap";

const SignIn = () => {
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  // const [errors, setErrors] = useState("");
  // const history = useHistory();
  const dispatch = useDispatch();
  // const history = useHistory();

  const user = useSelector((state) => state.user.profile);
  const signInError = useSelector((state) => state.user.signInError);
  const history = useHistory();

  const authWithEmailPassword = (values, actions) => {
    dispatch({ type: SIGNIN_USER_ERROR, error: null });
    const { email, password } = values;
    // console.log("sign in values", values);
    // await dispatch(
    axios
      .post("/login", { email, password })
      .then((res) => {
        setAuthorizationHeader(res.data.token);
        return axios.get("/user-details");
      })
      .then((userDetails) => {
        dispatch({ type: SET_AUTHENTICATED, user: userDetails.data });
        actions.setSubmitting(false);
        userDetails.data.business
          ? history.push("/dashboard/business")
          : history.push("/dashboard/jobs/");
      })
      .catch((err) => {
        console.log("sign in error", err.error);
        actions.setSubmitting(false);
        dispatch({
          type: SIGNIN_USER_ERROR,
          error: "Wrong Credentials, Please try again",
        });
      });

    // );
  };

  // const handleSubmit = values => {
  //   dispatch(signInUser(values));
  // }

  return (
    <Container style={{ minHeight: "80vh" }}>
      <Row
        className="shadow bg-lighter py-5 rounded mt-5"
        style={{
          alignContent: "center",
        }}
      >
        {user && <Redirect to="/dashboard" />}

        <Col sm={12} md={{ span: 6, offset: 3 }}>
          {/* <div> */}
          <h3>
            <span className="divider">Sign In</span>
          </h3>
          {signInError && (
            <Collapse in={signInError}>
              <Alert variant="danger">{signInError}</Alert>
            </Collapse>
          )}
          {/* {signInError && <h5 style={{ color: "red" }}>{signInError}</h5>} */}
          <SignInForm handleSubmit={authWithEmailPassword} />
          {/* </div> */}
        </Col>
        <Col sm={12} md={{ span: 8, offset: 2 }}>
          <span
            className="divider"
            style={{ padding: "15px 0", textAlign: "center" }}
          >
            OR
          </span>

          <div className="social-container">
            <FacebookLogin
              errorMessage="Failed to login with Facebook"
              history={history}
            />
            <GoogleLogin
              errorMessage="Failed to login with Google"
              history={history}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SignIn;
