import React, { useState } from "react";
import { Form, InputGroup, Button, Alert, Spinner } from "react-bootstrap";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { setAuthorizationHeader } from "../../redux/actions/userAction";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import PlaceAutocomplete from "../google-map/PlaceAutocomplete";
import axios from "axios";
import { SET_AUTHENTICATED } from "../../redux/types";
import { getNearbyPostalCode } from "../../utils";
import JobTypeAutocomplete from "../Jobs/JobType/JobTypeAutocomplete";

const AboutBusiness = ({ email }) => {
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector(state => state.user.profile);

  const newBusinessValidationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    business_name: Yup.string().required("Required"),
    industry: Yup.object().required("Required"),
    password: Yup.string()
      .required("Required")
      .min(6, "Should be min of 6 characters"),
    location: Yup.object()
      .required()
      .shape({
        name: Yup.string().required("Required"),
        lat: Yup.number().required("Required"),
        lng: Yup.number().required("Required"),
        postcode: Yup.number(),
        state: Yup.string().required("Required"),
      }),
    phone: Yup.string()
      .required("Required")
      .matches(
        /^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$/,
        "Invalid"
      ),
  });

  const businessAboutSubmit = async (values, actions) => {
    console.log("Business About values", { email, ...values });
    const postcodes = await getNearbyPostalCode({
      lat: values.location.lat,
      lng: values.location.lng,
    });
    console.log("postcodes", postcodes);
    try {
      const postedBusiness = await axios.post("/register-business", {
        email,
        ...values,
        postcodes,
      });
      console.log("postedBusiness", postedBusiness);
      setAuthorizationHeader(postedBusiness.data.token);
      delete postedBusiness.data.token;
      dispatch({ type: SET_AUTHENTICATED, user: postedBusiness.data });
      actions.resetForm();
      history.push("/dashboard/business");
    } catch (e) {
      console.log(e);
    }
    // const postedBusiness = await axios.post("/register-business", {
    //   email,
    //   ...values,
    //   postcodes,
    // });
    // console.log("postedBusiness", postedBusiness);
    // if (postedBusiness) {
    //   setAuthorizationHeader(postedBusiness.data.token);
    //   delete postedBusiness.data.token;
    //   dispatch({ type: SET_AUTHENTICATED, user: postedBusiness.data });
    //   actions.resetForm();
    //   history.push("/dashboard/business");
    // } else {
    //   setError("Unable to register. Please try again");
    // }
  };

  return (
    <>
      {user && user.business_name ? (
        <Redirect to="/dashboard/business-details" />
      ) : null}
      <p>Please provide your business details.</p>
      {error && <Alert variant="danger">{error}</Alert>}
      <Formik
        initialValues={{
          name: "",
          business_name: "",
          password: "",
          phone: "",
          description: "",
          industry: {},
          location: {
            name: "",
            lat: "",
            lng: "",
            postcode: null,
            state: "",
          },
        }}
        onSubmit={businessAboutSubmit}
        validationSchema={newBusinessValidationSchema}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          touched,
          errors,
          handleBlur,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} noValidation className="form">
            {console.log("about business formik value", values)}
            <Form.Group>
              <Form.Label htmlFor="name">
                Name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                id="name"
                placeholder="Name"
                onChange={handleChange}
                isInvalid={touched.name && errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Password * </Form.Label>
              <InputGroup>
                <Form.Control
                  type={show ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  onChange={handleChange}
                  isInvalid={touched.password && errors.password}
                  value={values.password}
                  onBlur={handleBlur}
                />
                <InputGroup.Append onClick={() => setShow(!show)}>
                  <InputGroup.Text style={{ cursor: "pointer" }}>
                    {show ? <FiEye /> : <FiEyeOff />}
                  </InputGroup.Text>
                </InputGroup.Append>
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="business_name">
                Business Name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="business_name"
                id="business_name"
                placeholder="Business Name"
                onChange={handleChange}
                isInvalid={touched.business_name && errors.business_name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.business_name}
              </Form.Control.Feedback>
            </Form.Group>
            <JobTypeAutocomplete
              type="text"
              name="industry"
              placeholder="eg. Cleaner, Plumber, Electrician etc"
              setValue={setFieldValue}
              label="What service can you provide"
              values={values}
            />

            <Form.Group style={{ marginTop: "1rem" }}>
              <Form.Label htmlFor="description">Short Description</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Short description of your business"
                rows={5}
                name="description"
                value={values.description}
                isInvalid={touched.description && errors.description}
                id="description"
                onChange={handleChange}
              />

              <Form.Control.Feedback type="invalid">
                {errors.description}
              </Form.Control.Feedback>
            </Form.Group>

            <PlaceAutocomplete
              name="location"
              label="Where are your business located"
              setValue={setFieldValue}
              // value={values.location}
              values={values}
            />
            <Form.Group>
              <Form.Label>Business Phone</Form.Label>

              {/* <InputGroup> */}
              {/* <InputGroup.Prepend>
                  <InputGroup.Text>+61</InputGroup.Text>
                </InputGroup.Prepend> */}
              <Form.Control
                type="tel"
                name="phone"
                placeholder="Mobile or Landline"
                onChange={handleChange}
                isInvalid={touched.phone && errors.phone}
              />
              <Form.Text className="text-muted">
                Preferably mobile number as you can get SMS notification
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                {errors.phone}
              </Form.Control.Feedback>
              {/* </InputGroup> */}
            </Form.Group>
            <Form.Group>
              <Button
                type="submit"
                className="w-50 mt-2"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Register"
                )}
              </Button>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AboutBusiness;
