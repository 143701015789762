import React, { useState } from "react";
import JobTypeAutocomplete from "../Jobs/JobType/JobTypeAutocomplete";
import { Formik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import Footer from "../footer/Footer";

const JobType = () => {
  const industry = useSelector(state => state.user.profile.business.industry);
  // const [industry, setIndustry] = useState([]);

  const handleSubmit = values => {
    console.log(values);
  };

  const validationSchema = yup.object().shape({
    industry: yup
      .object()
      .shape({
        id: yup.string().required("Required"),
        name: yup.string().required("Required"),
      })
      .nullable(),
  });

  return (
    <div>
      <div>
        <Formik
          onSubmit={handleSubmit}
          initialValues={{
            industry: { id: "", name: "" },
          }}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, setFieldValue, values }) => (
            <Form onSubmit={handleSubmit}>
              {console.log("jobtype jobs", values)}
              <JobTypeAutocomplete
                name="industry"
                setValue={setFieldValue}
                label="Would you like to add other work that you do"
                values={values}
              />
              {/* <Form.Group>
                <Form.Control type="text" name="industry" />
              </Form.Group> */}
            </Form>
          )}
        </Formik>
      </div>
      <div>
        {/* {industry.map(item => {}
          // <h4>{item.name}</h4>
          item.categories && (

          )
        )} */}
      </div>
    </div>
  );
};

export default JobType;
