import React, { forwardRef } from "react";
import "./home-hero.css";
import GetQuoteForm from "../getquoteform/GetQuoteForm";
// import video from "../../assets/img/kitchen-section-cabinets.mp4";
// import poster from "../../assets/img/woodworker.jpeg";
// import { Container } from "react-bootstrap";
// import AboutJob from "../Jobs/about-job/AboutJob";

const HomeHero = forwardRef((props, ref) => {
  return (
    <div className="home-hero" ref={ref}>
      <div className="container">
        {/* <div
        className="slanted-div"
        style={{ width: "50%", display: "grid", placeItems: "center" }}
      > */}
        <div className="home-form-container">
          <div style={{ padding: "15px" }}>
            <h1
              className="section-header"
              style={{ color: "white", marginBottom: "20px" }}
            >
              Post your job and get free quotes
            </h1>
            {/* <div style={{ maxWidth: "450px" }}>
            <AboutJob />
          </div> */}
            <GetQuoteForm />
          </div>
        </div>
        {/* </div>
      <div className="featured-image">
        
      </div> */}
      </div>
    </div>
  );
});

// const HomeHero = forwardRef((props, ref) => {
//   return (
//     <div className="home-hero" ref={ref}>
//       <video playsInline autoPlay muted loop poster={poster} id="bgvid">
//         <source src={video} type="video/mp4" />
//       </video>
//       <div id="form-container">
//         <Container style={{ position: "relative" }}>
//           <div className="home-form-container">
//             <div style={{ padding: "10px 20px 20px 20px" }}>
//               <h2 className="section-header">
//                 Post your job and get free quotes
//               </h2>
//               {/* <AboutJob /> */}
//               <GetQuoteForm />
//             </div>
//           </div>
//         </Container>
//       </div>
//     </div>
//   );
// });

export default HomeHero;
