import React, { useRef } from "react";
import { Form } from "react-bootstrap";
import { FiCamera } from "react-icons/fi";
//Redux Stuff
import { useDispatch } from "react-redux";
import { setAttachment } from "../../../redux/actions/jobAction";
import { useField } from "formik";

const FileUpload = props => {
  const inputFile = useRef(null);
  const dispatch = useDispatch();
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
  const [field, meta] = useField(props);
  const { name, setValue, setFieldError, maxFileSize } = props;
  // const attachments = useSelector(state => state.job.attachments);

  // const attachAttachment = dispatch(file => setAttachment(file));

  console.log("attachments field", field);
  console.log("file upload meta", meta);

  const AttachPhotos = ({ error }) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        color: `${error ? "red" : "inherit"}`,
        cursor: "pointer",
      }}
    >
      <FiCamera /> <span style={{ marginLeft: "10px" }}>Attach Photos</span>
    </div>
  );

  const showFileUpload = () => {
    inputFile.current.click();
  };

  const imageUpload = e => {
    e.preventDefault();
    let files = e.target.files;
    console.log("image upload event", e);

    if (files) {
      Array.from(files).forEach(file => {
        console.log("file", file);
        const size = parseFloat(file.size / (1024 * 1024)).toFixed(2);
        console.log("actual file size is ", file.size);
        console.log("calculated file size is ", size);
        if (SUPPORTED_FORMATS.includes(file.type) && size <= maxFileSize) {
          setValue(name, [file, ...field.value]);
          dispatch(setAttachment(file));
          setFieldError(field.name, null);
        } else {
          if (!SUPPORTED_FORMATS.includes(file.type)) {
            setFieldError(name, "Unsupported Format");
          }
          if (size > maxFileSize) {
            setFieldError(name, "Maximum upload file size is 2Mb");
          }
        }
      });
    }
  };

  return (
    <>
      {/* <Form.Row> */}
      <Form.Group style={{ marginBottom: "0.5rem" }} className="col-sm-12">
        <Form.Label htmlFor="attachmentsFormik" onClick={showFileUpload}>
          <AttachPhotos error={meta.error} />
          {meta.error && (
            <div>
              <span
                style={{
                  color: "red",
                  fontSize: "0.7rem",
                  display: "block",
                  margin: "0.5rem 0",
                }}
              >
                {meta.error}
              </span>
            </div>
          )}
        </Form.Label>
        <Form.File
          label="Attach Photos"
          // className="d-none"
          // onChange={e => {
          //   console.log("file attachment", e.target.files[0]);
          //   setValue(name, [...field.value, e.target.files[0]]);
          // }}
          onChange={imageUpload}
          multiple
          custom
          ref={inputFile}
          isInvalid={meta.touched && meta.error}
          name={name}
          // id="attachmentsFormik"
          className="d-none"
        />
      </Form.Group>
      {/* </Form.Row> */}
      {/* {errorMessage && ( */}
      {/* <Form.Control.Feedback type="invalid"> */}
      {/* {errors.attachement} */}
      {/* {errorMessage} */}
      {/* </Form.Control.Feedback> */}
      {/* )} */}
    </>
  );
};

export default FileUpload;
