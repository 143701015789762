import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Card,
  Tooltip,
  Accordion,
  OverlayTrigger,
  Table,
  Badge,
  Button,
  Container,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { formatDistanceToNow, format } from "date-fns";
import { FiAtSign, FiChevronDown } from "react-icons/fi";
import { AiOutlineMobile } from "react-icons/ai";
import { QUOTE_JOB } from "../redux/types";

const BusinessLeads = () => {
  const leads = useSelector((state) => state.user.profile.leads);
  const auth = useSelector((state) => state.user.profile);
  const dispatch = useDispatch();
  // const jobLocation = location => {
  //   const locationArray = location.split(" ");
  //   return `${locationArray[0]} ${locationArray[1]}, ${locationArray[2]}`;
  // };

  const tasks = () => {
    const taskToReturn = [];
    const industry = auth.business.industry;
    industry.forEach((parent) => {
      taskToReturn.push(parent.parentId);
      if (parent.categories) {
        parent.categories.forEach((sub) => {
          taskToReturn.push(sub.id);
        });
      }
    });
    return taskToReturn.length;
  };

  const quoteJob = (job) => {
    dispatch({ type: QUOTE_JOB, quoteJob: { status: true, job } });
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <div>
          <p style={{ marginBottom: "2px" }}>{leads.length} leads Available</p>
          <p className="bold" style={{ margin: 0 }}>
            You are matched to jobs {auth.business.radius} km from{" "}
            {`${auth.business.location.name} ${auth.business.location.state}, ${auth.business.location.postcode}`}{" "}
            for <Badge variant="primary">{tasks()} tasks</Badge>
          </p>
        </div>

        <div>{/* <Button variant="outline-primary">Edit</Button> */}</div>
      </div>
      <hr />
      {leads.length > 0 ? (
        leads.map((job, i) => {
          let jobIndustry = (
            <span style={{ textTransform: "capitalize", fontWeight: "bold" }}>
              {job.jobType.name}
            </span>
          );
          // console.log("jobIndustry", jobIndustry);
          return (
            <div>
              <Card
                key={job.id}
                style={{
                  marginBottom: "10px",
                  // width: "300px",
                  // background: "#ccc",
                  // minHeight: "300px",
                  // border: "none",
                  boxShadow: "-7px 10px 45px 0px rgba(182,167,167,0.37)",
                }}
              >
                <Card.Header
                  style={{
                    background: "transparent",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h5 style={{ margin: 0 }}>
                    {job.name}'s{" "}
                    <span style={{ textTransform: "capitalize" }}>
                      {jobIndustry}
                    </span>{" "}
                    job at{" "}
                    {`${job.location.name} ${job.location.state}, ${job.location.postcode}`}
                  </h5>
                  <Button
                    variant="outline-primary"
                    onClick={() => quoteJob(job)}
                  >
                    Quote
                  </Button>
                </Card.Header>

                <Card.Body>
                  <Row>
                    <Col xs={12} md={8}>
                      <div>
                        <p>{job.description}</p>
                      </div>
                      <Table
                        striped
                        bordered
                        hover
                        style={{ marginTop: "0.3rem" }}
                      >
                        <tbody>
                          <tr>
                            <td>
                              <FiAtSign />
                            </td>
                            <td>{job.email}</td>
                          </tr>
                          {job.mobile && (
                            <tr>
                              <td>
                                <AiOutlineMobile />
                              </td>

                              <td>+61 {job.mobile}</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>

                      {job.attachmentsUrl
                        ? job.attachmentsUrl.length > 0 && (
                            <div style={{ marginBottom: "1rem" }}>
                              <hr />
                              <p>Attachments</p>
                              {job.attachmentsUrl.map((attachment) => (
                                <img
                                  src={attachment.url}
                                  alt={attachment.name}
                                  style={{
                                    width: "80px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                  }}
                                />
                              ))}
                            </div>
                          )
                        : null}
                    </Col>
                    <Col xs={12} md={4}>
                      <ul>
                        <li>
                          Posted{" "}
                          {formatDistanceToNow(new Date(job.createdAt), {
                            addSuffix: true,
                          })}
                          {/* {format(new Date(job.createdAt), "do MMM, yy")} */}
                        </li>
                        <li>
                          {job.when.whenValue !== "date"
                            ? job.when.whenValue
                            : job.when.endDate
                            ? `${format(
                                new Date(job.when.startDate),
                                "do MMM, yy"
                              )} - ${format(
                                new Date(job.when.endDate),
                                "do MMM, yy"
                              )}`
                            : format(
                                new Date(job.when.startDate),
                                "do MMM, yy"
                              )}
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <Col xs={12}>
                    {auth.membership && auth.membership !== "pro" ? (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id={`${i}-topplacement`}>
                            Buy Membership
                          </Tooltip>
                        }
                      >
                        <Link
                          to="/dashboard/business/payments"
                          style={{ color: "#6c757d" }}
                        >
                          CONTACT CUSTOMER
                        </Link>
                      </OverlayTrigger>
                    ) : (
                      <Accordion>
                        <Accordion.Toggle
                          eventKey={i + 1}
                          as={Row}
                          varaint="link"
                          style={{
                            cursor: "pointer",
                            border: "none",
                            // borderBottom: "1px solid #ccc",
                            padding: "10px 0",
                            marginLeft: "0",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              // padding: "10px -15px",
                              // border: "1px solid #ccc",
                              borderRadius: "5px",
                            }}
                          >
                            <h6 style={{ margin: 0 }}>CONTACT CUSTOMER</h6>
                            <h6 style={{ margin: "0", marginLeft: "10px" }}>
                              <FiChevronDown />
                            </h6>
                          </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={i + 1}>
                          <Table
                            striped
                            bordered
                            hover
                            style={{ marginTop: "0.3rem" }}
                          >
                            <tbody>
                              <tr>
                                <td>
                                  <FiAtSign />
                                </td>
                                <td>{job.email}</td>
                              </tr>
                              {job.mobile && (
                                <tr>
                                  <td>
                                    <AiOutlineMobile />
                                  </td>

                                  <td>+61 {job.mobile}</td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </Accordion.Collapse>
                      </Accordion>
                    )}
                  </Col>
                </Card.Footer>
              </Card>
            </div>
          );
        })
      ) : (
        <p
          style={{
            fontSize: "1.5rem",
            fontWeight: "light",
            textAlign: "center",
            color: "#9e9696",
          }}
        >
          There are currently no active leads available in your surrounding
          area. Will notify you as soon as job is posted related to the services
          you provide.
        </p>
      )}
    </Container>
  );
};

export default BusinessLeads;
