import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import { Container, Col } from "react-bootstrap";
import SocialFollow from "../SocialFollow/SocialFollow";

const Footer = () => {
  return (
    <div className="footer">
      <Container>
        <Col xs={12}>
          <div className="footer-container">
            <div className="footer-content">
              <div className="contact">
                <Link to="/contact-us">Contact Us</Link>
                {/* <ul className>
                  <li>Adress:</li>
                  <li>Email:</li>
                  <li>Phone:</li>
                </ul> */}
              </div>
              <Link to="/about-us">About US</Link>

              <Link to="/privacy-policy">Privacy Policy</Link>
              <Link to="/terms-conditions">Terms & Conditions</Link>
            </div>

            <div className="copy-right">
              <div className="social">
                <SocialFollow />
              </div>
              <p>
                getfreequote &copy;{new Date().getFullYear()} Pyt Ltd. All
                Rights Reserved
              </p>
            </div>
          </div>
        </Col>
      </Container>
    </div>
  );
};

export default Footer;

/**
 <div>
                <h2>Customer</h2>
                <ul>
                  <li>
                    <Link to="#">Post a job</Link>
                  </li>
                  <li>
                    <Link to="#">Business Directory</Link>
                  </li>
                  <li>
                    <Link to="#">Find a business</Link>
                  </li>
                  <li>
                    <Link to="#">Help</Link>
                  </li>
                </ul>
              </div>
              <div>
                <h2>Business</h2>
                <ul>
                  <li>
                    <Link to="#">Register as Business</Link>
                  </li>
                  <li>
                    <Link to="#">Review Business</Link>
                  </li>
                </ul>
              </div>
              <div>
                <h2>Customer</h2>
                <ul>
                  <li>
                    <Link to="#">Sydney</Link>
                  </li>
                  <li>
                    <Link to="#">Melbourne</Link>
                  </li>
                  <li>
                    <Link to="#">Brisbane</Link>
                  </li>
                  <li>
                    <Link to="#">Adelade</Link>
                  </li>
                </ul>
              </div>
              <div>
                <h2>About Us</h2>
                <ul>
                  <li>
                    <Link to="#">About our Company</Link>
                  </li>
                  <li>
                    <Link to="#">Facebook</Link>
                  </li>
                  <li>
                    <Link to="#">Youtube</Link>
                  </li>
                  <li>
                    <Link to="#">Instagram</Link>
                  </li>
                </ul>
              </div>
 */
