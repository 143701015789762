import React, { useEffect, useState } from "react";
import { Container, Button } from "react-bootstrap";
// import firebase from "../firebase/firebase-config";
import { useSelector, useDispatch } from "react-redux";
import { SET_JOBS } from "../redux/types";
import JobCard from "../components/jobCard/JobCard";
import axios from "axios";
import { FiPlusSquare } from "react-icons/fi";
import { setActiveStep, setShowPostJob } from "../redux/actions/jobAction";
// import { Spinner, Collapse, Alert } from "react-bootstrap";

const UserJobs = () => {
  // const [jobs, setJobs] = useState([]);
  // const [jobs, setJobs] = useState(null);
  // const [jobsError, setJobsError] = useState(null);
  const jobs = useSelector(state => state.user.profile.jobs);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   axios
  //     .get("user-jobs")
  //     .then(res => {
  //       // setJobs(res.data);
  //       console.log("jobs in component", res.data);
  //       dispatch({ type: SET_JOBS, jobs: res.data });
  //     })
  //     .catch(err => {
  //       // setJobsError(err.message);
  //       console.log("user jobs error", err);
  //     });
  // }, []);

  const postJobStart = () => {
    // dispatch(setActiveStep(1));
    dispatch(setActiveStep(0));
    dispatch(setShowPostJob(true));
  };

  return (
    <Container>
      {!jobs || jobs.length <= 0 ? (
        <div
          style={{
            border: "3px dashed #ccc",
            borderRadius: "10px",
            minHeight: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h5 style={{ fontSize: "1.5rem", marginBottom: "1.5rem" }}>
            No Jobs
          </h5>
          {/* <h5
            
            style={{
              fontSize: "2rem",
              cursor: "pointer",
              margin: "0",
              display: "flex",
              alignItems: "center",
              minWidth: "150px",
              justifyContent: "space-between",
              color: 
            }}
          >
            
          </h5> */}
          <Button onClick={postJobStart}>
            {" "}
            <FiPlusSquare style={{ fontSize: "1.5rem" }} /> Post a Job
          </Button>
        </div>
      ) : (
        jobs.map(job => <JobCard key={job.id} job={job} />)
      )}
    </Container>
  );
};

export default UserJobs;
