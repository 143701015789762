import { updateUserBusiness, setAuthorizationHeader } from "./userAction";
import axios from "axios";
import { SET_AUTHENTICATED, CHANGE_RADIUS } from "../types";

export const signUpNewBusiness = (newBusiness, history) => dispatch => {
  axios
    .post("/register-business", newBusiness)
    .then(res => {
      setAuthorizationHeader(res.data.token);
      dispatch({
        type: SET_AUTHENTICATED,
        user: {
          user: res.data.userDetails,
          business: res.data.businessDetails,
        },
      });
      history.push("/dashboard/business-details");
      // dispatch(getUserData(history, "/dashboard/business-details"));
      // dispatch({ type: SIGNUP_BUSINESS_ERROR, error: null });
      // history.push("/dashboard");
    })
    .catch(err => {
      console.log("new business error", err);
      // dispatch({ type: SIGNUP_BUSINESS_ERROR, error: err.data });
    });
};

export const signUpBusinessForExistingUser = (
  user,
  businessDetails,
  history
) => {
  return async (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();

    const userBusiness = await firestore
      .collection("business")
      .doc(user.uid)
      .set({
        business_name: businessDetails.name,
        phone: businessDetails.phone,
        email: user.email,
      });

    // dispatch(updateUser)
    console.log(userBusiness);
  };
};

export const getBusinessDetails = id => {
  return async (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();

    firestore
      .collection("business")
      .doc(id)
      .get()
      .then(businessDetails => {
        dispatch(updateUserBusiness(businessDetails.data()));
      })
      .catch(error => {
        console.log("getBusinessDetails error", error);
      });
  };
};

export const updateBusiness = (businessDetails, user, history) => {
  return async (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();

    firestore
      .collection("business")
      .doc(user.id)
      .set(
        {
          ...businessDetails,
        },
        { merge: true }
      )
      .then(() => {
        dispatch(getBusinessDetails(user.id));
        history.push("/buy-member");
      })
      .catch(err => {
        console.log("updateUserBusiness err", err);
      });

    // dispatch(updateUser)
    // console.log(updatedBusinessDetails);
  };
};

export const changeRadius = radius => dispatch => {
  dispatch({
    type: CHANGE_RADIUS,
    radius,
  });
};
