import React from "react";
import IndividualJobs from "./IndividualJobs";

const OtherIndividualJobs = () => {
  return (
    <div style={{ minHeight: "90vh", margin: "3rem 0" }}>
      <IndividualJobs />
    </div>
  );
};

export default OtherIndividualJobs;
