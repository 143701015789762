import React from "react";
import { Card, Button, ButtonGroup } from "react-bootstrap";
import { format } from "date-fns";
// import { FiEdit } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { JOB_STATUS_UPDATE } from "../../redux/types";

const JobCard = ({ job }) => {
  const formattedDate = format(new Date(job.createdAt), "do MMM, yy");
  console.log("job", job);
  const dispatch = useDispatch();

  const setJobStatusUpdate = () => dispatch({ type: JOB_STATUS_UPDATE, job });

  return (
    <Card className="mb-2">
      <Card.Header
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Link to={`/dashboard/job/${job.id}`}>
          <h6 style={{ textTransform: "capitalize", margin: 0 }}>
            {job.jobType.name} | {job.location.name}
          </h6>
        </Link>
        <div style={{ display: "flex" }}>
          <p className="small" style={{ margin: 0 }}>
            {formattedDate}
          </p>
          {/* <FiEdit style={{ marginLeft: "10px" }} /> */}
        </div>
      </Card.Header>
      <Card.Body style={{ position: "relative" }}>
        <Card.Text>{job.description}</Card.Text>
        <Card.Text>{job.location.name}</Card.Text>
      </Card.Body>
      <Card.Footer>
        <ButtonGroup>
          <Button variant="info" size="sm" disabled>
            <span className="capitalize">{job.status}</span>
          </Button>
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={setJobStatusUpdate}
          >
            {/* <Link to={`/dashboard/job/${job.id}`}>Update Status</Link> */}
            Update Status
          </Button>
        </ButtonGroup>
      </Card.Footer>
    </Card>
  );
};

export default JobCard;
