export const getNearbyPostalCode = async (location, radius = 20000) => {
  let postal_code,
    i = 1,
    totalResults,
    placeHasPostalCodes = [],
    postcodes;

  try {
    const nearbysearch = await fetch(
      `https://api.tomtom.com/search/2/nearbySearch/.json?lat=${location.lat}&lon=${location.lng}&limit=100&countrySet=au&radius=${radius}&extendedPostalCodesFor=Geo&idxSet=Geo&key=UEEYtSd16asn5O7q75BUGXqM1asRFUgu`
    );
    const nearbySearchData = await nearbysearch.json();
    // console.log("nearbySearchData", nearbySearchData);
    postal_code = nearbySearchData.results;
    totalResults = nearbySearchData.summary.totalResults;

    // console.log('------------------------------------');
    while (postal_code && postal_code.length < totalResults) {
      const nearbySearchNext = await fetch(
        `https://api.tomtom.com/search/2/nearbySearch/.json?lat=${
          location.lat
        }&lon=${location.lng}&limit=100&ofs=${
          i * 100
        }&countrySet=au&radius=${radius}&extendedPostalCodesFor=Geo&idxSet=Geo&key=UEEYtSd16asn5O7q75BUGXqM1asRFUgu`
      );

      const nearbySearchDataNext = await nearbySearchNext.json();
      console.log("nearbySearchDataNext", nearbySearchDataNext);
      postal_code.push(...nearbySearchDataNext.results);
      i++;
    }
    // console.log('-----------------------------');
    placeHasPostalCodes = postal_code.filter(
      (place) => place.address.postalCode
    );

    postcodes = placeHasPostalCodes.map((place) => place.address.postalCode);
    // return postal_code;
  } catch (e) {
    console.error(e);
    // return e;
  }

  return postcodes;
};
