import React from "react";
import { Form, Col } from "react-bootstrap";
import { useField } from "formik";

const TextAreaInput = props => {
  const [field, meta] = useField(props);
  const { type, rows, placeholder, name } = props;
  return (
    <Form.Group as={Col} sm="12" id="jobDescriptionFormik">
      {props.label && <Form.Label>Quickly describe your job</Form.Label>}
      <Form.Control
        as={type}
        placeholder={placeholder}
        name={name}
        rows={rows}
        isInvalid={meta.touched && meta.error}
        id="jobDescription"
        {...field}
      />

      <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default TextAreaInput;
