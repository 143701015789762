import React, { useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Form, InputGroup, Spinner, Button } from "react-bootstrap";
import { FiEye, FiEyeOff } from "react-icons/fi";
// import { useDispatch } from "react-redux";
// import { signUpUser } from "../../redux/actions/userAction";
// import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

const SignUpForm = ({ handleSubmit, email, disabled }) => {
  const [show, setShow] = useState(false);
  // const phoneRegExp = /^((\\+[0-9]{1,3}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = yup.object().shape({
    name: yup.string().required("Required"),
    email: yup.string().email("Invaid Email").required("Required"),
    password: yup.string().required("Required"),
    confirmPassword: yup
      .string()
      .required("Required")
      .when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: yup
          .string()
          .oneOf([yup.ref("password")], "Doesn't match with password"),
      }),
    phone: yup
      .string()
      .min(9, "Invalid")
      .max(10, "Invalid")
      .matches(
        /^((\\+[0-9]{1,3}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Invalid"
      )
      .required("Required"),
    terms: yup.bool().required().oneOf([true], "Terms must be accepted"),
  });

  return (
    <Formik
      initialValues={{
        name: "",
        email: email ? email : "",
        password: "",
        confirmPassword: "",
        phone: "",
        terms: false,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        handleBlur,
        errors,
        touched,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>
              Name <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="name"
              placeholder="Your Name"
              onChange={handleChange}
              isValid={touched.name && !errors.name}
              isInvalid={touched.name && errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {errors.name}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              Email <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="Email"
              onChange={handleChange}
              isValid={touched.email && !errors.email}
              isInvalid={touched.email && errors.email}
              disabled={disabled}
              value={values.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              Password <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <InputGroup>
              <Form.Control
                type={show ? "text" : "password"}
                name="password"
                placeholder="Password"
                onChange={handleChange}
                isValid={touched.password && !errors.password}
                isInvalid={touched.password && errors.password}
              />
              <InputGroup.Append onClick={() => setShow(!show)}>
                <InputGroup.Text style={{ cursor: "pointer" }}>
                  {show ? <FiEye /> : <FiEyeOff />}
                </InputGroup.Text>
              </InputGroup.Append>
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              Confirm Password <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <InputGroup>
              <Form.Control
                type={show ? "text" : "password"}
                name="confirmPassword"
                placeholder="Confirm Password"
                onChange={handleChange}
                isInvalid={touched.confirmPassword && errors.confirmPassword}
                value={values.confirmPassword}
                onBlur={handleBlur}
              />
              <InputGroup.Append onClick={() => setShow(!show)}>
                <InputGroup.Text style={{ cursor: "pointer" }}>
                  {show ? <FiEye /> : <FiEyeOff />}
                </InputGroup.Text>
              </InputGroup.Append>
              <Form.Control.Feedback type="invalid">
                {errors.confirmPassword}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              Phone no <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>+61</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                name="phone"
                placeholder="Phone No"
                onChange={handleChange}
                handleBlur={handleBlur}
                isValid={touched.phone && !errors.phone}
                isInvalid={touched.phone && errors.phone}
              />
              <Form.Control.Feedback type="invalid">
                {errors.phone}
              </Form.Control.Feedback>
            </InputGroup>
            {!errors.phone && (
              <Form.Text className="text-muted">
                We'll never share your phone no with anyone else.
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group>
            <div className="d-flex justify-content-between">
              <Form.Check
                name="terms"
                // label="I agree to getfreequote's Terms & Conditions, Community Guidelines and Privacy Policy."
                onChange={handleChange}
                isInvalid={touched.terms && errors.terms}
                isValid={touched.terms && !errors.terms}
                // feedback={errors.terms}
                id="validationFormik0"
                value={values.terms}
                className="mr-2 mt-1"
              />
              <Form.Label>
                I agree to getfreequote's Terms & Conditions, Community
                Guidelines and Privacy Policy.
                <Link to="/terms-conditions"> Terms & Conditions</Link>
              </Form.Label>
            </div>
            <Form.Control.Feedback type="invalid" className="d-block">
              {errors.terms}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Button
              type="submit"
              className="btn btn-primary w-50 mt-2"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Sign Up"
              )}
            </Button>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
};

export default SignUpForm;
