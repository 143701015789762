import React, { useState, useEffect } from "react";
// import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import {
  setActiveStep,
  setUser,
  clearJobUser,
} from "../../../redux/actions/jobAction";
import { setAuthorizationHeader } from "../../../redux/actions/userAction";
import SubmitJob from "../submit-job/SubmitJob";
import CheckUser from "../../check_user/CheckUser";
import SignInOrSignUp from "../signinorsignup/SignInOrSignUp";
import FacebookLogin from "../../FacebookLogin";
import GoogleLogin from "../../GoogleLogin";
import firebase, {
  googleAuthProvider,
  facebookAuthProvider,
} from "../../../firebase/firebase-config";
import {
  SIGNIN_USER_ERROR,
  SET_AUTHENTICATED,
  SIGNUP_USER_ERROR,
} from "../../../redux/types";
import { Collapse, Alert, Row, Col } from "react-bootstrap";

const EmailOrSocialAuth = ({ localActiveStep, setLocalActiveStep }) => {
  const dispatch = useDispatch();
  const signInError = useSelector(state => state.user.signInError);

  const checkUserHandleSubmit = (values, actions) => {
    // console.log(values);
    console.log("contact deatils submit actions", actions);
    actions.setSubmitting(true);
    axios
      .post("/checkuser", {
        email: values.email,
      })
      .then(res => {
        // actions.setFieldValue("exist", true);
        console.log("check user response", res.data);
        dispatch(
          setUser({
            exists: true,
            email: values.email,
            provider: res.data.provider,
          })
        );
        actions.setSubmitting(false);
        // dispatch(setActiveStep(activeStep + 1));
        setLocalActiveStep(localActiveStep + 1);
      })
      .catch(err => {
        // actions.setFieldValue("exist", false);
        dispatch(setUser({ exists: false, email: values.email }));
        actions.setSubmitting(false);
        // dispatch(setActiveStep(activeStep + 1));
        setLocalActiveStep(localActiveStep + 1);
      });
  };

  const authGoogle = async () => {
    firebase
      .auth()
      .signInWithPopup(googleAuthProvider)
      .then(googleAuth => {
        console.log("google auth user token", googleAuth.user.getIdToken());
        return googleAuth.user.getIdToken();
        // return googleAuth.credential.accessToken;
      })
      .then(token => {
        // console.log("firebase google login res", token);
        // dispatch({ type: SIGNIN_USER_ERROR, error: null });
        // console.log("FBIdToken", res.data.token);
        setAuthorizationHeader(token);
        return axios.get("/user-details");
      })
      .then(userDetails => {
        dispatch({ type: SET_AUTHENTICATED, user: userDetails.data });
        setLocalActiveStep(localActiveStep + 1);
      })
      .catch(error => {
        // console.log("catch err", error);
        console.log("Google login error", error.message);
        dispatch({
          type: SIGNIN_USER_ERROR,
          error: "Unable to login via Google",
        });
      });
  };

  const facebookAuth = () => {
    console.log("Loggin or signingup with Facebook");
    dispatch({ type: SIGNIN_USER_ERROR, error: null });
    firebase
      .auth()
      .signInWithPopup(facebookAuthProvider)
      .then(facebookUser => {
        // console.log('facebook signin or sign up response', res)
        // const user = googleAuth.user
        // const fbAccessToken = facebookUser.credential.accessToken
        // return facbookAuth.user.getIdToken()
        return facebookUser.credential.accessToken;
      })
      .then(accessToken => {
        return axios.get(`/login/social/${accessToken}`);
      })
      .then(res => {
        console.log("firebase facebook login res", res);
        setAuthorizationHeader(res.data.token);
        return axios.get("/user-details");
      })
      .then(userDetails => {
        dispatch({ type: SET_AUTHENTICATED, user: userDetails.data });
        setLocalActiveStep(localActiveStep + 1);
      })
      .catch(error => {
        console.log("facebook login error", error);
        dispatch({ type: SIGNIN_USER_ERROR, error: error.message });
      });
  };

  return (
    <>
      <h3 className="text-center mb-3">
        Where would you like your quotes sent
      </h3>
      {signInError && (
        <Collapse in={signInError}>
          <Alert variant="danger">{signInError}</Alert>
        </Collapse>
      )}
      <CheckUser
        handleSubmit={checkUserHandleSubmit}
        helperText="Posted a job before? We recommend using the same email address."
      />
      <span
        className="divider"
        style={{
          marginBottom: "1rem",
          fontStyle: "italic",
          textAlign: "justify",
        }}
      >
        OR
      </span>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridGap: "10px",
          marginBottom: "1rem",
        }}
      >
        <FacebookLogin handleSubmit={facebookAuth} />
        <GoogleLogin handleSubmit={authGoogle} />
      </div>
    </>
  );
};

const ContactDetails = props => {
  const dispatch = useDispatch();
  const [localActiveStep, setLocalActiveStep] = useState(0);
  const user = useSelector(state => state.user.profile);
  const jobUser = useSelector(state => state.job.user);
  const activeStep = useSelector(state => state.job.activeStep);
  const signInUserError = useSelector(state => state.user.signInError);
  const signUpError = useSelector(state => state.user.signUperror);

  useEffect(() => {
    if (user) {
      setLocalActiveStep(2);
    }

    return () => {
      dispatch({
        type: SIGNIN_USER_ERROR,
        error: null,
      });
      dispatch({ type: SIGNUP_USER_ERROR, error: null });
    };
  }, []);

  const getContent = activeStep => {
    switch (activeStep) {
      case 0:
        return (
          <EmailOrSocialAuth
            localActiveStep={localActiveStep}
            setLocalActiveStep={setLocalActiveStep}
          />
        );
      case 1:
        return (
          <SignInOrSignUp
            localActiveStep={localActiveStep}
            setLocalActiveStep={setLocalActiveStep}
          />
        );
      case 2:
        return <SubmitJob />;
      default:
        return null;
    }
  };

  const handleBack = () => {
    dispatch(setActiveStep(activeStep - 1));
    dispatch(clearJobUser());
  };

  return (
    <>
      <Row>
        <Col sm={6} md={2}>
          <span
            onClick={handleBack}
            style={{ marginRight: "2rem", cursor: "pointer" }}
          >
            👈
          </span>
        </Col>
        {jobUser.exists !== null && (
          <Col sm={6} md={{ span: 4, offset: 3 }}>
            <h5>{jobUser.exists ? "Login" : "Sign Up"} to proceed</h5>
          </Col>
        )}
        {(signInUserError || signUpError) && (
          <div
            style={{
              display: "grid",
              placeItems: "center",
              marginTop: "10px",
              width: "100%",
            }}
          >
            {signInUserError && (
              <Alert className="text-center mb-0" variant="danger">
                {signInUserError}
              </Alert>
            )}
            {signUpError && (
              <Alert className="text-center mb-0" variant="danger">
                {signUpError}
              </Alert>
            )}
          </div>
        )}
      </Row>
      <hr />
      {user ? getContent(localActiveStep) : getContent(localActiveStep)}
    </>
  );
};

export default ContactDetails;
