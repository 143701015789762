import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { Spinner, Container } from "react-bootstrap";
import "./jobtypecategories.css";
import { Link } from "react-router-dom";
import InfiniteCarousel from "react-leaf-carousel";
import { GrNext, GrPrevious } from "react-icons/gr";
import { Fade } from "react-reveal";
import { Helmet } from "react-helmet";
import startOfYesterday from "date-fns/esm/startOfYesterday/index";
import startOfMinute from "date-fns/startOfMinute";
import Categories from "../components/categories/Categories";

const JobTypeCategories = () => {
  // const params = useParams();
  // const { name } = useParams();

  const { state } = useLocation();
  const [jobType, setJobType] = useState();
  const [popularCategories, setPopularCategories] = useState();
  // const jobTypeName = params.name.replace("+", " ");
  // console.log("name and id", name, state);
  // console.log("params", params);
  useEffect(() => {
    getCategories();
    getJobType();
  }, []);

  const getJobType = async () => {
    console.log(window.location.href);
    if (state) {
      const res = await axios.get(`/jobType/${state.id}`);
      setJobType(res.data);
    }
  };

  const getCategories = () => {
    axios
      .get("popular-categories")
      .then((res) => {
        setPopularCategories(res.data);
        const urlArr = window.location.href.split("/");
        const jobName = urlArr[urlArr.length - 1]
          .replace("-", " ")
          .replace("-", " ")
          .replace("-", " ")
          .replace("-", " ");

        console.log("location state", state);
        res.data.forEach((e) => {
          if (e.job == jobName) {
            const res = axios.get(`/jobType/${e.id}`).then((resThis) => {
              setJobType(resThis.data);
            });
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div style={{ minHeight: "60vh" }}>
      {jobType ? (
        <>
          <Helmet>
            <title>{jobType.title}</title>
            <meta name="description" content={jobType.metaDescription} />
          </Helmet>
          <div
            className="category__featured"
            style={{
              backgroundImage: jobType.featuredImg
                ? `url(${jobType.featuredImg})`
                : null,
            }}
          >
            <Fade top>
              <h1>{jobType.name}</h1>
              <Link to="/register-business">Register</Link>
            </Fade>
          </div>

          {jobType.description && (
            <Fade left>
              <Container className="description_container">
                <div
                  className="description"
                  dangerouslySetInnerHTML={{ __html: jobType.description }}
                ></div>
              </Container>
            </Fade>
          )}

          {jobType.images && (
            <Fade right>
              <div className="images">
                <InfiniteCarousel
                  breakpoints={[
                    {
                      breakpoint: 500,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                      },
                    },
                    {
                      breakpoint: 768,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                      },
                    },
                  ]}
                  dots={true}
                  showSides={false}
                  // sidesOpacity={0.5}
                  // sideSize={2}
                  slidesToScroll={1}
                  slidesToShow={2}
                  scrollOnDevice={true}
                  lazyLoad={false}
                  swipe={true}
                  autoCycle={false}
                  nextArrow={
                    <div
                      style={{
                        borderRadius: "50%",
                        background: "rgba(255,255,255, 0.5)",
                        position: "absolute",
                        padding: "0.5rem",
                        top: "45%",
                        right: "1rem",
                        zIndex: "10",
                        cursor: "pointer",
                      }}
                    >
                      <GrNext
                        style={{
                          // borderRadius: "50%",
                          // background: "rgba(0,0,0, 0.2)",
                          fontSize: "1.5rem",
                          color: "#fff",
                          pointer: "cursor",
                        }}
                      />
                    </div>
                  }
                  prevArrow={
                    <div
                      style={{
                        borderRadius: "50%",
                        background: "rgba(255,255,255, 0.5)",
                        position: "absolute",
                        padding: "0.5rem",
                        top: "45%",
                        left: "1rem",
                        zIndex: "10",
                        cursor: "pointer",
                      }}
                    >
                      <GrPrevious
                        style={{
                          // padding: "2rem",
                          // borderRadius: "50%",
                          // background: "rgba(0,0,0, 0.2)",
                          fontSize: "1.5rem",
                          color: "#fff",
                          pointer: "cursor",
                        }}
                      />
                    </div>
                  }
                  // style={{ padding: "1rem" }}
                >
                  {jobType.images.map((image) => (
                    <div key={image}>
                      <img src={image} alt={`${jobType.name} images`} />
                    </div>
                  ))}
                </InfiniteCarousel>
              </div>
            </Fade>
          )}

          <Fade left>
            <Container>
              <div
                className="category__content"
                dangerouslySetInnerHTML={{ __html: jobType.content }}
              ></div>
            </Container>
          </Fade>
        </>
      ) : (
        <Spinner animation="border" />
      )}
    </div>
  );
};

export default JobTypeCategories;
