import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  setJob,
  setLocation,
  setActiveStep,
  setShowPostJob,
} from "../../redux/actions/jobAction";
import { Formik } from "formik";
import * as yup from "yup";
import { Form } from "react-bootstrap";
import PlaceAutocomplete from "../google-map/PlaceAutocomplete";
import JobTypeAutocomplete from "../Jobs/JobType/JobTypeAutocomplete";
// import SuburbAutocomplete from "../suburb_autocomplete/SuburbAutocomplete";
// import JobLocation from "../Jobs/JobLocation/JobLocation";

const GetQuoteForm = ({
  activeStep,
  // setTypeAndLocation,
  setJob,
  setLocation,
  setActiveStep,
  setShowPostJob,
  job,
  location,
}) => {
  const validationSchema = yup.object().shape({
    job: yup
      .object()
      .required("Required")
      .shape({
        id: yup.string().required("Required"),
        name: yup.string().required("Required"),
      })
      .nullable(),
    location: yup
      .object()
      .required()
      .shape({
        name: yup.string().required("Required"),
        lat: yup.number().required("Required"),
        lng: yup.number().required("Required"),
        postcode: yup.string().required("Invalid Suburb"),
      })
      .nullable(),
  });

  const formikInitialValues = {
    job,
    location,
  };

  console.log("location value in getquoteform", location);
  console.log("formik initialValues", formikInitialValues);

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);
    // e.preventDefault();
    console.log("values", values);
    setJob(values.job);
    setLocation(values.location);
    actions.setSubmitting(false);
    actions.resetForm();
    setActiveStep(1);
    setShowPostJob(true);
    // }
  };

  return (
    <>
      <Formik
        initialValues={formikInitialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        id="getquoteform"
        // enableReinitialize={true}
      >
        {({
          handleChange,
          handleSubmit,
          touched,
          errors,
          setFieldValue,
          setErrors,
          values,
          isSubmitting,
        }) => (
          <Form
            className="get-quote-form row"
            onSubmit={handleSubmit}
            noValidation
          >
            {console.log("getquoteform errors", errors)}
            {console.log("getquoteform values", values)}
            <div className="col-md-5" style={{ marginBottom: "-15px" }}>
              <JobTypeAutocomplete
                setValue={setFieldValue}
                // value={values.job}
                // onChange={handleChange}
                // onChange={(name, e) => setFieldValue(name, e.target.value)}
                name="job"
                // errors={errors.job}
                // touched={touched.job}
                values={values}
              />
            </div>
            <div className="col-md-5">
              {/* <JobLocation label={false} /> */}
              <PlaceAutocomplete
                setValue={setFieldValue}
                name="location"
                values={values}
                setErrors={setErrors}
              />
              {/* <SuburbAutocomplete
                name="location"
                values={values}
                setValue={setFieldValue}
              /> */}
            </div>
            <div className="col-md-2">
              <button
                type="submit"
                className="btn btn-primary w-100"
                disabled={errors && isSubmitting}
              >
                Get Quote
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    activeStep: state.job.activeStep,
    job: state.job.job,
    location: state.job.location,
    showPostJob: state.job.showPostJob,
  };
};

const mapDispatchToProps = {
  setJob,
  setLocation,
  setActiveStep,
  setShowPostJob,
};

export default connect(mapStateToProps, mapDispatchToProps)(GetQuoteForm);
