import React from "react";
import { GoogleMap, Circle, LoadScript } from "@react-google-maps/api";
// import { useSelector } from "react-redux";
// import { Form } from "react-bootstrap";

const containerStyle = {
  width: "100%",
  height: "100%",
  marginTop: "1rem",
  marginBottom: "1rem",
};

const MapComponent = ({ center, radius, zoom, isLoaded, loadError }) => {
  // const [center, setCenter] = useState(businessLocation);
  // const [radius, setRadius] = useState(0);

  // useEffect(() => {
  //   if (businessLocation) {
  //     setRadius(10000);
  //   }
  // }, [businessLocation]);

  console.log(radius);

  const options = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: radius,
    zIndex: 1,
  };

  if (loadError) return "Error Loading Maps";
  if (!isLoaded) return "Loading Maps";

  const onLoad = marker => console.log("marker: ", marker);

  // console.log(locationInputRef.current.value);

  return (
    // <LoadScript googleMapsApiKey="AIzaSyCw5Y2wT558IzK6svOI2qK8RYjHyIndFqs">
    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={zoom}>
      {/* <Marker
        onLoad={onLoad}
        position={{ lat: center.lat, lng: center.lng }}
      ></Marker> */}

      {/* <Marker position={center} /> */}
      <Circle center={center} options={options} onLoad={onLoad} />
    </GoogleMap>
    // </LoadScript>
  );
};

export default MapComponent;
