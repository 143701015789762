import React, { useState, useEffect } from "react";
// import categoryImg from "../../assets/img/categories-img.png";
import "./categories.css";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// import TabPanel from '@material-ui/core/TabPanel';
import Box from "@material-ui/core/Box";
import { Container, Spinner, Col, Row } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import { FacebookProvider, Page } from "react-facebook";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
      className="tabPanel"
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const Categories = () => {
  const [tabValue, setTabValue] = useState(0);
  const [popularCategories, setPopularCategories] = useState();
  useEffect(() => {
    getCategories();
  }, []);

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  const getCategories = () => {
    axios
      .get("popular-categories")
      .then((res) => {
        setPopularCategories(res.data);
        console.log("categories", res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Container>
      <section className="popular_categories">
        <h2 className="section-header">Popular Categories</h2>
        <Row>
          <Col md={12} sm={12}>
            {/* <div>
          <img src={categoryImg} alt="categories" />
        </div> */}
            <div>
              <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label="Sydney" />
                <Tab label="Melbourne" />
                <Tab label="Brisbane" />
                <Tab label="Perth" />
                {/* <Tab label="Canberra" /> */}
              </Tabs>
              <TabPanel value={tabValue} index={0}>
                <div className="tabPanel-content">
                  {popularCategories ? (
                    popularCategories
                      .filter((category) => category.state === "NSW")
                      .slice(0, 21)
                      .map((nswCat) => {
                        // console.log("nswCat", nswCat);
                        const jobName = nswCat.job
                          .replace(" ", "-")
                          .replace(" ", "-")
                          .replace(" ", "-")
                          .replace(" ", "-");
                        return (
                          <div>
                            {/* <Badge
                            variant="secondary"
                            pill
                            style={{ margin: "5px", padding: "5px" }}
                          > */}
                            <Link
                              to={{
                                pathname: `/jobType/${jobName}`,
                                state: { id: nswCat.id },
                              }}
                              key={`${nswCat.id}`}
                              style={{
                                padding: "18px",
                                display: "inline-block",
                                // pointerEvents: "none",
                              }}
                            >
                              {nswCat.job}
                            </Link>
                            {/* </Badge> */}
                          </div>
                        );
                      })
                  ) : (
                    <Spinner animation="border" />
                  )}
                </div>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <div className="tabPanel-content">
                  {popularCategories &&
                    popularCategories
                      .filter((category) => category.state === "VIC")
                      .slice(0, 21)
                      .map((nswCat) => {
                        const jobName = nswCat.job
                          .replace(" ", "_")
                          .replace(" ", "_")
                          .replace(" ", "_");
                        return (
                          <div>
                            <Link
                              to={{
                                pathname: `/jobType/${jobName}`,
                                state: { id: nswCat.id },
                              }}
                              key={`${nswCat.id}`}
                              style={{
                                padding: "18px",
                                display: "inline-block",
                                // pointerEvents: "none",
                              }}
                            >
                              {nswCat.job}
                            </Link>
                          </div>
                        );
                      })}
                </div>
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <div className="tabPanel-content">
                  {popularCategories &&
                    popularCategories
                      .filter((category) => category.state === "QLD")
                      .map((nswCat) => {
                        const jobName = nswCat.job
                          .replace(" ", "_")
                          .replace(" ", "_")
                          .replace(" ", "_");
                        return (
                          <div>
                            <Link
                              to={{
                                pathname: `/jobType/${jobName}`,
                                state: { id: nswCat.id },
                              }}
                              key={`${nswCat.id}`}
                              style={{
                                padding: "18px",
                                display: "inline-block",
                                // pointerEvents: "none",
                              }}
                            >
                              {nswCat.job}
                            </Link>
                          </div>
                        );
                      })}
                </div>
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <div className="tabPanel-content">
                  {popularCategories &&
                    popularCategories
                      .filter((category) => category.state === "WA")
                      .map((nswCat) => {
                        const jobName = nswCat.job
                          .replace(" ", "_")
                          .replace(" ", "_")
                          .replace(" ", "_");
                        return (
                          <div>
                            <Link
                              to={{
                                pathname: `/jobType/${jobName}`,
                                state: { id: nswCat.id },
                              }}
                              key={`${nswCat.id}`}
                              style={{
                                padding: "18px",
                                display: "inline-block",
                                // pointerEvents: "none",
                              }}
                            >
                              {nswCat.job}
                            </Link>
                          </div>
                        );
                      })}
                </div>
              </TabPanel>
            </div>
          </Col>
          {/* <Col
            md={4}
            sm={12}
            style={{
              // margin: "auto 0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "3.5rem",
            }}
          >
            <FacebookProvider appId="358728251855957">
              <Page
                href="https://www.facebook.com/getfreequotes"
                tabs="timeline"
                style={{ width: "100%" }}
              />
            </FacebookProvider>
          </Col> */}
        </Row>
      </section>
    </Container>
  );
};

export default Categories;
