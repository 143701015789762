import React, { useState } from "react";
import {
  Container,
  Col,
  Row,
  Form,
  Button,
  Spinner,
  Alert,
} from "react-bootstrap";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { FacebookProvider, Page } from "react-facebook";
import classes from "./ContactUs.module.css";
import { Helmet } from "react-helmet";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const ContactUs = () => {
  const [message, setMessage] = useState();
  const [error, setError] = useState();

  const handleSubmit = (values, { setSubmitting }) => {
    setError();
    setMessage();
    axios
      .post("/contact-admin", {
        name: values.name,
        email: values.email,
        message: values.message,
      })
      .then(() => {
        setMessage(
          "Thank you for contacting us. We'll get back you very soon."
        );
        setSubmitting(false);
      })
      .catch((err) => {
        setError("Please try again");
        setSubmitting(false);
      });
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required("Required"),
    email: yup.string().required("Required").email("Invalid Email"),
    message: yup.string().required("Required"),
  });

  return (
    <>
      <div id="contact-feature" className="feature">
        <Helmet>
          <title>GetFreeQuote - Contact us</title>
          <meta
            name="description"
            content="Getfreequote is a leading company in Australia that provides an active marketplace for people looking for jobs. For more info, explore now!"
          />
        </Helmet>
        <div className="overlay grid-center">
          <h1>Contact Us</h1>
        </div>
      </div>
      <Container fluid>
        <Row className={classes.row1}>
          <Col md={6} sm={12} style={{ padding: "3rem" }}>
            {error && <Alert variant="danger">{error}</Alert>}
            {message && <Alert variant="success">{message}</Alert>}
            <div>
              <h1>Have an enquiry?</h1>
              <p>
                Send us a message using the form next and we’ll get back to you
                as soon as possible.
              </p>
            </div>
          </Col>
          <Col className={classes.col1} md={6} sm={12}>
            <Formik
              onSubmit={handleSubmit}
              initialValues={{ email: "", message: "", name: "" }}
              validationSchema={validationSchema}
            >
              {({
                handleSubmit,
                errors,
                handleChange,
                touched,
                isSubmitting,
                values,
              }) => (
                <Form onSubmit={handleSubmit}>
                  {console.log("contact form values", values)}
                  {console.log("contact form errors", errors)}
                  <Form.Group>
                    <Form.Label>
                      Your Name <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      onChange={handleChange}
                      name="name"
                      isInvalid={touched.name && errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      Your Email <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      onChange={handleChange}
                      name="email"
                      isInvalid={touched.email && errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      Your Message <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      placeholder="Your message"
                      onChange={handleChange}
                      name="message"
                      isInvalid={touched.message && errors.message}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Button type="submit" disabled={isSubmitting}>
                      {isSubmitting ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </Form.Group>
                </Form>
              )}
            </Formik>
            {/* <div className="fb">
              <FacebookProvider appId="358728251855957">
                <Page
                  href="https://www.facebook.com/getfreequotes"
                  tabs="timeline"
                  style={{ width: "100%" }}
                />
              </FacebookProvider>
            </div> */}
          </Col>
        </Row>
        <Row className={classes.row2} style={{ minHeight: "55vh" }}>
          <Col className={classes.col2} md={6} sm={12}>
            <div>
              <h1>Our Location</h1>
              <p>Monday to Friday - 9am to 5pm (AEST)</p>
              <ul>
                <li>Address: 53/11-21 Underwood Rd, Homebush NSW 2140</li>
                <li>
                  Email:&nbsp;
                  <a href="mailto:info@qetfreequote.com.au">
                    info@qetfreequote.com.au
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col md={6} sm={12} style={{ paddingRight: "3rem" }}>
            <GoogleMap
              mapContainerStyle={containerStyle}
              zoom={15}
              center={{ lat: -33.856, lng: 151.078 }}
            >
              <Marker position={{ lat: -33.856, lng: 151.078 }} />
            </GoogleMap>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContactUs;
