import {
  JOB,
  LOCATION,
  WHEN,
  PRIORITY,
  STEP,
  SHOW_POSTJOB,
  DESCRIPTION,
  ATTACHMENT,
  JOB_SUBMIT_SUCCESS,
  JOB_SUBMIT_ERROR,
  RESET_JOB,
  SET_USER,
  CLEAR_USER,
  REMOVE_ATTACHMENT,
} from "../types";

export const resetJob = () => dispatch => {
  dispatch({ type: RESET_JOB });
};

export const setShowPostJob = postJobState => dispatch => {
  dispatch({
    type: SHOW_POSTJOB,
    payload: postJobState,
  });
};

export const setActiveStep = activeStep => dispatch => {
  dispatch({ type: STEP, payload: activeStep });
};

export const setJob = job => dispatch => {
  dispatch({
    type: JOB,
    payload: job,
  });
};

export const setLocation = location => dispatch => {
  // console.log("location", location);
  dispatch({
    type: LOCATION,
    payload: location,
  });
};

export const setDescription = description => dispatch => {
  dispatch({ type: DESCRIPTION, description });
};

export const setAttachment = attachment => dispatch => {
  dispatch({ type: ATTACHMENT, attachment });
};

export const setWhen = when => dispatch => {
  dispatch({ type: WHEN, payload: when });
};

export const setPriority = priority => dispatch => {
  dispatch({ type: PRIORITY, payload: priority });
};

export const setUser = user => dispatch => {
  dispatch({
    type: SET_USER,
    user,
  });
};

export const clearJobUser = () => dispatch => {
  dispatch({
    type: CLEAR_USER,
  });
};

export const removeAttachment = attachment_name => dispatch => {
  dispatch({
    type: REMOVE_ATTACHMENT,
    attachment_name,
  });
};
