import React from "react";
// Redux Stuff
import { connect } from "react-redux";
import {
  setActiveStep,
  setDescription,
  setAttachment,
  removeAttachment,
} from "../../../redux/actions/jobAction";
// import * as Yup from "yup";
import { Form, Button, Badge } from "react-bootstrap";
import FileUpload from "./FileUpload";
import TextAreaInput from "../../forms/FormFields/TextAreaInput";
import { Formik } from "formik";
import * as yup from "yup";
import { FiXCircle } from "react-icons/fi";

const JobDescription = props => {
  const {
    attachments,
    setDescription,
    setActiveStep,
    description,
    activeStep,
    removeAttachment,
  } = props;

  const formikInitialValues = {
    description,
    attachments,
  };

  const validationSchema = yup.object().shape({
    description: yup
      .string()
      .required("Required")
      .min(20, "Minimum 20 Characters"),
  });

  const handleSubmit = (values, actions) => {
    console.log("about job values", values);
    setDescription(values.description);
    // setLocation(values.location);
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <Formik
      initialValues={formikInitialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      // enableReinitialize={true}
    >
      {({ handleSubmit, setFieldError, setFieldValue, errors, touched }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Row>
            <TextAreaInput
              type="textarea"
              placeholder="Short description of you job...min 20 characters"
              name="description"
              rows="4"
              label="Quickly describe your job"
              inValid={touched.description && errors.description}
            />
          </Form.Row>
          <Form.Row>
            <FileUpload
              name="attachments"
              setValue={setFieldValue}
              setFieldError={setFieldError}
              maxFileSize={5}
            />
            <p
              className="text-muted"
              style={{ marginTop: "-.8rem", fontSize: ".8rem" }}
            >
              Supported Format : (jpeg, jpng, png). Max FileSize: 5 Mb
            </p>
          </Form.Row>

          {attachments && (
            <Form.Row style={{ marginTop: "-0.5rem" }}>
              <Form.Group>
                <div
                  className="attachments"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  {attachments.map((attachment, i) => (
                    // <button
                    //   style={{
                    //     display: "flex",
                    //     alignItems: "center",
                    //     justifyContent: "space-between",
                    //     marginRight: "5px",
                    //     padding: "5px 10px",
                    //     borderRadius: "10px",
                    //     border: "none",
                    //     backgroundColor: "#E9ECEF",
                    //     fontSize: "0.6rem",
                    //   }}
                    //   key={attachment.name}
                    //   type="button"
                    // >
                    //   {attachment.name}
                    //   <span style={{ marginLeft: "10px" }}>x</span>
                    // </button>
                    <Badge
                      pill
                      variant="secondary"
                      className="p-2 ml-1"
                      // onClick={() => removeAttachment(attachment.name)}
                    >
                      {attachment.name}
                      {/* <span style={{ marginLeft: "15px", cursor: "pointer" }}>
                        x
                      </span> */}
                      <FiXCircle
                        style={{
                          marginLeft: "15px",
                          cursor: "pointer",
                          fontSize: "1rem",
                        }}
                        onClick={() => removeAttachment(attachment.name)}
                      />
                    </Badge>
                  ))}
                </div>
              </Form.Group>
            </Form.Row>
          )}
          <Form.Row
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button type="button" onClick={handleBack}>
              Back
            </Button>
            <Button type="submit">Continue</Button>
          </Form.Row>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = state => ({
  activeStep: state.job.activeStep,
  description: state.job.description,
  attachments: state.job.attachments,
});

const mapDispatchToProps = {
  setActiveStep,
  setDescription,
  setAttachment,
  removeAttachment,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobDescription);
