import React from "react";
import "./subscription.css";
import { FiCheckSquare, FiCheckCircle } from "react-icons/fi";
import Badge from "react-bootstrap/Badge";
import { changeRadius } from "../../redux/actions/businessActions";
import { useDispatch } from "react-redux";

const SubscriptionItems = ({ prices, activeSubscription }) => {
  const dispatch = useDispatch();
  return (
    <>
      <div className="subscription__items">
        {prices.map(item => (
          <div
            key={item.id}
            className={`${
              activeSubscription && item.id === activeSubscription.id
                ? "active shadow"
                : ""
            } subscription__item `}
            onClick={() =>
              dispatch(changeRadius(parseInt(item.metadata.max_radius)))
            }
          >
            {/* <p className="subscription__name">{item.nickname}</p> */}
            <div className="subscription__heading">
              <p className="mb-0">
                ${item.unit_amount / 100}{" "}
                <span style={{ fontSize: "1rem" }}>monthly</span>
              </p>
              <h5 className="mb-0">
                <Badge pill variant="primary" style={{ marginTop: ".3rem" }}>
                  Upto {item.metadata.max_radius}km radius
                </Badge>
              </h5>
            </div>
            {activeSubscription && item.id === activeSubscription.id ? (
              <FiCheckCircle className="active_icon" />
            ) : null}
          </div>
        ))}
      </div>
      {/* <hr />
      <div className="premium_privileges">
        <p>
          <FiCheckSquare />
          <span>Unlimited quoting on leads</span>
        </p>
        <p>
          <FiCheckSquare />
          Email and Messages notifications
        </p>
        <p>
          <FiCheckSquare />
          Recieve leads with mobile number
        </p>
      </div> */}
    </>
  );
};

export default SubscriptionItems;
