import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Dropdown,
  Button,
  Accordion,
  Card,
  Spinner,
  Container,
  Row,
  Col,
  Table,
  Badge,
} from "react-bootstrap";
import { FiChevronDown } from "react-icons/fi";
import { firestore } from "../../firebase/firebase-config";
import { format } from "date-fns";
// import { setJob } from "../../redux/actions/jobAction";
// import JobWhen from "../Jobs/job-when/JobWhen";
const IndividualJobs = () => {
  const params = useParams();
  // console.log("params id", typeof params.id);
  const user = useSelector(state => state.user.profile);

  // const [quotes, setQuotes] = useState(null);
  const [job, setJob] = useState();
  // console.log("job", job);

  const getJob = async () => {
    const jobRef = await firestore.collection("jobs").doc(params.id).get();
    const quotesRef = await firestore
      .collection("quotes")
      .where("job.id", "==", params.id)
      .get();

    const quotes = quotesRef.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    setJob({ id: jobRef.id, ...jobRef.data(), quotes });
  };

  useEffect(() => {
    // getQuote();
    window.scrollTo(0, 0);
    getJob();
    return () => {
      // setQuotes(null);
      setJob(null);
    };
  }, []);

  console.log("individaul jobs", job);
  return (
    <Container>
      {!job ? (
        <Spinner animation="border" size="sm" />
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4 style={{ margin: 0 }}>
              {job.jobType.name} in {job.location.name}
            </h4>
            {user && user.id === job.author ? (
              <Dropdown
                style={{
                  backgroundColor: "transparent",
                  border: "1px solid #007bff",
                  color: "#007bff",
                  borderRadius: "10px",
                }}
              >
                <Dropdown.Toggle as={Badge} style={{ padding: "10px 20px" }}>
                  <span className="capitalize fs-3 mr-2">{job.status}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  style={{ textAlign: "center", top: "10px !important" }}
                >
                  <span>Cancel</span>
                </Dropdown.Menu>
              </Dropdown>
            ) : null}
          </div>
          <hr style={{ marginTop: "1rem" }} />
          <Accordion className="mt-2">
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                variant="link"
                eventKey={job.id}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span>Job Details</span>
                  <FiChevronDown />
                </div>
              </Accordion.Toggle>

              <Accordion.Collapse eventKey={job.id}>
                <Card.Body>
                  <Table striped hover>
                    <tbody>
                      <tr>
                        <td>Posted by</td>
                        <td>{job.name}</td>
                      </tr>
                      <tr>
                        <td>Posted on</td>
                        <td>{format(new Date(job.createdAt), "do MMM, yy")}</td>
                      </tr>
                      <tr>
                        <td>To be finished by</td>
                        <td>
                          {job.when.whenValue !== "date"
                            ? job.when.whenValue
                            : !job.when.endDate
                            ? format(
                                new Date(job.when.startDate),
                                "do, MMM, yy"
                              )
                            : `${format(
                                new Date(job.when.startDate),
                                "do, MMM, yy"
                              )} - ${format(
                                new Date(job.when.endDate),
                                "do, MMM, yy"
                              )}`}
                        </td>
                      </tr>

                      <tr>
                        <td>Location</td>
                        <td>{job.location.name}</td>
                      </tr>
                      <tr>
                        <td colSpan="2">{job.description}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>

          {user && user.id === job.author
            ? job.status !== "cancelled" && (
                <Card className="mt-3 shadow-sm text-center">
                  <Card.Body>
                    <Card.Title className="mb-4">WHAT'S NEXT</Card.Title>
                    <Card.Text className="text-muted">
                      Find a business you want to hire ? Click "Choose this
                      business" under their quote.
                    </Card.Text>
                    <Card.Text className="text-muted">
                      If your job is done, leave a star rating by clicking "Rate
                      this business"
                    </Card.Text>
                  </Card.Body>
                </Card>
              )
            : null}

          {user && user.id === job.author ? (
            job.quotes.length > 0 ? (
              job.quotes.map(quote => (
                <Card key={quote.id} className="mt-2 mb-2">
                  <Card.Header>
                    <h6>
                      {quote.business.businessName} |{" "}
                      <span className="text-muted">0 Reviews</span>{" "}
                    </h6>
                  </Card.Header>
                  <Card.Body>
                    <Container>
                      <Row>
                        <Col sm={12} md={4}>
                          <h5>{quote.business.businessName}</h5>
                          <p>{quote.business.mobile}</p>
                        </Col>
                        <Col sm={12} md={8}>
                          {quote.message.replace(
                            /@user_name/g,
                            quote.job.userName
                          )}
                          <div className="d-flex flex-row  mt-3">
                            <Button
                              variant="outline-primary"
                              className="rounded-pill mr-3"
                            >
                              Choose This Business
                            </Button>
                            <Button
                              variant="outline-primary"
                              className="rounded-pill"
                            >
                              Rate This Business
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              ))
            ) : (
              <h5 className="text-center mt-3 mb-3">
                We'll notify as soon as business quotes for your job.
              </h5>
            )
          ) : null}
        </>
      )}
    </Container>
  );
};

export default IndividualJobs;
