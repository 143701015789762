import React, { useState, useEffect } from "react";
// import "./post-job.css";
// Redux Stuff
import { connect, useSelector, useDispatch } from "react-redux";
import {
  setActiveStep,
  setShowPostJob,
  resetJob,
} from "../../redux/actions/jobAction";
// Components
import AboutJob from "../Jobs/about-job/AboutJob";
import JobDescription from "../Jobs/job-description/JobDescription";
import JobWhen from "../Jobs/job-when/JobWhen";
import SubmitJob from "../Jobs/submit-job/SubmitJob";
// import JobPriority from "../Jobs/job-priority/JobPriority";
import ContactDetails from "../Jobs/contact-details/ContactDetails";
import { Formik, Form, useFormikContext } from "formik";
import { FiX } from "react-icons/fi";
import JobValidationSchema from "../forms/FormModel/JobValidationSchema";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { useHistory } from "react-router-dom";
import {
  getUserData,
  setAuthorizationHeader,
} from "../../redux/actions/userAction";

const CloseAndResetPostJob = ({ showDialog, setShowDialog }) => {
  // const { setSubmitting } = useFormikContext();
  const dispatch = useDispatch();
  // const [confirm, setConfirm] = useState(showDialog);

  return (
    <>
      {showDialog && (
        <div
          className="card"
          style={{
            position: "absolute",
            top: "10px",
            left: "5%",
            padding: "20px",
            width: "90%",
            background: "grey",
            // height: "500px",
            zIndex: "400",
            transition: "height .5s",
            display: showDialog ? "block" : "none",
          }}
        >
          <p style={{ padding: "10px 0", color: "white" }}>
            Are you sure you want to exit ? Your Job won't be saved.
          </p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button
              className="btn btn-info"
              variant="contained"
              type="button"
              onClick={() => {
                setShowDialog(false);
                // setSubmitting(false);
              }}
            >
              Stay and Continue
            </button>
            <button
              className="btn btn-danger"
              type="button"
              onClick={() => {
                // resetForm({});
                // setConfirm(fal)
                dispatch(resetJob());
                setShowDialog(false);
                dispatch(setShowPostJob(false));

                // setConfirm(false);
                // setSubmitting(true);
              }}
            >
              Exit
            </button>
          </div>
        </div>
      )}

      {/* <span
        className="modal__close"
        onClick={() => {
          // resetForm();
          // dispatch(setShowPostJob(false));
          setConfirm(true);
          resetJob();
          setShowPostJob(false);
          // setSubmitting(true);
        }}
        // onClick={resetJob()}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          cursor: "pointer",
          display: "inline-block",
          fontSize: "1.3rem",
        }}
      >
        <FiX />
      </span> */}
    </>
  );
};

const PostJob = ({
  activeStep,
  user,
  setActiveStep,
  setShowPostJob,
  job,
  location,
  resetJob,
  showPostJob,
}) => {
  const steps = ["About Job", "Details", "When", "Contact Details"];

  const [showDialog, setShowDialog] = useState(false);

  const getContent = activeStep => {
    if (user) {
      switch (activeStep) {
        case 0:
          return <AboutJob />;
        case 1:
          return <JobDescription />;
        case 2:
          return <JobWhen />;
        case 3:
          return <SubmitJob />;
        case 4:
          return <SubmitJob />;
        default:
          return null;
      }
    } else {
      switch (activeStep) {
        case 0:
          return <AboutJob />;
        case 1:
          return <JobDescription />;
        case 2:
          return <JobWhen />;
        case 3:
          return <ContactDetails />;
        case 4:
          return <SubmitJob />;
        default:
          return null;
      }
    }
  };

  const hideModal = () => {
    setShowDialog(true);
  };

  return (
    <Modal
      show={showPostJob}
      style={{ zIndex: "100 !important" }}
      size="lg"
      onHide={hideModal}
      backdrop="static"
      keyboard={false}
    >
      <CloseAndResetPostJob
        showDialog={showDialog}
        setShowDialog={setShowDialog}
      />
      <Modal.Header closeButton>
        <Modal.Title style={{ width: "100%" }}>
          <div
            className="steps col-sm-12"
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "0.8rem",
            }}
          >
            {steps.map((step, index) => {
              return (
                <div
                  style={{
                    borderBottom:
                      activeStep === index
                        ? "4px solid #04BFB3"
                        : "4px solid gray",
                    color: activeStep === index ? "#04BFB3" : "gray",
                    padding: "10px 0",
                    marginBottom: "15px",
                    width: "100%",
                    textAlign: "center",
                    margin: "0 5px",
                  }}
                  key={index + 1}
                >
                  {step}
                </div>
              );
            })}
          </div>
        </Modal.Title>
      </Modal.Header>
      <div className="post-job container" style={{ padding: "20px" }}>
        <div>{getContent(activeStep)}</div>
      </div>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    job: state.job.job,
    location: state.job.location,
    activeStep: state.job.activeStep,
    user: state.user.profile,
    showPostJob: state.job.showPostJob,
  };
};

export default connect(mapStateToProps, {
  setActiveStep,
  setShowPostJob,
  resetJob,
})(PostJob);
