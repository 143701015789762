import React, { useState } from "react";
import { Form, Button, Alert, Container, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import "./forgot-password.css";

const ForgotPassword = () => {
  const [message, setMessage] = useState();
  const [error, setError] = useState();
  const validationSchema = yup.object().shape({
    email: yup.string().required("Email Required").email("Invalid Email"),
  });

  const handleSubmit = async (values, actions) => {
    setError();
    setMessage();
    await axios
      .post("/reset-password", { email: values.email })
      .then((res) => {
        setMessage("An email to reset your password has been sent!");
        actions.setSubmitting(false);
        // console.log("forgot password res", res.data);
      })
      .catch((e) => {
        // setError(e.message.error);
        if (e.response.data.code === "auth/user-not-found") {
          setError("No user of below email.");
        } else {
          setError(e.response.data.message);
        }
        actions.setSubmitting(false);
        // console.log("forgot password error", e.response.data);
      });
  };

  return (
    <Container style={{ height: "90vh" }}>
      <div className="forgot-password-wrapper">
        <div className="shadow-sm forgot-password-box">
          {message && <Alert variant="success">{message}</Alert>}
          {error && <Alert variant="danger">{error}</Alert>}
          <Formik
            onSubmit={handleSubmit}
            initialValues={{ email: "" }}
            validationSchema={validationSchema}
          >
            {({
              handleSubmit,
              handleChange,
              touched,
              errors,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    onChange={handleChange}
                    isInvalid={touched.email && errors.email}
                    placeholder="Email"
                  />
                </Form.Group>
                <Form.Group>
                  <Button type="submit" disabled={errors.email}>
                    {isSubmitting ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Reset Password"
                    )}
                  </Button>
                </Form.Group>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Container>
  );
};

export default ForgotPassword;
