import React, { useState } from "react";
import { FcGoogle } from "react-icons/fc";
import firebase, { googleAuthProvider } from "../firebase/firebase-config";
// import { connect } from "react-redux";
// import { setUser } from "../redux/actions/userAction";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getUserData,
  setAuthorizationHeader,
} from "../redux/actions/userAction";
import { SIGNIN_USER_ERROR } from "../redux/types";
import { Spinner } from "react-bootstrap";

const GoogleLogin = ({ handleSubmit, destination }) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const authWithGoogle = () => {
    console.log("Logging in with gmail or Google...");
    googleAuthProvider.addScope("profile");
    googleAuthProvider.addScope("email");
    setLoading(true);
    firebase
      .auth()
      .signInWithPopup(googleAuthProvider)
      .then(googleAuth => {
        return googleAuth.user.getIdToken();
      })
      .then(token => {
        dispatch({ type: SIGNIN_USER_ERROR, error: null });
        setAuthorizationHeader(token);
        setLoading(false);
        destination
          ? dispatch(getUserData(history, destination))
          : dispatch(getUserData(history));
      })
      .catch(error => {
        // console.log("catch err", error);
        console.log("Google login error", error);
        dispatch({ type: SIGNIN_USER_ERROR, error: error.message });
        loading(false);
      });
  };

  return (
    <button
      className="google-login"
      onClick={handleSubmit ? handleSubmit : authWithGoogle}
    >
      {!loading ? (
        <>
          <FcGoogle /> <span>Sign in with Google</span>
        </>
      ) : (
        <Spinner animation="border" size="sm" />
      )}
    </button>
  );
};

// export default connect(null, { setUser })(GoogleLogin);
export default GoogleLogin;
