import React, { useEffect, useState } from "react";
import Autocomplete from "./Autocomplete";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSearch } from "@fortawesome/free-solid-svg-icons";
// import algoliasearch from "algoliasearch";
import "./jobtype.css";
import axios from "axios";

const JobTypeAutomcomplete = (props) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    axios
      .get("/jobs-type")
      .then((res) => {
        console.log("jobs response: ", res);
        // res.docs.forEach(doc => {
        //   let docObject = { id: doc.id, ...doc.data() };
        //   dataArray.push(docObject);
        // });
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      setData(null);
    };
  }, []);

  // const submitHandle = () => {};

  return <Autocomplete data={data} {...props} />;
};

export default JobTypeAutomcomplete;
