import React from "react";
import { useSelector, useDispatch } from "react-redux";
import SignInForm from "../../sign-in/SignInForm";
import SignUpForm from "../../signup/SignUpForm";
// import FacebookLogin from "../../FacebookLogin";
// import GoogleLogin from "../../GoogleLogin";
import {
  SIGNIN_USER_ERROR,
  SET_AUTHENTICATED,
  SIGNUP_USER_ERROR,
} from "../../../redux/types";
import { setAuthorizationHeader } from "../../../redux/actions/userAction";
import axios from "axios";
// import { Link } from "react-router-dom";

const SignInOrSignUp = ({ localActiveStep, setLocalActiveStep }) => {
  const jobUser = useSelector(state => state.job.user);
  const dispatch = useDispatch();

  const signInEmailPassword = (values, actions) => {
    dispatch({ type: SIGNIN_USER_ERROR, error: null });
    actions.setSubmitting(true);
    axios
      .post("/login", { email: values.email, password: values.password })
      .then(res => {
        setAuthorizationHeader(res.data.token);
        return axios.get("/user-details");
      })
      .then(userDetails => {
        dispatch({ type: SET_AUTHENTICATED, user: userDetails.data });
        setLocalActiveStep(localActiveStep + 1);
      })
      .catch(err => {
        // console.log("signin user error", err.response);
        dispatch({
          type: SIGNIN_USER_ERROR,
          error: "Username/Password not found. Please try again",
        });
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const signUpEmailPassword = (values, actions) => {
    console.log("signup local values", values);
    dispatch({ type: SIGNUP_USER_ERROR, error: null });
    actions.setSubmitting(true);
    axios
      .post("/signup", {
        name: values.name,
        email: values.email,
        password: values.password,
        confirmPassword: values.confirmPassword,
        phone: values.phone,
      })
      .then(res => {
        setAuthorizationHeader(res.data.token);
        return axios.get("/user-details");
        // history.push("/");
      })
      .then(userDetails => {
        dispatch({ type: SET_AUTHENTICATED, user: userDetails.data });
        setLocalActiveStep(localActiveStep + 1);
      })
      .catch(err => {
        console.log("sign up error", err);
        dispatch({
          type: SIGNUP_USER_ERROR,
          error: "Wrong Credentials, Please try again",
        });
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <>
      {}
      {jobUser.exists ? (
        <SignInForm
          handleSubmit={signInEmailPassword}
          email={jobUser.email}
          disabled={true}
        />
      ) : (
        <SignUpForm
          handleSubmit={signUpEmailPassword}
          email={jobUser.email}
          disabled={true}
        />
      )}
      {/* <span
        className="divider"
        style={{
          marginBottom: "1rem",
          fontStyle: "italic",
          textAlign: "justify",
        }}
      >
        OR
      </span>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridGap: "10px",
          marginBottom: "1rem",
        }}
      >
        <FacebookLogin />
        <GoogleLogin />
      </div> */}
      {/* <Link to="forgot-password">Forgot password</Link> */}
    </>
  );
};

export default SignInOrSignUp;
