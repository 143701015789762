import React from "react";
import {
  Row,
  Tab,
  Col,
  Nav,
  Form,
  Button,
  Container,
  Alert,
  Card,
  Accordion,
} from "react-bootstrap";
// import { FiSmartphone, FiUser, FiNavigation, FiAtSign } from "react-icons/fi";
import {
  BiUserCircle,
  BiMobile,
  BiTargetLock,
  BiMap,
  BiCalendar,
} from "react-icons/bi";
import { useSelector } from "react-redux";
import { format, formatDistanceToNow } from "date-fns";

const Profile = () => {
  const user = useSelector(state => state.user.profile);
  const business = user.business;
  return (
    <Container>
      <Row>
        <Col sm={12} md={4}>
          <div
            style={{ padding: "1rem 2rem", borderRadius: "10px" }}
            className="shadow-sm border"
          >
            {/* <div
              id="cover-img"
              className=""
              style={{
                height: "200px",
                background: "#eee",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            ></div> */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {business.logo ? (
                <img
                  id="logo"
                  src={business.logo.url}
                  alt="business logo"
                  style={{
                    // width: "100px",
                    height: "130px",
                    objectFit: "cover",
                    borderRadius: "10px",
                    border: "3px solid #ccc",
                    // marginTop: "-60px",
                    margin: "0 auto 1.5rem auto",
                    width: "100%",
                  }}
                />
              ) : (
                <div
                  style={{
                    display: "grid",
                    placeItems: "center",
                    height: "130px",
                    width: "130px",
                    background: "#144870",
                    color: "#fff",
                    borderRadius: "10px",
                    border: "3px solid #ccc",
                    margin: "0 auto 1.5rem auto",
                  }}
                >
                  Logo
                </div>
              )}
            </div>
            <div style={{ width: "100%" }} className="text-truncate">
              <h5 className="mb-3 text-truncate">{business.businessName}</h5>
              <Alert
                variant="primary"
                className="d-flex justify-content-start align-items-center mb-2"
              >
                <h6 className="mb-0">
                  <BiMobile /> <span className="ml-3">{business.mobile}</span>
                </h6>
              </Alert>
              <hr />
              <h6
              // style={{ fontWeight: "bold" }}
              // style={{
              //   marginBottom: ".5rem",
              //   display: "flex",
              //   justifyContent: "space-around",
              // }}
              >
                <BiUserCircle
                  style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                />{" "}
                <span className="ml-3">{user.name}</span>
              </h6>
              <h6
              // style={{
              //   marginBottom: ".5rem",
              //   display: "flex",
              //   justifyContent: "space-around",
              // }}
              >
                <BiMap style={{ fontSize: "1.5rem", fontWeight: "bold" }} />{" "}
                <span className="ml-3">{business.location.name}</span>
              </h6>
              <h6
              // style={{
              //   marginBottom: ".5rem",
              //   display: "flex",
              //   justifyContent: "space-around",
              // }}
              >
                <BiTargetLock
                  style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                />{" "}
                <span className="ml-3">{business.location.state}</span>
              </h6>
              <h6>
                <BiCalendar
                  style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                />
                <span className="ml-3">
                  Member since {format(new Date(user.createdAt), "yyyy")}
                </span>
              </h6>
            </div>
          </div>
        </Col>
        <Col sm={12} md={8}>
          <Tab.Container id="left-tabs-example" defaultActiveKey="about">
            <Row>
              <Col sm={12} className="mb-3">
                <Nav variant="pills">
                  <Nav.Item>
                    <Nav.Link eventKey="about">About Us</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="services">Services</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    {business.reviews.length === 0 ? (
                      <Nav.Link eventKey="disabled" disabled>
                        Reviews ({business.reviews.length})
                      </Nav.Link>
                    ) : (
                      <Nav.Link eventKey="reviews">
                        Reviews ({business.reviews.length})
                      </Nav.Link>
                    )}
                  </Nav.Item>
                  <Nav.Item>
                    {business.gallery && business.gallery.length > 0 ? (
                      <Nav.Link eventKey="gallery">
                        Gallery (
                        {business.gallery ? business.gallery.length : "0"})
                      </Nav.Link>
                    ) : (
                      <Nav.Link eventKey="disabled" disabled>
                        Gallery (0)
                      </Nav.Link>
                    )}
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={12}>
                <Tab.Content className="text-wrap">
                  <Tab.Pane eventKey="about" className="w-100 text-wrap">
                    <div className="p-3 border rounded-lg font-weight-bold">
                      <div className="d-flex justify-content-between border-bottom ">
                        <p>Quoted on</p>
                        <p>
                          {business.quotes.length > 0
                            ? business.quotes.length
                            : 0}{" "}
                          jobs
                        </p>
                      </div>
                      <div className="d-flex justify-content-between pt-3">
                        <p className="mb-0">Last Quoted </p>
                        <p className="mb-0">
                          {business.quotes.length > 0
                            ? formatDistanceToNow(
                                new Date(business.quotes[0].createdAt),
                                {
                                  addSuffix: true,
                                }
                              )
                            : "No quotes yet"}
                        </p>
                      </div>
                      {/* <div className="d-flex justify-content-between pt-3">
                        <p className="mb-0">Member Since </p>
                        <p className="mb-0">
                          {format(new Date(user.createdAt), "yyyy")}
                        </p>
                      </div> */}
                    </div>
                    <p
                      className="text-wrap mt-4"
                      style={{
                        overflowWrap: "break-word",
                        borderLeft: "5px solid #0FB2E8",
                        paddingLeft: "1rem",
                      }}
                    >
                      {business.description}
                    </p>
                  </Tab.Pane>
                  <Tab.Pane eventKey="services">
                    {business.industry.map((jobType, i) => (
                      <Accordion defaultActiveKey={i}>
                        <Card>
                          <Accordion.Toggle as={Card.Header} eventKey={i}>
                            <h6 className="mb-0">{jobType.name}</h6>
                          </Accordion.Toggle>
                          {jobType.categories && (
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <div className="d-flex align-items-center">
                                  {jobType.categories.map(type => (
                                    <p>{type.name}</p>
                                  ))}
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          )}
                        </Card>
                      </Accordion>
                    ))}
                  </Tab.Pane>
                  <Tab.Pane eventKey="reviews">
                    {business.reviews.length <= 0
                      ? "No Reviews at the moment"
                      : "Show the reviews"}
                  </Tab.Pane>
                  <Tab.Pane eventKey="gallery">
                    {business.gallery &&
                      business.gallery.map(gallery => (
                        <p>Gallery photo comes heres</p>
                      ))}
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Col>
      </Row>
    </Container>
  );
};

export default Profile;
