import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Form,
  Button,
  Spinner,
  Table,
  Card,
  Alert,
  Badge,
  Modal,
} from "react-bootstrap";
import { Formik } from "formik";
import PlaceAutocomplete from "../google-map/PlaceAutocomplete";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { format } from "date-fns";
import { useToasts } from "react-toast-notifications";
import {
  UPDATE_BUSINESS_DETAILS,
  UPDATE_CONTACT_METHOD,
  UPDATE_MEMBERSHIP,
  UPDATE_MEMBERSHIP_STATUS,
} from "../../redux/types";
import { BiInfoCircle } from "react-icons/bi";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// export const stripePromise = loadStripe(
//   "pk_live_51IpkqAJf3ioUV8IXu6IzU0DlKJHaRFvQ7oGXCLwbuUcPNfgphOZTjxIjyq12buwgEHrTFWXNAHjCHrZ5ReKjZRbO00ucchd0i0"
// );

export const stripePromise = loadStripe(
  "pk_test_51IpkqAJf3ioUV8IXN0UiDJY9viZCZ8TjAUIcryuF4WopUWPtLX1JjS3QyyMUC6yut4RhqoGZ6rPpnSXGcsKcghvT00TutiHoVu"
);

const BusinessSettings = () => {
  const user = useSelector(state => state.user.profile);
  const business = user.business;
  const membership = user.membership;
  const membershipStatus = user.status;
  const [stripeCustomer, setStripeCustomer] = useState();
  const [card, setCard] = useState();
  const [cancelLoading, setCancelLoading] = useState(false);
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const stripeOptions = {
    style: {
      base: {
        color: "#424770",
        letterSpacing: "0.025em",
        fontFamily: "Source Code Pro, monospace",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };

  const businessValidationSchema = yup.object().shape({
    business_name: yup.string().required("Required"),
    location: yup
      .object()
      .required("Required")
      .shape({
        name: yup.string().required("Required"),
        lat: yup.number().required("Required"),
        lng: yup.number().required("Required"),
        postcode: yup.string().required("Required"),
      }),
    description: yup
      .string()
      .required("Required")
      .min("20", "Minimum of 20 characters"),
  });

  const contactMethodValidationSchema = yup.object().shape({
    contact_method: yup.string().required("Required"),
  });

  const businessDetailsSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      await axios.post("/update-details", {
        businessName: values.business_name,
        location: values.location,
        description: values.description,
      });
      dispatch({
        type: UPDATE_BUSINESS_DETAILS,
        businessDetails: {
          businessName: values.business_name,
          location: values.location,
          description: values.description,
        },
      });
      addToast("Business details updated successfully", {
        appearance: "success",
      });
    } catch (e) {
      addToast(
        "There is problem updateding business details. Please try again",
        { appearance: "error" }
      );
    } finally {
      setSubmitting(false);
    }
  };

  const contactDetailsSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      await axios.post("/contact-method", {
        contactMethod: values.contact_method,
      });
      dispatch({
        type: UPDATE_CONTACT_METHOD,
        contactMethod: values.contact_method,
      });
      addToast("Updated successfully", {
        appearance: "success",
      });
    } catch (e) {
      addToast(
        "There is problem updateding contact methods. Please try again",
        { appearance: "error" }
      );
    } finally {
      setSubmitting(false);
    }
  };

  const cancelSubscription = async () => {
    setCancelLoading(true);
    try {
      await axios.post("/subscriptionsCancel", {
        id: stripeCustomer.subscriptions.data[0].id,
      });
      dispatch({ type: UPDATE_MEMBERSHIP_STATUS, status: "cancelled" });
      addToast("Subscirption canceled successfully", { appearance: "success" });
    } catch (e) {
      addToast(
        "Unable to cancel subscription. Please try again later or contact us at info@getfreequote.com.au",
        { appearance: "error" }
      );
    } finally {
      setCancelLoading(false);
    }
  };

  const getStripeCustomer = async () => {
    try {
      const customer = await axios.get("/stripe-customer");
      setStripeCustomer(customer.data);
      console.log("stripe customer", customer.data);
    } catch (e) {
      console.log("stripe customer error", e);
    }
  };

  const getCardDetails = async () => {
    try {
      const cardDetails = await axios.get("/card-details");
      setCard(cardDetails.data);
      // console.log("active price", cardDetails.data);
    } catch (e) {
      console.log("card details error", e);
    }
  };

  const reactivateSubscription = async () => {
    setCancelLoading(true);
    try {
      await axios.post("/reactivate-subscription", {
        id: stripeCustomer.subscriptions.data[0].id,
        subId: stripeCustomer.subscriptions.data[0].items.data[0].id,
        price: stripeCustomer.subscriptions.data[0].items.data[0].price.id,
      });
      dispatch({ type: UPDATE_MEMBERSHIP_STATUS, status: "active" });
      addToast("Subscirption reactivated successfully", {
        appearance: "success",
      });
    } catch (e) {
      addToast(
        "Unable to cancel subscription. Please try again later or contact us at info@getfreequote.com.au",
        { appearance: "error" }
      );
    } finally {
      setCancelLoading(false);
    }
  };

  useEffect(() => {
    if (user.activePlans) {
      // console.log("business has actve Plans");
      getStripeCustomer();
      getCardDetails();
    }
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    // const cardElement = elements.getElement(CardElement);
    const cardElement = elements.getElement(CardNumberElement);
    // Create Payment Method
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (paymentMethod) {
      console.log("payment method", paymentMethod);
      try {
        await axios.post("/card", {
          source: paymentMethod.id,
        });
        await getCardDetails();
      } catch (error) {
        console.log("add card error", error);
      } finally {
        setLoading(false);
        setModal(false);
        addToast("successfully added your new card", { appearance: "success" });
      }
    } else {
      console.log("payment method error", error);
      addToast("Not able to add card. Please try again.", {
        appearance: "error",
      });
    }
  };

  return (
    <Container className="mb-3">
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row>
          <Col sm={12} className="border-bottom pb-2">
            <Nav variant="pills" className="flex-row">
              <Nav.Item>
                <Nav.Link eventKey="first">Business Settings</Nav.Link>
              </Nav.Item>
              {business && membership === "pro" && (
                <Nav.Item>
                  <Nav.Link eventKey="second">Manage Subscription</Nav.Link>
                </Nav.Item>
              )}
              {/* <Nav.Item>
                <Nav.Link eventKey="third">Leads & Radius</Nav.Link>
              </Nav.Item> */}
            </Nav>
          </Col>
          <Col sm={12} className="border-left mt-3">
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <Col sm={12} md={9}>
                  <h5 className="mb-4">Business Settings</h5>
                  <Formik
                    id="settings"
                    initialValues={{
                      location: business.location,
                      business_name: business.businessName,
                      description: business.description,
                    }}
                    validationSchema={businessValidationSchema}
                    onSubmit={businessDetailsSubmit}
                    // enableReinitialize
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      values,
                      setFieldValue,
                      touched,
                      errors,
                      isSubmitting,
                    }) => (
                      <Form onSubmit={handleSubmit} id="settings_form">
                        <Form.Group>
                          <Form.Label>Business Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="business_name"
                            onChange={handleChange}
                            placeholder="e.g Home Cleaners Inc"
                            isInvalid={
                              touched.business_name && errors.business_name
                            }
                            value={values.business_name}
                          />
                          <Form.Text className="text-muted">
                            Make sure your business name is correctly entered.
                            It helps boost your profile in search results
                          </Form.Text>
                          <Form.Control.Feedback type="invalid">
                            {errors.business_name}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>Suburb</Form.Label>
                          <PlaceAutocomplete
                            setValue={setFieldValue}
                            name="location"
                            values={values}
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>Description</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={6}
                            placeholder="Describe your business. Minimum of 20 characters."
                            name="description"
                            onChange={handleChange}
                            isInvalid={
                              touched.description && errors.description
                            }
                            value={values.description}
                          />
                          <Form.Text className="text-muted">
                            Tell customers about your business
                          </Form.Text>
                          <Form.Control.Feedback type="invalid">
                            {errors.description}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Button
                          type="submit"
                          className="w-25"
                          variant="outline-primary"
                        >
                          {isSubmitting ? (
                            <Spinner animation="border" size="sm" />
                          ) : (
                            "Save"
                          )}
                        </Button>
                      </Form>
                    )}
                  </Formik>
                  <hr />
                  <h5>
                    How would you like to recieve notificatons for your leads
                  </h5>
                  <Formik
                    id="contact"
                    initialValues={{ contact_method: business.contactMethod }}
                    validationSchema={contactMethodValidationSchema}
                    onSubmit={contactDetailsSubmit}
                    // enableReinitialize
                  >
                    {({ handleSubmit, handleChange, values, isSubmitting }) => (
                      <Form
                        onSubmit={handleSubmit}
                        className="mt-3"
                        id="contact_form"
                      >
                        {console.log("contactMethod values", values)}

                        <Form.Check
                          inline
                          custom
                          type="radio"
                          label="Email"
                          name="contact_method"
                          onChange={handleChange}
                          id="email"
                          value="email"
                          checked={values.contact_method === "email"}
                        />

                        <Form.Check
                          inline
                          custom
                          type="radio"
                          label="SMS"
                          name="contact_method"
                          onChange={handleChange}
                          id="sms"
                          value="sms"
                          checked={values.contact_method === "sms"}
                        />

                        <Form.Check
                          inline
                          custom
                          type="radio"
                          label="Both"
                          name="contact_method"
                          onChange={handleChange}
                          id="both"
                          value="both"
                          checked={values.contact_method === "both"}
                        />

                        <Button
                          type="submit"
                          className="mt-4 w-25 d-block"
                          variant="outline-primary"
                        >
                          {isSubmitting ? (
                            <Spinner animation="border" size="sm" />
                          ) : (
                            "Save"
                          )}
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </Col>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <Col sm={12} md={9}>
                  {stripeCustomer && card ? (
                    <Card>
                      <Card.Header>
                        <div className="d-flex justify-content-between align-items-center">
                          <h5 className="mb-0">Subscription</h5>
                          {membershipStatus === "active" ? (
                            <Badge variant="primary" className="text-uppercase">
                              {membershipStatus}
                            </Badge>
                          ) : (
                            <Badge variant="danger" className="text-uppercase">
                              {membershipStatus}
                            </Badge>
                          )}
                        </div>
                      </Card.Header>
                      <Card.Body>
                        <Table bordered>
                          <tbody>
                            <tr>
                              <th>Current Plan</th>
                              <td>
                                {
                                  stripeCustomer.subscriptions.data[0].plan
                                    .nickname
                                }{" "}
                                around {business.location.name}
                              </td>
                            </tr>
                            <tr>
                              <th>Next Payment</th>
                              <td>
                                {format(
                                  new Date(
                                    stripeCustomer.subscriptions.data[0]
                                      .current_period_end * 1000
                                  ),
                                  "MM/dd/yy"
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                        {membershipStatus === "active" && (
                          <>
                            <Alert variant="warning">
                              <BiInfoCircle /> You'll able to quote and recieve
                              leads until next payment date.
                            </Alert>
                            <Button
                              type="button"
                              variant="outline-danger"
                              className="w-25 mb-1"
                              onClick={cancelSubscription}
                            >
                              {cancelLoading ? (
                                <Spinner animation="border" size="sm" />
                              ) : (
                                "Unsubscribe"
                              )}
                            </Button>
                          </>
                        )}
                        {membershipStatus === "cancelled" && (
                          <Button
                            type="button"
                            variant="outline-primary"
                            className="w-25 mb-1"
                            onClick={reactivateSubscription}
                          >
                            {cancelLoading ? (
                              <Spinner animation="border" size="sm" />
                            ) : (
                              "Reactivate"
                            )}
                          </Button>
                        )}

                        <hr />
                        <h5 className="mb-3">Payment Source</h5>
                        {card &&
                          card.data.map(item => (
                            <Alert
                              variant="primary"
                              key={item.id}
                              className="d-flex justify-content-between"
                            >
                              <div>
                                <span style={{ textTransform: "capitalize" }}>
                                  {item.card.brand}
                                </span>{" "}
                                Card ************{item.card.last4}
                              </div>
                              <div>
                                Exp: {item.card.exp_month} /{" "}
                                {item.card.exp_year}
                              </div>
                            </Alert>
                          ))}
                        <Button
                          variant="outline-primary"
                          className="w-25"
                          onClick={() => setModal(true)}
                        >
                          Add Card
                        </Button>
                      </Card.Body>
                    </Card>
                  ) : (
                    <Spinner animation="border" size="sm" />
                  )}
                </Col>
              </Tab.Pane>
              {/* <Tab.Pane eventKey="third">Leads and Radius</Tab.Pane> */}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
      {modal && (
        <Modal size="sm" show={modal} onHide={() => setModal(false)}>
          <Modal.Header closeButton className="bg-primary text-white">
            <Modal.Title>Add Card</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Elements stripe={stripePromise}> */}
            {/* <SubscribeToPlan /> */}

            {modal && (
              <Form
                onSubmit={handleSubmit}
                // className="well"
                // hidden={!price}
                //  hidden={!subscription || subscription !== chosenSubscription.id}
                // style={{ maxWidth: "400px" }}
              >
                <label className="stripe">
                  Card number
                  <CardNumberElement
                    options={stripeOptions}

                    // onReady={() => {
                    //   console.log("CardNumberElement [ready]");
                    // }}
                    // onChange={event => {
                    //   console.log("CardNumberElement [change]", event);
                    // }}
                    // onBlur={() => {
                    //   console.log("CardNumberElement [blur]");
                    // }}
                    // onFocus={() => {
                    //   console.log("CardNumberElement [focus]");
                    // }}
                  />
                </label>
                <label className="stripe">
                  Expiration date
                  <CardExpiryElement
                    options={stripeOptions}
                    // onReady={() => {
                    //   console.log("CardNumberElement [ready]");
                    // }}
                    // onChange={event => {
                    //   console.log("CardNumberElement [change]", event);
                    // }}
                    // onBlur={() => {
                    //   console.log("CardNumberElement [blur]");
                    // }}
                    // onFocus={() => {
                    //   console.log("CardNumberElement [focus]");
                    // }}
                  />
                </label>
                <label className="stripe">
                  CVC
                  <CardCvcElement
                    options={stripeOptions}
                    // onReady={() => {
                    //   console.log("CardNumberElement [ready]");
                    // }}
                    // onChange={event => {
                    //   console.log("CardNumberElement [change]", event);
                    // }}
                    // onBlur={() => {
                    //   console.log("CardNumberElement [blur]");
                    // }}
                    // onFocus={() => {
                    //   console.log("CardNumberElement [focus]");
                    // }}
                  />
                </label>
                <Button
                  className="mt-3 w-50"
                  variant="outline-primary"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Add Card"
                  )}
                </Button>
              </Form>
            )}
            {/* </Elements> */}
          </Modal.Body>
        </Modal>
      )}
    </Container>
  );
};

// export default BusinessSettings;

const AddCard = () => {
  return (
    <Elements stripe={stripePromise}>
      <BusinessSettings />
    </Elements>
  );
};

export default AddCard;
