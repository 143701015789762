import React from "react";
import { Col } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
// import { FiUser } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
// import userAvatar from "../../assets/img/user-avatar.png";
import { Button, Image } from "react-bootstrap";
import { setActiveStep, setShowPostJob } from "../../redux/actions/jobAction";

const Sidebar = () => {
  const user = useSelector(state => state.user.profile);
  const dispatch = useDispatch();
  // const location = useLocation();
  // console.log("location", location);
  // const { url, path } = useRouteMatch();
  // console.log("url in sidebar", url);
  // console.log("path in sidebar", path);
  const { pathname } = useLocation();

  const postJobStart = () => {
    // dispatch(setActiveStep(1));
    dispatch(setActiveStep(0));
    dispatch(setShowPostJob(true));
  };

  console.log("pathname", pathname);

  return (
    <Col
      sm={12}
      md={3}
      style={{
        backgroundColor: "#F8F9FA",
        padding: "1rem 0",
        boxShadow: "0 0 2rem 0 rgb(122 126 128 / 30%)",
      }}
    >
      <div style={{ position: "sticky", top: "20px" }}>
        <div style={{ textAlign: "center", padding: "0 1rem" }}>
          <Image
            src={user.photoUrl}
            roundedCircle
            style={{ width: "100px", height: "100px", objectFit: "cover" }}
          />
          <p style={{ margin: "1rem 0" }}>{user.name}</p>
          <Button onClick={postJobStart} block>
            Post Job
          </Button>
        </div>

        <ul
          style={{
            display: "flex",
            listStyle: "none",
            marginTop: "1.5rem",
            flexDirection: "column",
            // justifyContent: "center",
            // alignItems: "center",
            alignContent: "center",
            paddingLeft: "15px",
          }}
        >
          {user.business && (
            <>
              <p
                className="text-muted"
                style={{
                  paddingRight: "1rem",
                  marginBottom: "0",
                  paddingLeft: "10px",
                }}
              >
                Business
              </p>
              <hr style={{ marginTop: "1rem" }} />
              <li>
                <NavLink
                  to="/dashboard/business"
                  // activeStyle={{
                  //   fontWeight: "bold",
                  //   color: "#124870",
                  //   backgroundColor: "#ccc",
                  // }}
                  activeClassName="active-link"
                  exact
                  style={{ display: "block", padding: "10px" }}
                >
                  Dashboard
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/dashboard/business/leads`}
                  // activeStyle={{
                  //   fontWeight: "bold",
                  //   color: "#124870",
                  //   // backgroundColor: "#ccc",
                  // }}
                  className="sidebar-link"
                  activeClassName="active-link"
                  style={{ display: "block", padding: "10px" }}
                >
                  Leads
                </NavLink>
              </li>

              {user.membership === "non-pro" ? (
                <li>
                  <NavLink
                    to={`/dashboard/business/buy-membership`}
                    activeClassName="active-link"
                    style={{ display: "block", padding: "10px" }}
                  >
                    Buy Membership
                  </NavLink>
                </li>
              ) : null}
              <li>
                <NavLink
                  to={`/dashboard/business/settings`}
                  activeClassName="active-link"
                  style={{ display: "block", padding: "10px" }}
                >
                  Settings
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/dashboard/business/profile`}
                  activeClassName="active-link"
                  style={{ display: "block", padding: "10px" }}
                >
                  Profile
                </NavLink>
              </li>
              <hr />
            </>
          )}

          <li>
            <NavLink
              to="/dashboard/jobs/"
              style={{ display: "block", padding: "10px" }}
              // activeStyle={{
              //   fontWeight: "bold",
              //   color: "#124870"
              //   // backgroundColor: "#ccc",
              // }}
              isActive={() =>
                ["/dashboard/jobs/", "/dashboard/job/*"].includes(pathname)
              }
              activeClassName="active-link"
            >
              My Jobs
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/dashboard/reviews"
              style={{ display: "block", padding: "10px" }}
              // activeStyle={{
              //   fontWeight: "bold",
              //   color: "#124870",
              //   // backgroundColor: "#ccc",
              // }}
              activeClassName="active-link"
            >
              Reviews
            </NavLink>
          </li>
        </ul>
      </div>
    </Col>
  );
};

export default Sidebar;
