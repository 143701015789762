import React, { useState, useEffect } from "react";
import {
  Modal,
  ListGroup,
  Button,
  Form,
  Spinner,
  Alert,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { JOB_STATUS_UPDATE, UPDATE_JOB } from "../../redux/types";
import axios from "axios";
import { Formik } from "formik";
import Rater from "react-rater";
import "react-rater/lib/react-rater.css";
import { useToasts } from "react-toast-notifications";

const JobStatus = ({ setStatus, status, next }) => {
  return (
    <>
      <h6 className="mb-3">Is your job done yet ?</h6>
      <ListGroup>
        <ListGroup.Item
          onClick={() => setStatus("done")}
          className={status === "done" && "activeList"}
        >
          Yes
        </ListGroup.Item>
        <ListGroup.Item
          onClick={() => setStatus("still deciding")}
          className={status === "still deciding" && "activeList"}
        >
          Still Deciding
        </ListGroup.Item>
        <ListGroup.Item
          onClick={() => setStatus("cancelled")}
          className={status === "cancelled" && "activeList"}
        >
          No, I cancelled the job
        </ListGroup.Item>
      </ListGroup>
      <Button onClick={next} disabled={!status} className="mt-3 w-50">
        Next
      </Button>
    </>
  );
};

const ChooseBusiness = ({
  businessToReview,
  setBusinessToReview,
  next,
  quotes,
  back,
}) => {
  return (
    <>
      <ListGroup>
        {quotes && quotes.length > 0 ? (
          <>
            {quotes.map(quote => (
              <ListGroup.Item
                key={quote.id}
                onClick={() => setBusinessToReview(quote.business.id)}
                className={
                  businessToReview === quote.business.id && "activeList"
                }
              >
                {quote.business.businessName}
              </ListGroup.Item>
            ))}
            <ListGroup.Item
              onClick={() => setBusinessToReview("other business")}
              className={businessToReview === "other business" && "activeList"}
            >
              Business outside from getfreequote
            </ListGroup.Item>
          </>
        ) : (
          <ListGroup.Item
            onClick={() => setBusinessToReview("other business")}
            className={businessToReview === "other business" && "activeList"}
          >
            Business outside from getfreequote
          </ListGroup.Item>
        )}
      </ListGroup>
      <div
        style={{
          display: "grid",
          justifyContent: "space-between",
          width: "100%",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridGap: "10px",
        }}
      >
        <Button onClick={next} disabled={!businessToReview} className="mt-3">
          Next
        </Button>
        <Button
          onClick={back}
          // disabled={!businessToReview}
          className="mt-3 "
        >
          Back
        </Button>
      </div>
    </>
  );
};

const WriteReview = ({ businessToReview, jobId, status, userId }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const handleSubmit = values => {
    console.log("review values", values);
    axios
      .post("/write-review", {
        jobId,
        businessId: businessToReview,
        rating: values.rating,
        feedback: values.feedback,
        status,
        userId,
      })
      .then(() => {
        addToast("Job status succefully updated", { appearance: "success" });
        dispatch({ type: UPDATE_JOB, job: { id: jobId, status: status } });
        dispatch({ type: JOB_STATUS_UPDATE, job: null });
      })
      .catch(err => {
        console.log("write review err", err);
        addToast("There is problem updating job status.", {
          appearance: "error",
        });
        dispatch({ type: JOB_STATUS_UPDATE, job: null });
        return;
      });
  };

  const updateStatus = async () => {
    setLoading(true);
    console.log("status update payload", {
      jobId,
      status,
      businessId: businessToReview,
    });
    axios
      .post("update-status", { jobId, status, businessId: businessToReview })
      .then(() => {
        addToast("Job status succefully updated", { appearance: "success" });
        dispatch({ type: UPDATE_JOB, job: { id: jobId, status: status } });
        dispatch({ type: JOB_STATUS_UPDATE, job: null });
      })
      .catch(err => {
        console.log(err);
        addToast("There is problem updating job status.", {
          appearance: "error",
        });
        dispatch({ type: JOB_STATUS_UPDATE, job: null });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return businessToReview === "other business" || status !== "done" ? (
    <>
      {businessToReview === "other business" && (
        <Alert variant="danger">
          We are sorry to hear that you have not decided to choose from the
          business that has quoted. Please come back for next job. We'll make
          sure to get you good deal.
        </Alert>
      )}
      <Button onClick={updateStatus} className="w-50" disabled={loading}>
        {loading ? <Spinner animation="border" size="sm" /> : "Update Status"}
      </Button>
    </>
  ) : (
    <>
      <h6>Rate and Write a feeback to the business</h6>
      <Formik
        initialValues={{
          rating: "",
          feedback: "",
        }}
        onSubmit={handleSubmit}
      >
        {({
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
          errors,
          values,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Rater
                total={5}
                rating={values.rating}
                interactive={true}
                onRate={({ rating }) => {
                  console.log("onRate rating", rating);
                  setFieldValue("rating", rating);
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Feeback (Optional)"
                onChange={handleChange}
                name="feedback"
              />
            </Form.Group>
            <Form.Group>
              <Button type="submit" className="w-50" disabled={isSubmitting}>
                {isSubmitting ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Submit"
                )}
              </Button>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </>
  );
};

const UpdateJobStatus = () => {
  const dispatch = useDispatch();
  const jobToUpdateStatus = useSelector(state => state.job.jobStatusUpdate);
  const hideModal = () => dispatch({ type: JOB_STATUS_UPDATE, job: null });

  const [quotes, setQuotes] = useState();
  const [activeStep, setActiveStep] = useState(0);

  const [status, setStatus] = useState();
  const [businessToReview, setBusinessToReview] = useState();

  useEffect(() => {
    axios
      .get(`/get-quotes/${jobToUpdateStatus.id}`)
      .then(res => {
        // console.log("quotes", res.data);
        setQuotes(res.data);
      })
      .catch(err => console.log(err));
  }, []);

  const next = () => {
    if (status !== "done") {
      setActiveStep(activeStep + 2);
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const back = () => {
    setActiveStep(activeStep - 1);
  };

  const getContent = step => {
    switch (step) {
      case 0:
        return <JobStatus setStatus={setStatus} status={status} next={next} />;

      case 1:
        return (
          <ChooseBusiness
            setBusinessToReview={setBusinessToReview}
            businessToReview={businessToReview}
            next={next}
            quotes={quotes}
            back={back}
          />
        );

      case 2:
        return (
          <WriteReview
            businessToReview={businessToReview}
            jobId={jobToUpdateStatus.id}
            status={status}
            userId={jobToUpdateStatus.author}
            back={back}
          />
        );

      default:
        return <JobStatus setStatus={setStatus} status={status} next={next} />;
    }
  };

  return (
    <Modal
      show={jobToUpdateStatus}
      style={{ zIndex: "100 !important" }}
      onHide={hideModal}
    >
      <Modal.Header closeButton className="bg-primary text-white">
        <Modal.Title>Rate your experience</Modal.Title>
      </Modal.Header>
      <Modal.Body>{getContent(activeStep)}</Modal.Body>
    </Modal>
  );
};

export default UpdateJobStatus;
