import React, { useState, useEffect } from "react";
// import SignUp from "../components/signup/SignUp";
import { Container, Row, Col } from "react-bootstrap";
// import { Formik } from "formik";
// import * as Yup from "yup";
// import { FiEye, FiEyeOff } from "react-icons/fi";
// import axios from "axios";
import CheckUser from "../components/check_user/CheckUser";
// import { checkUser } from "../redux/actions/userAction";
import { useSelector } from "react-redux";
import AboutBusiness from "../components/business/AboutBusiness";
// import { useDispatch } from "react-redux";
// import { signInUser } from "../redux/actions/userAction";
// import { useHistory, Redirect } from "react-router-dom";
import LoginWithPassword from "../components/LoginWithPassword";
import axios from "axios";
import AddBusiness from "../components/business/AddBusiness";
import { Helmet } from "react-helmet";

// import EditBusiness from "../components/business/EditBusiness";
// import UserBusinessRegistratioin from "../components/business/UserBusinessRegistration";

const BusinessRegistration = () => {
  const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  // const [businessDetails, setBusinessDetails] = useState({});
  const [userExists, setUserExists] = useState(false);
  const [businessStep, setBusinessStep] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const checkUserSubmit = (values, actions) => {
    setEmail(values.email);
    // console.log("actions", actions);
    actions.setSubmitting(true);
    axios
      .post("/checkuser", values)
      .then((res) => {
        console.log("check user res", res);
        setUserExists(true);
        setBusinessStep(2);
        actions.setSubmitting(false);
      })
      .catch((err) => {
        console.log("check user res", err);
        setUserExists(false);
        setBusinessStep(2);
        actions.setSubmitting(false);
      });
  };

  // const [show, setShow] = useState(false);
  // const handleSubmit = values => {
  //   console.log(values);
  //   dispatch(checkUser(values.email));
  // };
  // const dispatch = useDispatch();
  // const history = useHistory();

  const user = useSelector((state) => state.user.profile);

  return (
    <Container
      className="mt-5"
      style={{ minHeight: "100vh", padding: "1.5rem" }}
    >
      <Helmet>
        <title>GetFreeQuote - BusinessRegistration</title>
                <meta name="description"
                    content="Getfreequote is a leading company in Australia that provides an active marketplace for people looking for jobs. For more info, explore now!"
              />
      </Helmet>
      <h1
        className="text-center"
        style={{ alignitems: "center", padding: "1rem 0 0" }}
      >
        Let us get you more customers
      </h1>
      <hr style={{ margin: "40px 0 10px 0", padding: "1rem" }} />
      <Row>
        <h4
          className="text-center"
          style={{ alignitems: "center", padding: "0px 120px 20px" }}
        >
          Become a member on Getfreequote and join a huge community of
          professionals.
        </h4>
        <Col
          style={{ minHeight: "408px", padding: "1.5rem 5rem 4rem" }}
          sm={4}
          md={{ offset: 2, span: 8 }}
          className="mt-3"
        >
          {user ? (
            <AddBusiness />
          ) : (
            <>
              {businessStep === 1 ? (
                <CheckUser handleSubmit={checkUserSubmit} />
              ) : !userExists ? (
                <AboutBusiness email={email} />
              ) : (
                <LoginWithPassword email={email} />
              )}
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default BusinessRegistration;
