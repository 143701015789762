export const JOB = "JOB";
export const LOCATION = "LOCATION";
export const DETAILS = "DETAILS";
export const DESCRIPTION = "DESCRIPTION";
export const ATTACHMENT = "ATTACHMENT";
export const WHEN = "WHEN";
export const PRIORITY = "PRIORITY";
export const STEP = "STEP";
export const SHOW_POSTJOB = "SHOW_POSTJOB";
export const JOB_SUBMIT_SUCCESS = "JOB_SUBMIT_SUCCESS";
export const JOB_SUBMIT_ERROR = "JOB_SUBMIT_ERROR";
export const UPDATE_USER_BUSINESSDETAILS = "UPDATE_USER_BUSINESSDETAILS";
export const ADD_BUSINESS = "ADD_BUSINESS";
export const SET_BUSINESS_DETAILS = "SET_BUSINESS_DETAILS";
export const UPDATE_JOBS = "UPDATE_JOBS";
export const RESET_JOB = "RESET_JOB";
export const CHANGE_RADIUS = "CHANGE_RADIUS";
export const SET_USER = "SET_USER";
export const CLEAR_USER = "CLEAR_USER";
export const QUOTE_JOB = "QUOTE_JOB";
export const ADD_TEMPLATE = "ADD_TEMPLATE";
export const UPDATE_BUSINESS_DESCRIPTION = "UPDATE_BUSINESS_DESCRIPTION";
export const UPDATE_BUSINESS_LOGO = "UPDATE_BUSINESS_LOGO";
export const UPDATE_BUSINESS_GALLERY = "UPDATE_BUSINESS_GALLERY";
export const UPDATE_MEMBERSHIP = "UPDATE_MEMBERSHIP";
export const JOB_STATUS_UPDATE = "JOB_STATUS_UPDATE";
export const UPDATE_JOB = "UPDATE_JOB";
export const SET_JOBS = "SET_JOBS";
export const UPDATE_BUSINESS_DETAILS = "UPDATE_BUSINESS_DETAILS";
export const UPDATE_CONTACT_METHOD = "UPDATE_CONTACT_METHOD";
export const UPDATE_MEMBERSHIP_STATUS = "UPDATE_MEMBERSHIP_STATUS";
export const REMOVE_ATTACHMENT = "REMOVE_ATTACHMENT";

// Auth Constants
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNUP_USER_ERROR = "SIGNUP_USER_ERROR";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNIN_USER_ERROR = "SIGNIN_USER_ERROR";
export const FB_SIGNUP_USER = "FB_SIGNUP_USER";
export const FB_SIGNIN_USER = "FB_SIGNIN_USER";
export const GOOGLE_SIGNUP_USER = "GOOGLE_SIGNUP_USER";
export const GOOGLE_SIGNIN_USER = "GOOGLE_SIGNIN_USER";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const USER_EXISTS = "USER_EXISTS";
export const USER_NOT_EXISTS = "USER_NOT_EXISTS";
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
