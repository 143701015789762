import React, { useState, useRef } from "react";
import { Autocomplete } from "@react-google-maps/api";
import { Form } from "react-bootstrap";
import { useField } from "formik";

const PlaceAutocomplete = props => {
  // console.log("location errors", errors);
  const [autoComplete, setAutoComplete] = useState(null);
  const [field, meta, helpers] = useField(props);
  // const dispatch = useDispatch();

  // console.log("meta placeautocomplete", meta);
  // const  {
  //   label,
  //   setValue,
  //   value,
  //   onChange,
  //   touched,
  //   errors,
  //   setCenter,
  // } = props
  const { setValue, name, onChange, values, setErrors, ...rest } = props;
  // console.log("PlaceAutocomplete setValue", setValue);
  // const [location, setLocation] = useState("");
  // const [coordinate, setCoordinate] = useState({});
  // const [location, setLocation] = useState(null);
  const locationInputRef = useRef(null);

  const autoCompleteOnLoad = autocomplete => {
    // console.log("autocomplete", autocomplete);
    setAutoComplete(autocomplete);
    // locationInputRef = autocomplete
  };

  const onPlaceChanged = () => {
    const place = autoComplete.getPlace();
    console.log("place", place);
    if (!field.value) {
      return;
    }

    if (!place.place_id) {
      return;
    }

    const formatted_address = place.formatted_address.split(",")[0];
    // const formatted_address_array = formatted_address.split(" ");
    // const latlng = {
    //   lat: place.geometry.location.lat(),
    //   lng: place.geometry.location.lng(),
    // };

    let postcode = place.address_components.filter(
      entry => entry.types[0] === "postal_code"
    );

    let state = place.address_components.filter(
      item =>
        item.types.includes("administrative_area_level_1") ||
        item.types.includes("poilitical")
    );

    console.log("state", state);

    console.log("postal_codes", postcode);

    if (postcode.length !== 0) {
      setValue("location", {
        name: formatted_address,
        postcode: postcode[0].short_name,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        state: state[0].short_name,
      });
    } else {
      setValue("location", {
        name: formatted_address,
        postcode: postcode[0].short_name,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        state: state[0].short_name,
      });
    }

    // console.log("suburb value set", values.location);

    // locationInputRef.current.value = formatted_address;

    // dispatch(
    //   setLocation({
    //     name: formatted_address,
    //     lat: place.geometry.location.lat(),
    //     lng: place.geometry.location.lng(),
    //   })
    // );

    // setAutoComplete(place);
    if (props.setCenter) {
      props.setCenter({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    }

    // meta.error = {}
  };

  // console.log("values in placeautocomplete", values);
  // console.log("field value in place autocomplete", field.value);
  console.log("meta error", meta.error);

  return (
    <Autocomplete
      onLoad={autoCompleteOnLoad}
      onPlaceChanged={onPlaceChanged}
      restrictions={{ country: "au" }}
      types={["(regions)"]}
      // fields={["formatted_address", "name", "place_id"]}
    >
      <Form.Group>
        {props.label && (
          <Form.Label htmlFor="location">{props.label}</Form.Label>
        )}
        <Form.Control
          name="location"
          type="text"
          sytle={{ marginBottom: "1rem" }}
          // required
          ref={locationInputRef}
          // isInvalid={!locationInputRef}
          // value={location}
          // onChange={setLocation}
          // value={values.location.name}
          // name="location"
          id="location"
          // onChange={onChange}
          // onChange={onPlaceChanged}
          isInvalid={meta.touched && meta.error}
          // {...field}
          onChange={field.onChange}
          // onChange={e => e.target.value}
          // value={formValue}
          value={values.location.name}
          placeholder="Suburb or Postcode"
          // {...rest}
        />
        <Form.Control.Feedback type="invalid">
          {!field.value
            ? meta.error && (meta.error.name || meta.error.postcode)
            : "Invalid Suburb"}
          {/* {meta.error && (meta.error.name || meta.error.postcode)} */}
        </Form.Control.Feedback>
      </Form.Group>
    </Autocomplete>
  );
};

export default PlaceAutocomplete;
