import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons";
import classes from "./SocialFollow.module.css";

export default function SocialFollow() {
  return (
    <div className={classes.socialContainer}>
      <div className={classes.social} id="facebook">
        <a
          href="https://www.facebook.com/getfreequotes/"
          className={classes.facebook}
        >
          <FontAwesomeIcon icon={faFacebook} size="2x" />
        </a>
        <span>
          <a href="https://www.facebook.com/getfreequotes/">@Getfreequote</a>
        </span>
      </div>
      <div className={classes.social} id="twitter">
        <a
          href="https://twitter.com/getfreequoteau"
          className={classes.twitter}
        >
          <FontAwesomeIcon icon={faTwitter} size="2x" />
        </a>
        <span>
          <a href="https://twitter.com/getfreequoteau">@Getfreequote</a>
        </span>
      </div>
    </div>
  );
}
