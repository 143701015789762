import React from "react";
import GetQuoteForm from "../getquoteform/GetQuoteForm";
const FullWidthForm = ({ inView }) => {
  // return !inView ? (
  return (
    <div
      style={{
        width: "100%",
        padding: "20px 0",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        position: "fixed",
        top: "0",
        zIndex: "10",
        transition: "0.5s",
      }}
    >
      <div
        className="container"
        style={{ padding: "0 10px", marginBottom: "-15px" }}
      >
        <GetQuoteForm />
      </div>
    </div>
  );
};

export default FullWidthForm;
