import React from "react";
import { Container, Accordion, Card } from "react-bootstrap";
const PrivacyPolicy = () => {
  return (
    <>
      <div id="privacy-feature" className="feature">
        <div className="overlay grid-center">
          <h1>Privacy Policy</h1>
        </div>
      </div>
      <Container className="py-3">
        <div style={{ maxWidth: "900px", margin: "70px auto" }}>
          <Accordion defaultActiveKey="0">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                <h5>INFORMATION COLLECTED</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    When registering with getfreequote.com.au you will be asked
                    for personal information including but not limited to name,
                    address, contact details and, where relevant, financial
                    information, including credit card information, banking
                    details and income information.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="1">
                <h5>WHAT IS THIS INFORMATION USED FOR?</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p>
                    The primary purpose for which this information is collected
                    is to personalise your getfreequote.com.au experience and
                    better respond to your individual needs. Other reasons
                    include:
                  </p>

                  <p>
                    a. To improve customer service and to respond to and
                    communicate with you more effectively by email, SMS message
                    or telephone; and
                  </p>

                  <p>
                    b. To improve getfreequote.com.au (we continually strive to
                    improve website offering based on the information and
                    feedback we receive from you).
                  </p>

                  <p>Other reasons include:</p>

                  <p>
                    a. To personalise and customise your experience on the
                    website; and
                  </p>

                  <p>
                    b. To help getfreequote.com.au manage and enhance its
                    services.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="2">
                <h5>COOKIES</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p>
                    The website uses cookies which contain information by which
                    getfreequote.com.au can identify your computer (or other
                    devices you use to access the website) to our servers and
                    the servers of third parties contracted by
                    getfreequote.com.au including, but not limited to, third
                    parties providing analytical and advertising services. You
                    may configure your browser so that you are notified before a
                    cookie is downloaded or so that your browser does not accept
                    cookies. getfreequote.com.au may use information contained
                    in cookies to make assumptions about the user of the device
                    and to provide users of that device with focused advertising
                    that getfreequote.com.au believes may be of interest, based
                    on that information. You can disable cookies through your
                    Internet browser.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="3">
                <h5>DISCLOSURE OF PERSONAL INFORMATION</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p>
                    Our quick to complete template allows members to register
                    their details and create aprofile without any fuss
                    whatsoevergetfreequote.com.au may disclose your personal
                    information, where deemed appropriate, to comply with the
                    law, enforce our site policies or protect ours or others
                    rights, property or safety. However, non-personally
                    identifiable visitor information may be provided to other
                    parties for marketing, advertising or other uses.
                    getfreequote.com.au may only disclose information relating
                    to a user's racial or ethnic origin, membership of political
                    bodies, religion, membership of a trade union or trade
                    association, sexual preferences, criminal record and health
                    for the purpose for which it was provided or a directly
                    related secondary purpose or as allowed by law unless the
                    user has agreed otherwise.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="4">
                <h5>PROTECTING YOUR PERSONAL INFORMATION</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  <p>
                    getfreequote.com.au places a great importance on the
                    security of all information associated with our customers
                    and contractors. We implement a variety of security measures
                    to maintain and protect against the loss, misuse and
                    alteration of personal information under our control.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="5">
                <h5>THIRD PARTY LINKS</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="5">
                <Card.Body>
                  <p>
                    Occasionally and at getfreequote.com.au’s discretion we may
                    include or offer third party products or services on our
                    website. These third parties have separate and private
                    policies. getfreequote.com.au may contain links to other web
                    sites and those third party web sites may collect personal
                    information about you. We are not responsible for the
                    privacy practices of other businesses or the content of web
                    sites that are linked to our web site. getfreequote.com.au
                    encourages users to be aware that when they leave the site
                    to read the privacy statements of each and every web site
                    that collects personally identifiable information.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="6">
                <h5>MANAGEMENT & SECURITY OF PERSONAL INFORMATION</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="6">
                <Card.Body>
                  <p>
                    getfreequote.com.au protects the personal information the
                    website holds from misuse, loss, unauthorised access,
                    modification or disclosure by various means including
                    firewalls, password access, secure servers and data
                    encryption.
                  </p>

                  <p>
                    getfreequote.com.au is committed to maintaining accurate,
                    timely, relevant and appropriate information about our
                    customers and web-site users. Inaccurate information will be
                    corrected upon receiving advice to this effect from you. To
                    ensure confidentiality, details of your personal information
                    will be passed on to you only if we are satisfied that the
                    information relates to you.
                  </p>

                  <p>
                    If we refuse to provide you with access or correct the
                    personal information held about you by us, then we will
                    provide reasons for such refusal.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="7">
                <h5>ACCESSING PERSONAL INFORMATION</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="7">
                <Card.Body>
                  <p>
                    You have the right to seek access to the personal
                    information getfreequote.com.au holds about you and to
                    advise getfreequote.com.au of any inaccuracy. There are some
                    exceptions to this right set out in the Privacy Act.
                  </p>

                  <p>
                    If you make a request, getfreequote.com.au will ask you to
                    prove your identity and specify what information you
                    require. getfreequote.com.au may charge you a fee to cover
                    the costs of meeting your request.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="8">
                <h5>COMPLAINTS</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="8">
                <Card.Body>
                  {" "}
                  <p>
                    If you have a complaint about our Privacy Policy or the
                    collection, use or safe disposal or destruction of your
                    personal information, your complaint should be directed to
                    getfreequote.com.au. We will investigate your complaint and
                    attempt to resolve any breach that might have occurred in
                    relation to the collection, use or destruction of personal
                    information held by us about you in accordance with the
                    Commonwealth Privacy legislation. If you are not satisfied
                    with the outcome of this procedure then you may request that
                    an independent person (usually the Commonwealth Privacy
                    Officer) investigate your complaint.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="9">
                <h5>LICENCE TO USE YOUR INFORMATION</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="9">
                <Card.Body>
                  {" "}
                  <p>
                    To enable getfreequote.com.au to use the information
                    supplied without violating any rights you agree to grant us
                    a non-exclusive, worldwide, irrevocable, perpetual, fee-free
                    right to exercise the copyright and other intellectual
                    property rights you have in that information in any format
                    (including, but not limited to, any text, photographs,
                    graphics, video or audio). You agree that
                    getfreequote.com.au can use that content in any way, now and
                    in the future. You warrant that you have all of the
                    necessary rights, including copyright, in the content you
                    contribute, that your content is not defamatory and that it
                    does not infringe any law within Australia. You indemnify
                    getfreequote.com.au against any and all legal fees, damages
                    and other expenses that may be incurred by
                    getfreequote.com.au as a result of a breach of this
                    warranty. getfreequote.com.au will only use your information
                    in accordance with our Privacy Policy.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="10">
                <h5>CHANGES TO PRIVACY POLICY</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="10">
                <Card.Body>
                  {" "}
                  <p>
                    If getfreequote.com.au changes its Privacy Policy, it will
                    post changes on this Privacy Policy page so that users are
                    always aware of what information is collected, how it is
                    used and the way in which information may be disclosed. As a
                    result, please remember to refer back to this Privacy Policy
                    regularly to review any amendments.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="11">
                <h5>YOUR CONSENT</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="11">
                <Card.Body>
                  <p>
                    By completing the registration process and using
                    getfreequote.com.au website you agree to our Privacy Policy
                    and Terms and Conditions.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="12">
                <h5>CONTACTING US</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="12">
                <Card.Body>
                  <p>
                    If there are any questions regarding this Privacy Policy you
                    may contact us through our website getfreequote.com.au
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </Container>
    </>
  );
};

export default PrivacyPolicy;
